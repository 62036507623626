import React, {useState,useEffect, useRef} from 'react'
import attach from "../../../images/icons/attach.svg"
import goback from "../../../images/icons/goback.svg"
import { ThreeDots } from 'react-loader-spinner'
import { Link } from 'react-router-dom'
import axios from 'axios'
import settings from '../../getrequest'
import { LineWave } from  'react-loader-spinner' 

function Members() {
  const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
  let urlbase = process.env.REACT_APP_BASE;
  const [add, setAdd] = useState()
  const [membersAdded, setMembersAdded] = useState()
  const [error, setError] = useState(0)
  const [processing, setProcessing] = useState(0)
  const [ticketDetailsData, setticketDetailsData] = useState([])
  const [ticketDetailsMessages, setticketDetailsMessages] = useState([])
  const[fetching, setFetching] = useState(1) 

  const getTicketDetails = async (url) => {
    setFetching(1)
    axios.get(urlbase+url, settings())
      .then((res) => {
        if (res.data.code === 200) {
          setticketDetailsData(res.data.data.details)
          if(res.data.data.messages.length > 0) {
            setticketDetailsMessages(res.data.data.messages)
          }
        } else {
          setError(res.data.message);
          setProcessing(0);
        }
        setFetching(0)
      })
      .catch((e) => {
        setError('There was an internal error, please try again later');
        setProcessing(0);
        setFetching(0)
      });
  }

  let ticket_id = sessionStorage.getItem('ticket_id')

  useEffect(() => {
    getTicketDetails('/api/tickets/'+ticket_id) 
  },[])

//delete members
const [actionModal, setactionModal] = useState()
const [deleteProcessing, setDeleteProcessing] = useState()



function getFirstLetter(inputString) {
  if (typeof inputString === 'string' && inputString.length > 0) {
      return inputString[0].toUpperCase(); 
  } else {
      return ''; 
  }
}


function Change(e){
  setTheAction(e)
  const newStatus = e.target.checked ? 'Resolved' : 'Unresolved';
  // setticketDetailsData(prevState => ({
  //   ...prevState,
  //   status: newStatus
  // }));
}

const [actionType, setActionType] = useState(1);
//resolve-unresolve
const setTheAction = (e) => {
  // alert(e.target.checked)
  setActionType(e.target.checked ? 1 : 2)
  setactionModal(1)
}

const [messageData,setMessageData] = useState({'id':ticket_id})
const CreateMessage = () =>  {
  setFetching(1)
  axios.post(urlbase+'/api/tickets/message/create',messageData,settings())
  .then((res) => {
    if(res.data.code == 200){
      alert('Message sent successfully')
      window.location.reload()
    }else{
      alert(res.data.message);
    }
    setFetching(0)
  }).catch(() => {
    alert('There was an error, please try again');
    setFetching(0)
  })
}

const updateData = (e) => {
  setMessageData({...messageData,
  message:e.target.value})
}


  return (
    <>
      {!fetching ? 
      <div className={`grid grid-cols-1 mt-10 mb-5 px-8 ${add || actionModal || membersAdded ? 'opacity-25' : ''}`}>
         <>
         <Link to={'/'+base+'/tickets'} className='w-[40px]'><img src={goback}/></Link>
         <div className='col-span-1 mt-5 rounded-[8px] border-[1px] grid grid-cols-1 bg-white h-[auto] '>
            <div className='col-span-1 flex flex-col lg:flex-row mb-10 justify-between space-y-3 lg:space-y-0 lg:items-center m-3'>
              <span className='text-[#121927] font-[500] text-[20px] flex flex-row space-x-2'>
                <span>Ticket Details</span>
                <span className='items-center'><span className='bg-[#F2F4F7] text-[14px] font-[400] rounded-[16px] items-center p-2 px-3'>{ticketDetailsData.ticket_id}</span></span>
              </span>
            </div>
            <div className='col-span-1 grid grid-cols-3 gap-5 m-3 mb-10'>
            <div className='col-span-3 lg:col-span-1 rounded-[4px] border-[1px] h-[62px] border-[#D0D5DD] flex flex-col p-2 grid my-auto'>
                <span className='text-[#667085] text-[12px] font-[300]'>Title</span>
                <span className='text-[#121927] text-[14px] font-[400]'>{ticketDetailsData.title}</span>
              </div>
              <div className='col-span-3 lg:col-span-1 rounded-[4px] border-[1px] h-[62px] border-[#D0D5DD] flex flex-col p-2 grid my-auto'>
                <span className='text-[#667085] text-[12px] font-[300]'>Admin</span>
                <span className='text-[#121927] text-[14px] font-[400]'>{ticketDetailsData.user}</span>
              </div>
              <div className='col-span-3 lg:col-span-1 rounded-[4px] border-[1px] h-[62px] border-[#D0D5DD] flex flex-col p-2 grid my-auto'>
                <span className='text-[#667085] text-[12px] font-[300]'>Issue Type</span>
                <span className='text-[#121927] text-[14px] font-[400]'>{ticketDetailsData.category}</span>
              </div>
              <div className='col-span-3 lg:col-span-1 rounded-[4px] border-[1px] h-[62px] border-[#D0D5DD] flex flex-col p-2 grid my-auto'>
                <span className='text-[#667085] text-[12px] font-[300]'>Date Submitted</span>
                <span className='text-[#121927] text-[14px] font-[400]'>{ticketDetailsData.created_at}</span>
              </div>
              <div className='col-span-3 lg:col-span-1 rounded-[4px] border-[1px] h-[62px] border-[#D0D5DD] flex flex-col p-2 grid my-auto'>
                <span className='text-[#667085] text-[12px] font-[300]'>Last Updated</span>
                <span className='text-[#121927] text-[14px] font-[400]'>{ticketDetailsData.updated_at}</span>
              </div>
              <div className='col-span-3 lg:col-span-1 rounded-[4px] border-[1px] h-[62px] border-[#D0D5DD] flex flex-col p-2 grid my-auto'>
                <span className='text-[#667085] text-[12px] font-[300]'>Status</span>
                <span className='text-[#121927] text-[14px] font-[400]'>{ticketDetailsData.status}</span>
              </div>
              <div className='col-span-3 rounded-[4px] border-[1px] h-auto lg:h-[114px] border-[#D0D5DD] flex flex-col p-2 grid my-auto'>
                <span className='text-[#667085] text-[12px] font-[300]'>Description</span>
                <span className='text-[#121927] text-[14px] font-[400]'>{ticketDetailsData.description}</span>
              </div>
              {/* <div className='col-span-3 lg:col-span-1 rounded-[4px] bg-[#FAFBFC] border-[1px] h-auto lg:h-[114px] border-[#D0D5DD] flex flex-col p-2 space-y-2'>
                <span className='text-[#667085] text-[12px] font-[300]'>Attachment</span>
                {ticketDetailsData.attachment ? 
                <>
                  <span className='text-[#121927] text-[14px] font-[400]'>{ticketDetailsData.attachment}</span>
                  <span className='rounded-[8px] bg-white w-[123px] text-center border-[#D0D5DD] border-[1px] p-[1.5px] text-[#344054]'>Download file</span>
                </> : <span className='text-[#121927] text-[14px] font-[400]'>No attachment</span>}
              </div> */}
            </div>

            <div className='col-span-1 bg-[#FAFBFC] rounded-b-[8px] grid grid-cols-1 p-3 pt-7 gap-y-5'>

              {ticketDetailsMessages.length > 0 ? 
              ticketDetailsMessages.map(({user,org,role,created_at,message,attachment}) => {
                return (
                  <div className='flex flex-row space-x-3'>
                  <div className=''>
                    <span className='rounded-full grid bg-white w-[40px] h-[40px] text-center'>
                        <span className='my-auto'>{getFirstLetter(user)}</span>
                    </span>
                  </div>
                  <div className='w-full flex flex-col space-y-4 text-[#4F4F4F] text-[14px]'>
                    <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center'>
                      <div className='flex flex-row space-x-2'>
                        <span className='flex flex-col'>
                          <span className='text-[#101828] font-[400] text-[16px]'>{user}</span>
                          <span className='text-[#667085] font-[300] text-[14px]'>{role}</span>
                        </span>
                      </div>
                      <div className=''>
                        <span className='text-[#3B3B3B] text-[14px]'>{created_at}</span>
                      </div>
                    </div>
                    {/* <div>Hello Lakeside</div> */}
                    <div>{message}</div>
                    {attachment ? 
                      <div>
                      <span className='rounded-[20px] border-[1px] border-[#EAEAEA] w-[109px] flex flex-row p-2 items-center space-x-2 text-center items-center'>
                        <span className=''><img src={attach} /></span>
                        <span className=''>{attachment}</span>
                      </span>
                    </div>
                    :
                      ''
                    }

                  </div>
                </div> 
                )
              })
              : ''}
                

                <div className='grid grid-cols-8'>
                  <div className='col-span-8 lg:col-span-5 space-y-2'>
                    <textarea onChange={(e) => updateData(e)} className='w-full border-[1px] border-[#D0D5DD] rounded-[8px] h-[114px] p-3' placeholder='Type message here...'>
                    </textarea>
                    <div className='flex flex-row justify-between'>
                      <span className='flex flex-row items-center space-x-2'>
                        {/* <span className=''><img src={attach} /></span>
                        <span className=''>Attach file</span> */}
                      </span>
                      <button onClick={CreateMessage} className='bg-[#b30000] p-2 rounded-[8px] text-white font-[400] text-[14px]'>Send Messsage</button>
                    </div>
                  </div>
                </div>

            </div>
          </div>
          </> 
      </div>
      : <div className='col-span-8 lg:col-span-6 flex flex-col justify-center items-center '>
      <ThreeDots height="80" width="80" radius="9" color="#333333" ariaLabel="three-dots-loading"
      wrapperStyle={{}} wrapperClassName="" visible={true}
      />
      </div>}
    </>
  )
}

export default Members