import React, {useState,useEffect} from 'react'
import search from "../../../images/icons/search.svg"
import delet from "../../../images/icons/delete2.svg"
import deleteRed from "../../../images/icons/deleteRed.svg"
import { ThreeDots } from 'react-loader-spinner'
import axios from 'axios'
import { LineWave } from  'react-loader-spinner'
import settings from '../../../components/getrequest'
import { format } from 'date-fns';

function Group() {
  const [groupsData,setgroupsData] = useState([])
  const [error, setError] = useState(0)
  const [processing, setProcessing] = useState(0)
  const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
  let urlbase = process.env.REACT_APP_BASE;
  const[fetching, setFetching] = useState(1)
  const sliceValue = 25
  const [GroupArray, setGroupArray] = useState([])
  const[GroupCurrent, setGroupCurrent] = useState([])
  const[GroupCurrentDefault, setGroupCurrentDefault] = useState([])
  const [currentPage,setCurrentPage] = useState(0)
  const [members, setMembers] = useState([])

  const getGivingTypes = async (url) => {
    axios.get(urlbase+url, settings())
      .then((res) => {
        if (res.data.code === 200) {
          console.log(res.data.data.length)
          if(res.data.data.length > 0){
            setgroupsData(res.data.data)
            const allData = res.data.data;
            const slicedData = [];
            for (let i = 0; i < allData.length; i += sliceValue) {
              slicedData.push(allData.slice(i, i + sliceValue));
            }
            setGroupCurrent(slicedData[0])
            setGroupArray(slicedData);
          }else{
            setgroupsData([])
            setGroupCurrent([])
            setGroupArray([]);
          }
          setFetching(0)
        } else {
          setError(res.data.message);
          setProcessing(0);
          setFetching(0)
        }
      })
      .catch((e) => {
        setError('There was an internal error, please try again later');
        setProcessing(0);
        setFetching(0)
      });
  }

  useEffect(() => { 
    //get giving types
    getGivingTypes('/api/givings/types')

  },[])

useEffect(()=>{
  //  createPages() 
  // setFetching(0)
},[])
const createPages = async () => {
  let L = groupsData.length
  let last_index = sliceValue
  if(groupsData.length > sliceValue) {
      for(L; L-sliceValue > 0; L-sliceValue){
          L = L-sliceValue;
          let new_last_index = last_index+sliceValue
          let NewObject = groupsData.slice(last_index,new_last_index)
          last_index = new_last_index
          if(NewObject.length > 0){
              GroupArray.push(NewObject)
          } 
      }
      const updatedDonations = [...GroupArray]
      setGroupArray(updatedDonations)
  }else{
      setGroupArray([groupsData])
  } 
}
const PreviousPage = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  setCurrentPage(currentPage > 0 ? currentPage-1 : currentPage)
  if(currentPage > 0){
    setGroupCurrent(GroupArray[currentPage-1])
    setGroupCurrentDefault(GroupArray[currentPage-1])
  }
}
const NextPage = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  setCurrentPage(currentPage == GroupArray.length-1 ? currentPage : currentPage+1)
  if(currentPage == GroupArray.length-1){

  }else{
    setGroupCurrent(GroupArray[currentPage+1])
    setGroupCurrentDefault(GroupArray[currentPage+1])
  }
}
const numberedPage = (id) => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  setCurrentPage(id)
  setGroupCurrent(GroupArray[id])
  setGroupCurrentDefault(GroupArray[id])
}
const [searchItem, setSearchItem] = useState("");
//Active donation
const [activeDonation, setActiveDonation] = useState()
const changeActiveDon = (id) => {
  if(activeDonation == id) setActiveDonation(null)
  else setActiveDonation(id)
}

//filter
const [filterr, setFilterr] = useState()
const filterAction = () => {
  if(filterr) setFilterr(0)
  else setFilterr(1)
}

//edit Group
const [GivingTypeModal, setGivingTypeModal] = useState()
const setTheModal = () => {
  setActiveDonation(null)
  setGivingTypeModal(1)
}

//delete donation
const [deleteModal, setDeleteModal] = useState()
const setTheDelete = (id) => {
  setActiveDonation(null)
  setDeleteModal(id)
}

const [deleteProcessing,setDeleteProcessing] = useState()
const performDelete = () => {
  setDeleteProcessing(1)
  axios.delete(urlbase+'/api/givings/types/'+deleteModal,settings())
  .then((res) => {
    if(res.data.code == 200){
      setDeleteModal(0)
      alert('Giving type removed successfully')
      window.location.reload()
    }else{
      alert(res.data.message);
    }
    setDeleteProcessing(0)
  }).catch(() => {
    alert('There was an error, please try again');
    setDeleteProcessing(0)
  })
}

//view details
const viewDetails = (id) => {
  sessionStorage.setItem('group_id',id)
  window.location.href='/'+base+"/group-details"
}

//add participant 
const [addParticipant2,setAddParticipant2] = useState()

//
const searchMember = () => {
  if(!newGivingTypesData.name || !newGivingTypesData.description){
    alert('Please fill all fields')
    return
  }
  setGivingTypeModal(0)
  setAddParticipant2(1)
}

//SELECTION
const [selection, setSelection] = useState([])
const [selectionIds, setSelectionIds] = useState([])
const AddSelection = (e) => {
  const isFound = members.some(element => {
    return element.member_id === e.target.value;
  });
  if(isFound){
    const found = selection.some(obj => {
      return obj.member_id === e.target.value;
    });
    if(found){
    }else{
      const NewFind = members.find(obj => {
        return obj.member_id === e.target.value;
      });
      setSelection([...selection,{id:NewFind.id,member_id:NewFind.member_id,name:NewFind.name}])
      setSelectionIds([...selectionIds,NewFind.id])
      console.log(selectionIds)
    }
  }
  //document.getElementById('search').value=''
} 

const AddSelection2 = (e) => {
  const isFound = members.some(element => {
    return element.member_id === e.target.value;
  });
  if(isFound){
    const found = members.find(obj => {
      return obj.member_id === e.target.value;
    });
    setSelection([...selection,{id:found.id,member_id:found.member_id,name:found.name}])
    setSelectionIds([...selectionIds,found.member_id])
  }
 // alert(document.getElementById('search').value)
  document.getElementById('search').value=''
} 


//VIEW GROUP 
const [seeGroups, setSeeGroups] = useState()
const ViewGroups = () => {
  window.location.reload()
}

const [newGivingTypesData, setNewGivingTypesData] = useState({})
const setGivingTypesData = (value,field) => {
  setNewGivingTypesData({...newGivingTypesData,[field] : value})
}

//CREATE GROUP
const [successs, setSuccesss] = useState()
const [AddingGivingType,setAddingGivingType] = useState()
const createGivingType = () => {
  setGivingTypeModal(0)
  setAddingGivingType(1)
  axios.post(urlbase+'/api/givings/types/create',newGivingTypesData,settings())
  .then((res) => {
    if(res.data.code == 200){
      alert('Giving type created successfully')
      window.location.reload()
    }else{
      alert(res.data.message);
    }
    setAddingGivingType(0)
  }).catch(() => {
    alert('There was an error, please try again');
    setAddingGivingType(0)
  })
}
let [dateF, setDateF] = useState(null)

  const dateFilter = (value) => {
      if(value) setDateF(value)
      else setDateF(null)
  };
 

  return (
    <>
      {GivingTypeModal ?
      <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative w-[90%] lg:w-[40%] mt-[25%] lg:mt-[10%] max-h-full mx-auto text-[#333]">
              <div className="relative bg-white shadow pt-3 rounded-[8px]">
                  <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                          Create new type
                      </h3>
                      <button onClick={() => setGivingTypeModal(0)} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div className='flex flex-col space-y-7 mt-3 mb-20'>
                    <div className="mt-5 grid flex flex-col text-start space-y-2 px-8">
                        <div className='text-[#505050]'>Type name</div>
                        <input onChange={(e) => setGivingTypesData(e.target.value,'type')} className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F]' placeholder='E.g Thanksgiving, Church Building ...'/>
                    </div>
                  </div>
                  <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4 rounded-b-[8px]">
                      <button onClick={() => setGivingTypeModal(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] w-[102px] h-[50px] rounded-[8px]">Cancel</button>
                      <button onClick={createGivingType} data-modal-hide="defaultModal" type="button" className="border-[1px] w-[119px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white">Continue</button>
                  </div>
              </div>
          </div>
      </div>
      :null}

  {addParticipant2 ?
      <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative w-[95%] lg:w-[40%] mt-[25%] lg:mt-[10%] max-h-full mx-auto text-[#333]">
              <div className="relative bg-white shadow pt-3 rounded-[8px]">
                  <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                          Add Participant to Group
                      </h3>
                      <button onClick={() => setAddParticipant2(0)} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div className='flex flex-col mt-3 mb-20 px-6 mt-7'>
                    <div className='relative'>
                      <div className='absolute top-3 z-50 left-4'><img src={search} /></div>
                      <input list="members" id="search" onChange={(e) => AddSelection(e)} className='relative w-full bg-[#F7F7F7] h-[40px] pl-10 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none' placeholder='Search' />
                      <label className='text-[#B30000] text-[14px] cursor-pointer' onClick={createGivingType}>Skip</label>
                      {selection ? 
                      <div className='flex-col space-y-2 text-start mt-4'>
                          {selection.map(({id,member_id,name},ind) => {
                            return (
                              <div key={id} className={`flex flex-col pb-3 ${ind+1 != selection.length ? 'border-b-[1px]' : ''}`}>
                                <span className='text-[#333333] font-[400] text-[16px]'>{name}</span>
                                <span className='text-[#333333] font-[400] text-[12px]'>{member_id}</span>
                              </div>
                            )
                          })}
                      </div> :null }
                      <datalist onSelect={(e) => AddSelection2(e)} id="members">
                        {members.map(({member_id,name},idd) => {
                          return (
                            <option key={idd} value={member_id}>{name}</option>
                          )
                        })}
                      </datalist> 
                    </div>
                  </div>
                  <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4 rounded-b-[8px]">
                    {AddingGivingType ? 
                    <LineWave height="100" width="100" color="#B30000" ariaLabel="line-wave" wrapperStyle={{marginTop:'-40px'}}
                    wrapperClass="" visible={true} firstLineColor="" middleLineColor="" lastLineColor=""/>  : <>
                      <button onClick={() => setAddParticipant2(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#B30000] w-[102px] h-[50px] rounded-[8px] bg-white text-[#B30000]">Back</button>
                      <button onClick={createGivingType} disabled={selection.length > 0 ? false : true} data-modal-hide="defaultModal" type="button" className={`border-[1px] w-[150px] h-[50px] ${selection.length > 0 ? 'bg-[#B30000]' : 'bg-[#E0E0E0]'} rounded-[8px] border-none text-white`}>Create group</button>
                      </>}
                  </div>
              </div>
          </div>
      </div>
      :null}

    <div>
    {deleteModal ?
      <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative w-[50%] lg:w-[35%] mt-[10%] max-h-full mx-auto text-[#333]">
              <div className="relative bg-white shadow pt-4 rounded-[8px]">
                  <div className="flex items-start justify-between px-8 pb-4">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                      </h3>
                      <button onClick={() => setDeleteModal(0)} type="button" className="text-gray-300 font-[200] bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div className='flex flex-col space-y-4 mt-3 px-5 text-center'>
                    <div className='grid mb-2'><img className='mx-auto' src={deleteRed} /></div>
                    <span className='text-[#121927] font-[600] text-[24px]'>Delete Giving Type</span>
                    <span className='text-[#4F4F4F] text-[16px]'>Deleted giving type would have to be re-created. Are you sure you want to delete?</span>
                  </div>
                  <div className="flex flex-col mt-5 space-y-3 px-8 py-4">
                    { !deleteProcessing ? 
                    <>
                      <button onClick={performDelete} data-modal-hide="defaultModal" type="button" className="border-[1px] w-full h-[50px] bg-[#EB5757] rounded-[8px] border-none text-white">Yes, delete</button>
                      <button onClick={() => setDeleteModal(0)} data-modal-hide="defaultModal" type="button" className="border-[#828282] w-full h-[50px] rounded-[8px] bg-[#F7F7F7] text-[#333333]">No, Cancel</button>
                    </> : 
                    <LineWave height="100" width="100" color="#B30000" ariaLabel="line-wave" wrapperStyle={{marginTop:'-40px'}}
                      wrapperClass="" visible={true} firstLineColor="" middleLineColor="" lastLineColor=""/>
                      }

                  </div>
              </div>
          </div>
      </div>
      :null}

      <div className={`grid grid-cols-1 mt-5 mb-5 px-8 ${GivingTypeModal ? 'opacity-25' : ''}`}>
        <div className='col-span-1 flex lg:flex-row flex-col justify-between space-y-4 lg:space-y-0'>
          <p className='text-[#292D32] text-[24px] font-[600] text-start'>Your Giving Types</p>
          <div className='flex flex-row space-x-4'>
            <div onClick={() => setTheModal()} className='border-[1px] bg-[#B30000] px-2 h-[40px] cursor-pointer rounded-[4px] flex flex-row p-2 items-center space-x-3'>
              <p className='text-white text-[15px] font-[600]'>Create Type</p>
            </div>
          </div>
        </div>

        {GroupCurrent.length > 0 ?
        <div className='col-span-1 mt-5 border-[1px] rounded-[8px]'>
          <table className="table-auto overflow-x-scroll w-full block divide-y divide-gray-200 ">
            <thead className='bg-[#F7F7F7]'>
                <tr className='px-6 py-1 text-[#292D32] font-[400]'>
                {/* <th className='px-7 py-3 text-star'><input type='checkbox' className='accent-[#B30000]' /></th> */}
                <th className='px-5 py-1 text-start text-[#292D32] font-[400] w-[14.2857143%]'>S/N</th>
                <th className='px-5 py-1 text-start text-[#292D32] font-[400] w-[14.2857143%]'>Giving Type</th>
                <th className='px-5 py-1 py-1 text-start text-[#292D32] font-[400] w-[14.2857143%]'>Date Created</th>
                <th className='px-5 py-1 text-start text-[#292D32] font-[400] w-[14.2857143%]text-[#F7F7F7]'>Delete</th>
                </tr>
            </thead>
            {groupsData.length > 0 ? 
            <tbody className='divide-y divide-gray-200'>
                {GroupCurrent.filter((row) =>
                !searchItem.length || row.type
                .toString()
                .toLowerCase()
                .includes(searchItem.toString().toLowerCase()) 
                ).map(({id,type,created_at},index) => {
                    return (
                        <tr key={id}>
                            {/* <td className='px-6 py-3 text-star'><input type='checkbox' className='accent-[#B30000]' /></td> */}
                            <td className='px-5 py-3 text-start text-[#4F4F4F]'>{index+1}</td>
                            <td className='px-5 py-3 text-start text-[#4F4F4F]'>{type}</td>
                            <td className='px-5 py-3 text-start text-[#4F4F4F]'>{format(new Date(created_at),'yyyy-mm-dd')}</td>
                            <td className='px-5 py-3 text-start text-[#4F4F4F]'><img className='w-[20px] h-[20px] cursor-pointer' onClick={() => setTheDelete(id)} src={delet} /></td>
                        </tr>
                        )
                    }) 
                }
            </tbody> : <div>No giving types retrieved</div> }
          </table>
        </div>
        :null}

        <div className='col-span-1 flex flex-row justify-between items-center'>
          {searchItem? '' : <span className='text-[#333333] text-[16px]'>Showing <span>{GroupCurrent.length}</span>/<span>{groupsData.length}</span> results</span>}
          {fetching ? 
            <div className='col-span-8 lg:col-span-6 flex flex-col justify-center items-center '>
                <ThreeDots height="80" width="80" radius="9" color="#333333" ariaLabel="three-dots-loading"
                wrapperStyle={{}} wrapperClassName="" visible={true}
                />
            </div> 
            :
          <div className={`ml-auto grid grid-cols-6 gap-x-4`}>
                <div className={`mt-5 col-span-6 flex flex-row gap-x-3 mb-5 lg:ml-auto`}>
                    <div onClick={PreviousPage} className={`border-[#BCBCBC] h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`<`}</div>
                    {GroupArray.slice(0,5).map((GroupArray,index)=> {
                        return (
                            <div key={index} onClick={()=>numberedPage(index)} className={`transitiion-all h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto text-start duration-500 border-[#BCBCBC] font-[600] text-[18px] border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center ${currentPage == index ? 'text-white bg-[#B30000] border-[#B30000] hover:bg-[#B30000] hover:text-white' :'text-[#292D32] bg-white hover:text-white hover:bg-[#B30000]'} hover:text-[#1D242D] cursor-pointer`}>{index+1}</div>
                        )
                    })}
                    <div onClick={NextPage} className={`border-[#BCBCBC] border-[1px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`>`}</div>
                </div>
            </div>
          }
        </div>
      </div>
      </div>

    </>
  )
}

export default Group