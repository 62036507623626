import React, {useState,useEffect} from 'react'
import search from "../../../images/icons/search.svg"
import { ThreeDots } from 'react-loader-spinner'
import axios from 'axios'
import { LineWave } from  'react-loader-spinner'
import settings from '../../../components/getrequest'
import { format } from 'date-fns';
import { ThreeCircles } from  'react-loader-spinner' 

function RunningClaims() {
  const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
  let urlbase = process.env.REACT_APP_BASE;
  const [claimsTotal, setClaimsTotal] = useState(0)
  const [claimsEstimate, setClaimsEstimate] = useState(0)
  const [error, setError] = useState(0)
  const [processing, setProcessing] = useState(0)
      const [donationsData, setDonationsData] = useState([])
    const[fetching, setFetching] = useState(0)
    const sliceValue = 25
    const [givingArray, setgivingArray] = useState([])
    const[givingCurrent, setgivingCurrent] = useState([])
    const [currentPage,setCurrentPage] = useState(0)
    const [tableStart, setTableStart] = useState(0)
    const [tableEnd, setTableEnd] = useState(5)
    useEffect(()=>{
      const getClaims = async () => {
        allPeriods()
        setFetching(1)
      //get running claims
      axios.get(urlbase+'/api/claims',settings())
      .then((res) => {
          if(res.data.code == 200){

            setClaimsTotal(res.data.data.total_amount)
            setClaimsEstimate(res.data.data.estimate)
            if(res.data.data.givings.length > 0){
              setDonationsData(res.data.data.givings)
              const allData = res.data.data.givings;
              const slicedData = [];
              for (let i = 0; i < allData.length; i += sliceValue) {
                slicedData.push(allData.slice(i, i + sliceValue));
              }
              setPageCount(slicedData[0].length)
              setgivingCurrent(slicedData[0])
              setgivingArray(slicedData);
            }else{
              
            }

            setFetching(0)
          }else{
              setError(res.data.message)
              setProcessing(0)
              setFetching(0) 
          }
      }).catch((e) => {
          setError('There was an internal error, please try again later')
          setProcessing(0)
      })
    }
    getClaims()
  },[])

    const [pageCount, setPageCount] = useState(0) 
    let [pageNumbers, setPageNumbers] = useState(0) 
      const PreviousPage = () => {
        if(currentPage > 0){
          if(givingArray.length > 5){
            if(tableStart != 0){
              setTableStart(tableStart-1)
              setTableEnd(tableEnd-1)
            }
          }
          let thenewPage = currentPage
          let counted = pageCount
          let start = 2
          while(start > 0){
              counted -= givingArray[thenewPage].length
              thenewPage--;
              start--;
          }
          setPageNumbers(counted)
          setPageCount(pageCount - givingArray[currentPage].length)
          setCurrentPage(currentPage-1)
          setgivingCurrent(givingArray[currentPage-1])
          scrollToTop()
        }
      }

      const NextPage = () => {
        if(currentPage != givingArray.length-1){
          if(givingArray.length > 5){
            if(givingArray.length != tableEnd){
              setTableStart(tableStart+1)
              setTableEnd(tableEnd+1)
            }
          }
            let thenewPage = currentPage
            let counted = 0
            while(thenewPage >= 0){
                counted += thenewPage > 0 ? givingArray[thenewPage-1].length : 0 + givingArray[thenewPage].length
                thenewPage--;
            }
            setPageNumbers(counted)
            setCurrentPage(currentPage+1)
            setgivingCurrent(givingArray[currentPage+1])
            setPageCount(pageCount+givingArray[currentPage+1].length)
            scrollToTop()
        }
      }
      const numberedPage = (id) => {
        let count = 0
        let counted = 0;
      
        while(count < id){
          counted += givingArray[count].length
          count++;
        }
        setPageNumbers(counted)
        setPageCount(counted+givingArray[id].length)
      
        setCurrentPage(id)
        setgivingCurrent(givingArray[id])
      
        scrollToTop()
      }

      const FirstPage = () => {
        numberedPage(0)
        setTableStart(0)
        setTableEnd(5)
      }
      
      const LastPage = () => {
        numberedPage(givingArray.length-1)
        if(givingArray.length > 5){
            setTableStart(givingArray.length - 5)
            setTableEnd(givingArray.length)
        }
      }

      function scrollToTop(){
        var targetElement = document.getElementById("claims");
              targetElement.scrollIntoView({
                behavior: "smooth",
        });
      }
      const [searchItem, setSearchItem] = useState("");
      //Active donation
      const [activeDonation, setActiveDonation] = useState()
      const changeActiveDon = (id) => {
        if(activeDonation == id) setActiveDonation(null)
        else setActiveDonation(id)
      }
      
      //giving types
      const givingTypes = ['Cash','Individual','Aggregated','GASDS']
      
      //filter
      const [filterr, setFilterr] = useState()
      const filterAction = () => {
        if(filterr) setFilterr(0)
        else setFilterr(1)
      }

      const [periods, setPeriods] = useState([])
const allPeriods = async () => {
  axios
    .get(urlbase + "/api/settings/periods", settings())
    .then((res) => {
      if (res.data.code === 200) {
        setPeriods(res.data.data)
        console.log(res.data.data)
      } else {
        console.log("Error");
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const [changeProcess, setChangeProcess] = useState()
const [periodToChange, setPeriodToChange] = useState()
const changePeriod = async (e) => {
  let val = e.target.value
  var start = val.substring(0, 4);
  var end = val.substring(4);
  if(sessionStorage.getItem('claim_end') == end){
    return;
  }
  setChangeProcess(1)
  axios
    .post(urlbase + "/api/settings/periods/set",{start:start,end:end}, settings())
    .then((res) => {
      if (res.data.code == 200) {
        sessionStorage.setItem('claim_start',start)
        sessionStorage.setItem('claim_end',end)
        window.alert('Period to claim changed successfully')
        window.location.reload()
      } else {
        console.log("Error");
        setChangeProcess(0)
      }
    })
    .catch((error) => {
      console.log(error);
      setChangeProcess(0)
    });
};

  return (
    <>
    {fetching ? 
      <div className='col-span-8 lg:col-span-6 flex flex-col justify-center items-center '>
          <ThreeDots height="80" width="80" radius="9" color="#333333" ariaLabel="three-dots-loading"
          wrapperStyle={{}} wrapperClassName="" visible={true}
          />
      </div> 
      :
          <div>
          <div id="claims" className='col-span-1 flex lg:flex-row flex-col space-y-4 lg:space-y-0 justify-between mt-5 lg:items-center text-start'>
            <div className='flex flex-row space-x-4'>
              <div className='flex flex-col items-'>
                <span className='text-[#8A8A8A] font-[400] text-[14px]'>Total Amount</span>
                <span className='text-[#121927] font-[600] text-[20px] lg:text-[28px]'>£{parseFloat(claimsTotal).toLocaleString()}</span>
              </div>
              <div className='border-r-[1px]'></div>
              <div className='flex flex-col'>
                <span className='text-[#8A8A8A] font-[400] text-[14px]'>Estimated Reclaim</span>
                <span className='text-[#121927] font-[600] text-[20px] lg:text-[28px]'>£{parseFloat(claimsEstimate).toLocaleString()}</span>
              </div>
            </div>
            <div className='flex lg:flex-row flex-col space-y-4 lg:space-y-0 lg:space-x-4 lg:items-center'>
              <div className='relative'>
                <div className='absolute top-3 z-40 left-4'><img src={search} /></div>
                <input onChange={(e) => setSearchItem(e.target.value)} className='relative w-[280px] bg-[#F7F7F7] h-[40px] pl-10 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none' placeholder='Search' />
              </div>
              <div className="flex flex-row space-x-3 text-[16px] text-right ml-auto mt-4 items-center">
              
              {!changeProcess ? <select onChange={(e) => changePeriod(e)} className="p-2 text-[#4F4F4F] border-[1px] rounded-[4px] w-[165px] h-[40px] outline-none ">
              <option value='currenty' selected={false}>Current Year</option>
                {periods.map(({start,end}) => {
                  return (
                    <option value={start.toString()+end.toString()} selected={end == sessionStorage.getItem('claim_end') ? true : false}>{start == end ? start : start+'-'+end}</option>
                  )
                })}
              </select> : 
                <ThreeCircles
                height="40"
                width="40"
                color="#B30000"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""
              />
              }
            </div>
            </div>
          </div>
          <div className='col-span-1 mt-5 border-[1px] rounded-[8px] overflow-x-scroll'>
            <table className="table-auto block divide-y divide-gray-200">
              <thead className='bg-[#F7F7F7]'>
                  <tr className='text-[#292D32] font-[400]'>
                  <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[30%]'>Name</th>
                  <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[30%]'>Description</th>
                  <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[30%]'>Total Giving</th>
                  <th className='px-6 py-1 text-start text-[#292D32] font-[400] w-[30%]'>Last Donation</th>
                  </tr>
              </thead>
              <tbody className='divide-y divide-gray-200'>
                  {givingCurrent.filter((row) =>
                  !searchItem.length || row.name
                  .toString()
                  .toLowerCase()
                  .includes(searchItem.toString().toLowerCase()) 
                  ).map(({name,total_giving,description,last_donation},index) => {
                      return (
                          <tr key={index}>
                              <td className='px-6 py-1 text-start text-[#4F4F4F]'>{name?name:'--------'}</td>
                              <td className='px-6 py-1 text-start text-[#4F4F4F]'>{description}</td>
                              <td className='px-6 py-1 text-start text-[#4F4F4F]'>£{total_giving ? parseFloat(total_giving).toLocaleString() : 0}</td>
                              <td className='px-6 py-1 text-start text-[#4F4F4F]'>{last_donation}</td>
                          </tr>
                          )
                      }) 
                  }
              </tbody>
            </table>
          </div>
          <div className='col-span-1 flex flex-row justify-between items-center '>
            {searchItem? '' : <span className='text-[#333333] text-[16px]'>Showing <span>{pageCount}</span>/<span>{donationsData.length}</span> results</span>}
            <div className={`ml-auto grid grid-cols-6 gap-x-4`}>
                  <div className={`mt-5 col-span-6 flex flex-row gap-x-3 mb-5 lg:ml-auto items-center`}>
                      <div onClick={PreviousPage} className={`border-[#BCBCBC] h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`<`}</div>
                      {givingArray.length > 5 ? <span className={`cursor-pointer text-[14px] font-[500] ${currentPage > 0 ? 'text-[#B30000]' : ''}`} onClick={FirstPage} >First</span> : null }
                      {givingArray.slice(tableStart,tableEnd).map((givingArrayS,index)=> {
                          return (
                              <div key={index} onClick={()=>numberedPage(givingArray.indexOf(givingArrayS))} className={`transitiion-all h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto text-start duration-500 border-[#BCBCBC] font-[600] text-[18px] border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center ${currentPage == givingArray.indexOf(givingArrayS) ? 'text-white bg-[#B30000] border-[#B30000] hover:bg-[#B30000] hover:text-white' :'text-[#292D32] bg-white hover:text-white hover:bg-[#B30000]'} hover:text-[#1D242D] cursor-pointer`}>{givingArray.indexOf(givingArrayS)+1}</div>
                          )
                      })}
                      {givingArray.length > 5 ? <span className={`cursor-pointer text-[14px] font-[500] ${currentPage < givingArray.length-1 ? 'text-[#B30000]' : ''}`} onClick={LastPage}>Last</span> : null }
                      <div onClick={NextPage} className={`border-[#BCBCBC] border-[1px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`>`}</div>
                  </div>
              </div>
            
          </div>
          </div>
      }
    </>


  )
}

export default RunningClaims