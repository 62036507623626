function Settings () {
    var MyHeaders = new Headers();
    MyHeaders.append("Accept", "application/json");
    MyHeaders.append("Content-Type", "application/json");
    MyHeaders.append("Authorization", 'Bearer '+sessionStorage.getItem('access_token') ? sessionStorage.getItem('access_token') : '');
    var set = {
        headers: {"Authorization" : `Bearer ${sessionStorage.getItem('access_token')}`}
    };
    return set
}
export default Settings