import { useState,useEffect } from "react";
import First from "../../../images/icons/first.svg";
import Second from "../../../images/icons/second.svg";
import Check from "../../../images/icons/check.svg";
import Check2 from "../../../images/icons/check 2.svg";
import Check3 from "../../../images/icons/check3.svg";
import Minus from "../../../images/icons/minus.svg";
import Add from "../../../images/icons/add.svg";
import { Link } from "react-router-dom";

const content = [
  {
    small: "01 - Donor Management",
    title: "Effortlessly Manage your Charity Activities",
    para: "Effortlessly track and manage donor information, including contact details, donation history, and communication preferences. Streamline your fundraising efforts and nurture strong relationships with your supporters.",
    button: "Get Started Now",
    img: First,
  },
  {
    small: "02 - Report & Analysis",
    title: "Get Insights into your  Performance",
    para: "Access comprehensive analytics and generate insightful reports to gain actionable insights into your organization's performance and members engagement. Make data-driven decisions for continuous improvement.",
    button: "Get Started Now",
    img: Second,
    reverse: true,
  },
];

const faq = [
  {
    question: "How can a charity SaaS product benefit our organization ?",
    answer:
      "A charity SaaS product can benefit your organization in multiple ways. It helps you automate and streamline administrative tasks, improve donor and volunteer management, enhance fundraising efforts, track program impact.",
  },
  {
    question: "Can I get records of all donations made to my organization?",
    answer:
      "Yes, all records are presented on your dashboard and you can download for your keeping.",
  },
  {
    question: "Can I create more than one organization ?",
    answer:
      "Of course, you can create multiple organizations and we have enhanced our system for you to easily switch between organizations",
  },
  {
    question: "What kind of claims can I make on donations ?",
    answer:
      "Claims ranges from donations made by unknown donors, registered tax paying members and even non tax payers. You get a 25% pay back on all succesful claims",
  },
  {
    question: "Do I have to register with HMRC to be able to make claims ?",
    answer:
      "Yes, you need to be a registered Charity organization under HMRC to be able to make claims via our platform",
  },
];

const Body = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }},[])
     const [activeIndex, setActiveIndex] = useState(-1);
     const handleAccordionToggle = (index) => {
       setActiveIndex(activeIndex === index ? -1 : index);
    };

sessionStorage.setItem('chGamsFreeTrial',0)
const freeTrials = (type) => {
  sessionStorage.setItem('chGamsFreeTrial',type)
  window.location.href="/onboard";
}
const [period, setPeriod] = useState(2)
  return (
    <div className="">
      <div className="w-[90%] 2xl:w-[80%] m-auto py-5 lg:py-20">
        {content.map((item, index) => (
          <div
            key={index}
            className={`space-y-10 px-3 lg:flex lg:justify-between lg:items-center ${
              item.reverse ? "space-y-10 pt-20 lg:flex-row-reverse lg:items-center" : ""
            }`}
          >
            <div className="wow fadeInLeft">
              <div className="color w-[210px] rounded-[24px_24px_24px_0px] p-2 mb-3">
                <p className="text-[#F2994A] text-center">{item.small}</p>
              </div>
              <div className="space-y-4 lg:w-[500px]">
                <p className=" text-[30px] lg:text-[48px] text-[#101727] font-[500] tracking-[-0.72px] leading-[130%]">
                  {item.title}
                </p>
                <p className="text-[18px] font-[300] text-[#4F4F4F] tracking-[-0.72px] leading-[150%]">
                  {item.para}
                </p> 
              </div>
              <div className="pt-10">
                <Link to='/onboard' className="bg-[#B30000] text-[16px] w-[193px] h-[50px] px-4 py-3 text-white rounded-[8px]">
                  {item.button}
                </Link>
              </div>
            </div>
            <div className="wow fadeInRight">
              <img src={item.img} alt="" />
            </div>
          </div>
        ))}
      </div>
      <div className="w-[90%] 2xl:w-[80%] m-auto py-5 lg:py-20">
        <div className="color w-[210px] m-auto rounded-[20px] p-2 wow fadeInUp">
          <p className="text-[#F2994A] text-center">03 - Pricing</p>
        </div>
        <p className="pt-4 w-[90%] m-auto wow fadeInUp text-[30px] text-center font-[500] leading-[40px] lg:leading-[62.14px] tracking-[-0.72px] md:text-[48px] text-[#101727]">
          Unlock Exclusive Features with Our<br></br> Pricing Options
        </p>
        <div className="w-[208px] h-[50px] bg-[#121927] rounded-[32px] flex flex-row px-3 space-x-4 mx-auto justify-center text-white mt-7 items-center">
          {/* <div onClick={()=>setPeriod(1)} className={` ${period==1? 'bg-[#B30000]' : ''} w-[87px] rounded-[20px] cursor-pointer h-[33px] items-center grid text-center`}><p className="my-auto">Monthly</p></div> */}
          <div onClick={()=>setPeriod(2)}  className={` ${period==2? 'bg-[#B30000]' : ''} w-[87px] rounded-[20px] cursor-pointer h-[33px] items-center grid text-center`}><p className="my-auto">Yearly</p></div>
        </div>
        <div className="grid grid-cols-3 py-10 lg:space-x-7 m-auto">
          <div className="pb-10 col-span-3 lg:col-span-1 wow fadeOut">
            <div className="border-[1px] rounded-[8px] px-6 py-8 h-[635px] freemium text-white">
              <div className="border-b-[1px] border-[#EAEAEA] pb-2">
                <p className="font-[500] leading-normal tracking-[-0.36px] text-[24px]">
                  Free
                </p>
              </div>
              
              {period == 1 ? <p className="text-[28px] font-[500] tracking-[-0.48px] mt-2">
                Free/
                <span className="text-[14px] tracking-[-0.21px] font-[500]">
                1 Month
                </span>
              </p>  : 
                <p className="text-[28px] font-[500] tracking-[-0.48px] mt-2">
                £0.00
            </p> 
              }
              <p className="text-[14px] font-[400] leading-[130%] mt-2">
                Enjoy our packages for one month without payment
              </p>
              <div className="pt-6 flex justify-center items-center w-full">
                <button onClick={() => freeTrials (1)} className="bg-[#F7F7F7] text-[14px] w-full font-[600] px-4 py-3 text-[#121927] rounded-[8px] ">
                  Get Started
                </button>
              </div>
              <div className="flex gap-x-4 items-center pt-6">
                <img src={Check3} alt="" />
                <p className="text-[16px] font-[400] leading-normal">
                  Members Management
                </p>
              </div>
              <div className="flex gap-x-4 items-center pt-4">
                <img src={Check3} alt="" />
                <p className="text-[16px] font-[400] leading-normal">
                  Donations management
                </p>
              </div>
              <div className="flex gap-x-4 items-center pt-4">
                <img src={Check3} alt="" />
                <p className="text-[16px] font-[400] leading-normal">
                  Groups Management
                </p>
              </div>
              <div className="flex gap-x-4 items-center pt-4">
                <img src={Check3} alt="" />
                <p className="text-[16px] font-[400] leading-normal">
                  Statistics Overview
                </p>
              </div>
              <div className="flex gap-x-4 items-center pt-4">
                <img src={Check3} alt="" />
                <p className="text-[16px] font-[400] leading-normal">
                  3 Months Data Retention
                </p>
              </div>
              <div className="flex gap-x-4 items-center pt-4">
                <img src={Check3} alt="" />
                <p className="text-[16px] font-[400] leading-normal">
                  Maximum of 3 Users
                </p>
              </div>
            </div>
          </div>

          <div data-wow-duration='1s' data-wow-delay=".3s" className="col-span-3 lg:col-span-1 wow fadeOut pb-10">
            <div className="border-[1px] bg-[#F2F2F2] rounded-[8px] px-6 py-8 h-[635px] w-full silver">
              <div className="flex justify-between items-center border-b-[1px] border-[#292D32] pb-2">
                <p className="text-[#292D32] font-[500] leading-normal tracking-[-0.36px] text-[24px]">
                  Silver
                </p>
                {/* <button className="bg-white text-[10px] font-[600] leading-normal p-2 text-[#B30000] rounded-[24px] ">
                  Recommended
                </button> */}
              </div>
              {period == 2 ? 
                <p className="text-[28px] font-[500] text-[#292D32] tracking-[-0.48px] mt-2">
                £161.99
                  {/* <span className="text-[14px] text-[#292D32] tracking-[-0.21px] font-[500] pr-3">
                    Yr
                  </span>  */}
                </p> :
                <p className="text-[28px] font-[500] text-[#292D32] tracking-[-0.48px] mt-2">
                    £13.99/
                  <span className="text-[14px] text-[#292D32] tracking-[-0.21px] font-[500]">
                    Mnth
                  </span>
                </p>
              }
              <p className="text-[14px] font-[400] leading-[130%] text-[#292D32] mt-2">
                This plan offers our basic functionalities
              </p>
              <div className="pt-6 flex justify-center items-center w-full">
                <button onClick={() => freeTrials(2)} className="bg-[#B30000] text-[14px] w-full font-[600] px-4 py-3 text-white rounded-[8px] ">
                  Get Started
                </button>
              </div>
              <div className="flex gap-x-4 items-center pt-6">
                <img src={Check} alt="" />
                <p className="text-[#292D32] text-[16px] font-[400] leading-normal">
                  Members Management
                </p>
              </div>
              <div className="flex gap-x-4 items-center pt-4">
                <img src={Check} alt="" />
                <p className="text-[#292D32] text-[16px] font-[400] leading-normal">
                  Donations Management
                </p>
              </div>
              <div className="flex gap-x-4 items-center pt-4">
                <img src={Check} alt="" />
                <p className="text-[#292D32] text-[16px] font-[400] leading-normal">
                  Groups Management
                </p>
              </div>
              <div className="flex gap-x-4 items-center pt-4">
                <img src={Check} alt="" />
                <p className="text-[#292D32] text-[16px] font-[400] leading-normal">
                  Statistics Overview
                </p>
              </div>
              <div className="flex gap-x-4 items-center pt-4">
                <img src={Check} alt="" />
                <p className="text-[#292D32] text-[16px] font-[400] leading-normal">
                  HMRC Claims
                </p>
              </div>
              <div className="flex gap-x-4 items-center pt-4">
                <img src={Check} alt="" />
                <p className="text-[#292D32] text-[16px] font-[400] leading-normal">
                  Maximum of 3 Users
                </p>
              </div>
            </div>
          </div>

          <div className="pb-10 col-span-3 lg:col-span-1 wow fadeOut">
            <div className="price bg-[#F2F2F2] border-[1px] rounded-[8px] px-6 py-8 h-[635px]">
              <div className="border-b-[1px] border-[#EAEAEA] pb-2">
                <p className="text-white font-[500] leading-normal tracking-[-0.36px] text-[24px]">
                  Gold
                </p>
              </div>
              {period == 2 ? 
                <p className="text-[28px] font-[500] text-white tracking-[-0.48px] mt-2">
                £291.99
                  {/* <span className="text-[14px] text-white tracking-[-0.21px] font-[500] pr-3">
                    Yr
                  </span> */}
                </p> :
                <p className="text-[28px] font-[500] text-white tracking-[-0.48px] mt-2">
                  £24.99/
                  <span className="text-[14px] text-white tracking-[-0.21px] font-[500]">
                    Mnth
                  </span>
                </p>  
              }
              
              <p className="text-[14px] font-[400] leading-[130%] text-white mt-2">
                Enjoy unlimited privileges with our gold plan
              </p>
              <div className="pt-6 flex justify-center items-center w-full">
                <button onClick={() => freeTrials (4)} className="bg-[#F7F7F7] text-[14px] w-full font-[600] px-4 py-3 text-[#121927] rounded-[8px] ">
                  Get Started
                </button>
              </div>
              <div className="flex gap-x-4 items-center pt-6">
                <img src={Check2} alt="" />
                <p className="text-white text-[16px] font-[400] leading-normal">
                  Members Management
                </p>
              </div>
              <div className="flex gap-x-4 items-center pt-4">
                <img src={Check2} alt="" />
                <p className="text-white text-[16px] font-[400] leading-normal">
                  Donations management
                </p>
              </div>
              <div className="flex gap-x-4 items-center pt-4">
                <img src={Check2} alt="" />
                <p className="text-white text-[16px] font-[400] leading-normal">
                  Groups Management
                </p>
              </div>
              <div className="flex gap-x-4 items-center pt-4">
                <img src={Check2} alt="" />
                <p className="text-white text-[16px] font-[400] leading-normal">
                  Statistics Overview
                </p>
              </div>
              <div className="flex gap-x-4 items-center pt-4">
                <img src={Check2} alt="" />
                <p className="text-white text-[16px] font-[400] leading-normal">
                  HMRC Claims
                </p>
              </div>
              <div className="flex gap-x-4 items-center pt-4">
                <img src={Check2} alt="" />
                <p className="text-white text-[16px] font-[400] leading-normal">
                  Unlimited User Access (Including Donors)
                </p>
              </div>
              <div className="flex gap-x-4 items-center pt-4">
                <img src={Check2} alt="" />
                <p className="text-white text-[16px] font-[400] leading-normal">
                  Dedicated Relationship Manager
                </p>
              </div>
              <div className="flex gap-x-4 items-center pt-4">
                <img src={Check2} alt="" />
                <p className="text-white text-[16px] font-[400] leading-normal">
                  Priority Support Service
                </p>
              </div>
              <div className="flex gap-x-4 items-center pt-4">
                <img src={Check2} alt=""/>
                <p className="text-white text-[16px] font-[400] leading-normal">
                  Bulk Upload of Online Givings
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-5 lg:max-w-[1440px] lg:w-[92%] lg:m-auto pb-10 lg:pb-40">
        <div>
          <div className="color w-[210px] m-auto rounded-[20px] p-2 wow wobble">
            <p className="text-[#F2994A] text-center">04 - FAQs</p>
          </div>
          <div className="max-w-[861px] m-auto py-6">
            <p id="faqs" className="font-[500] text-center leading-[130%] tracking-[-0.72px] text-[30px] text-[#101727] lg:text-[48px] wow fadeIn">
              You have questions, we have the answers
            </p>
          </div>
        </div>
        {faq.map((data, index) => (
          <Accordion
            key={index}
            question={data.question}
            answer={data.answer}
            index={index}
            activeIndex={activeIndex}
            onToggle={handleAccordionToggle}
          />
        ))}
      </div>
    </div>
  );
};

export default Body;

const Accordion = ({ answer, question, index, activeIndex, onToggle }) => {
const isActive = activeIndex === index;

const toggle = () => {
  onToggle(isActive ? -1 : index); // Toggle the active state
};

  return (
    <div className={`border-b-[1px] border-[#EAEAEA] last-of-type:border-none py-6 wow fadeOut`}>
      <div className="flex justify-between items-start" onClick={toggle}>
        <p
          className={`font-[400] text-[20px] leading-[130%] lg:text-[20px] ${
            activeIndex === index ? "text-[#B30000]" : "text-[#0E0E0E]"
          }`}
        >
          {question}
        </p>
        <div className="">
          <img
            src={isActive ? Minus : Add}
            alt="Toggle"
            className="relative"
          />
        </div>
      </div>
      {isActive && (
        <div className="pt-6 w-auto text-[18px] font-[300]">
          <p
            dangerouslySetInnerHTML={{ __html: answer }}
            className="font-[300] text-[#828282] text-[18px] leading-[140%] lg:text-[18px]"
          />
        </div>
      )}
    </div>
  );
};
