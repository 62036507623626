import React, { useState } from 'react'
import logo from '../../images/logo.svg'
import { NavLink, Link } from 'react-router-dom'

function Header() {
  const [menuOpen, setMenuOpen] = useState()
  const makeLogin = () => {
    if(sessionStorage.getItem('access_token')){
      window.location.href = sessionStorage.getItem('chgamsDashUrl')
    }else{
      window.location.href='/login'
    }
  }
  return (
    <>
    {/* <div className='bg-[#FFF7F7] fixed w-full h-[30px] items-center grid'><marquee className="my-auto text-[#B30000]">Dear esteemed subscriber, our plans have been reviewed to accommodate effective and uninterrupted service just for you.</marquee></div> */}
      <div className="hidden lg:flex flex-row justify-between py-4 px-20 items-center text-[16px] pt-20 lg:pt-5">
        <Link to='/' className="">
          <img src={logo} />
        </Link>
        <div className="flex flex-row space-x-7 font-[400] text-[#4F4F4F]">
          <NavLink to="/" activeClassName="active">
            Home
          </NavLink>
          <NavLink to="https://blog.chgams.com" activeClassName="active">
            Blog
          </NavLink>
          <NavLink to="/support" activeClassName="active">
            Support
          </NavLink>
        </div>
        <div className="flex flex-row space-x-7 font-[600] items-center">
          <button onClick={makeLogin} className="text-[#B30000]">
            Login
          </button>
          <Link
            to="/onboard"
            className="bg-[#B30000] px-4 py-3 text-white rounded-[8px]"
          >
            Get Started
          </Link>
        </div>
      </div>
      <div className="lg:hidden flex flex-col py-4 space-y-5 text-[16px] bg-white pt-5">
        <div className="flex flex-row justify-between items-center px-8">
          <div className="">
            <img src={logo} />
          </div>
          <div onClick={() => setMenuOpen(!menuOpen)}>
            <svg
              class="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </div>
        </div>
        <div
          className={`${
            menuOpen ? "" : "hidden"
          } flex flex-col border-b-[1px] pb-4`}
        >
          <div className="flex flex-col font-[400] space-y-2 text-[#4F4F4F] text-center">
            <NavLink to="/" activeClassName="active">
              Home
            </NavLink>
            <NavLink to="/blogs" activeClassName="active">
              Blogs
            </NavLink>
            <NavLink to="/support" activeClassName="active">
              Support
            </NavLink>
          </div>
          <div className="flex flex-col font-[600] space-y-3 pt-2 items-center text-center">
            <Link to="/login" className="text-[#B30000]">
              Login
            </Link>
            <Link
              to="/onboard"
              className="bg-[#B30000] px-4 py-3 text-white rounded-[8px]"
            >
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header