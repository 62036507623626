import React from "react"
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider,getAuth, signOut } from 'firebase/auth';

const Logout = () => {
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: "chgams-dd62e.firebaseapp.com",
        projectId: "chgams-dd62e", 
        storageBucket: "chgams-dd62e.appspot.com",
      };
      const app = initializeApp(firebaseConfig);
      const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signOut(auth)
    .then(() => {
        sessionStorage.clear()
        window.location.href='/'
    })
    .catch((error) => {
      alert('Error signing out');
    });

}

export default Logout
