import React, { useState,useEffect } from 'react'
import logo from '../../images/logo.svg'
import {Link} from 'react-router-dom'
import successs from '../../images/success_green.svg'
import upload from '../../images/icons/upload.svg'
import check from '../../images/icons/check.svg'
import Check2 from "../../images/icons/check 2.svg";
import Check3 from "../../images/icons/check3.svg";
import { AiOutlineEye,AiOutlineEyeInvisible } from 'react-icons/ai'
import settings from '../../components/getrequest'
import axios from 'axios'
import { LineWave } from  'react-loader-spinner'

function Onboarding() {
//   sessionStorage.clear();
  //Image processing
  const [selectedImage, SetselectedImage] = useState()
  //error
  const [error, setError] = useState({id:0,err:null})
  //processing
  const [processing, setProcessing] = useState(0)

  //Registration
  const userData = new FormData();

  let orgData = new FormData();
  const [steps, setSteps] = useState(sessionStorage.getItem('last') == 1 ? 6 : 1)
// const [steps, setSteps] = useState(sessionStorage.getItem('last') == 1 ? 6 : 6)
  const [tSteps, setTsteps] = useState([1,2,3,4,5,6])
  const setTheError = (id,er) => {
    setError({...error,
        id : id, err : er
        })
  } 
  let che = 0
  function changeStep () {
    if(steps == 1){
        //check errors in step 1
        if(!sessionStorage.getItem('name') || !sessionStorage.getItem('hmrc_reference') || !sessionStorage.getItem('charity_reg_no') || !sessionStorage.getItem('site_prefix') || !sessionStorage.getItem('hmrc_body') || !sessionStorage.getItem('authorization')){
            setTheError(1,'Please fill all fields before proceeding')
            che = 1
        }else{
            if(sessionStorage.getItem('hmrc_body') == 'null'){
                setTheError(1,'Please choose an HMRC registration body')
                che = 1
                return
            }
            setProcessing(1)
            //send otp
            let datas = {ref:sessionStorage.getItem('hmrc_reference'),reg:sessionStorage.getItem('charity_reg_no'),pref:sessionStorage.getItem('site_prefix')}
            axios.post(process.env.REACT_APP_BASE+'/api/verify-details',datas,settings)
            .then((res) => {
                if(res.data.code == 200){
                    moveNext()
                    setProcessing(0)
                }else{
                    setTheError(1,res.data.message)
                    che = 1
                    setProcessing(0)
                }
            }).catch((e) => {
                setTheError(1,'There was an internal error, please try again later')
                che = 1
                setProcessing(0)
            })
        }
    }
    if(steps == 2){
        //check errors in step 2
        if(!sessionStorage.getItem('start_date') || !sessionStorage.getItem('end_date')){
            setTheError(2,'Please fill required fields before proceeding')
            che = 2
        }else{

            var theDate = new Date(sessionStorage.getItem('start_date'));
            var theYear = theDate.getFullYear();
      
            var theLastDate = new Date(sessionStorage.getItem('end_date'));
            var theLastYear = theLastDate.getFullYear();
      
            var nextYear = (new Date()).getFullYear() + 1;
            var lastYear = (new Date()).getFullYear() - 1;
            if (theYear < lastYear) {
                setTheError(2,'Start date of financial year cannot be less than last year')
                che = 2
            }else{
                if (theLastYear > nextYear) {
                    setTheError(2,'End date of financial year cannot be greater than next year')
                    che = 2
                }else{
                    moveNext()
                }
            }
        }
    }
    if(steps == 3){
        //check errors in step 3
        if(!sessionStorage.getItem('first_name') || !sessionStorage.getItem('last_name') || !sessionStorage.getItem('email') || !sessionStorage.getItem('phone')){
            setTheError(3,'Please fill all fields before proceeding')
        }else{
            setProcessing(1)
            //send otp
            let datas = {email:sessionStorage.getItem('email')}
            axios.post(process.env.REACT_APP_BASE+'/api/verify-email',datas,settings)
            .then((res) => {
                if(res.data.code == 200){
                    moveNext()
                    setProcessing(0)
                }else{
                    setTheError(3,res.data.message)
                    che = 1
                    setProcessing(0)
                }
            }).catch((e) => {
                setTheError(3,'There was an internal error, please try again later')
                che = 1
                setProcessing(0)
            })
        }
    }
    if(steps == 4){
        //check errors in step 4
        if(!sessionStorage.getItem('otp')){
            setTheError(4,'Please fill all fields before proceeding')
        }else{
            //verify otp
            setProcessing(1)
            let datas = {email:sessionStorage.getItem('email'),otp:sessionStorage.getItem('otp')}
            axios.post(process.env.REACT_APP_BASE+'/api/verify-otp',datas,settings)
            .then((res) => {
                if(res.data.code == 200){
                    moveNext()
                    setProcessing(0)
                }else{
                    setTheError(4,res.data.message)
                    che = 1
                    setProcessing(0)
                }
            }).catch((e) => {
                setTheError(4,'There was an internal error, please try again later')
                che = 1
                setProcessing(0)
            })
        }
    }
    if(steps == 5){
        //check errors in step 5
        if(!sessionStorage.getItem('password') || !sessionStorage.getItem('passwordConfirmation')){
            setTheError(5,'Please fill all fields before proceeding')
        }else{
            if(sessionStorage.getItem('password') != sessionStorage.getItem('passwordConfirmation')){
                setTheError(5,'Passwords do not match')
            }else{
                if(sessionStorage.getItem('password').length < 8){
                    setTheError(5,'You should have at least 8 characters in your password')
                }else{
                    setProcessing(1)
                    //create account 1
                    axios.post(process.env.REACT_APP_BASE+'/api/register',buildRequest()[0],settings)
                    .then((res) => {
                        console.log(res)
                        if(res.data.code == 200){
                            let org_id = res.data.data.hgnjj_
                            let datas = buildRequest()[1]
                            datas.org_id = org_id
                            //create account 2
                            axios.post(process.env.REACT_APP_BASE+'/api/registerCon',datas,settings)
                            .then((res) => {
                                console.log(res)
                                if(res.data.code == 200){
                                    sessionStorage.setItem('access_token',res.data.data.access_token)
                                    sessionStorage.setItem('last',1)
                                    moveNext()
                                    setProcessing(0)
                                }else{
                                    setTheError(5,res.data.message)
                                    che = 1
                                    setProcessing(0)
                                }
                            }).catch((e) => {
                                setTheError(5,'There was an internal error, please try again later')
                                che = 1
                                setProcessing(0)
                            })
                        }else{
                            setTheError(5,res.data.message)
                            che = 1
                            setProcessing(0)
                        }
                    }).catch((e) => {
                        setTheError(5,'There was an internal error, please try again later')
                        che = 1
                        setProcessing(0)
                    })
                }
            }

        }
    }
    if(steps == 6){
        //check errors in step 1
    }

  }
    function buildRequest(){
    let org = {name:sessionStorage.getItem('name'),
                site_prefix:sessionStorage.getItem('site_prefix'),
                phone:sessionStorage.getItem('phone'),
                hmrc_reference:sessionStorage.getItem('hmrc_reference'),
                email_verification:1,
                start_date:sessionStorage.getItem('start_date'),
                end_date:sessionStorage.getItem('end_date'),
                start_set:sessionStorage.getItem('start_date'),
                end_set:sessionStorage.getItem('end_date'),
                charity_reg_no:sessionStorage.getItem('charity_reg_no'),
                hmrc_body:sessionStorage.getItem('hmrc_body'),
                email:sessionStorage.getItem('email'),
                authorization:sessionStorage.getItem('authorization'),
                image:sessionStorage.getItem('image') ? sessionStorage.getItem('image') : null
            }
    let user = {
        first_name:sessionStorage.getItem('first_name'),
        last_name:sessionStorage.getItem('last_name'),
        email:sessionStorage.getItem('email'),
        password:sessionStorage.getItem('password'),
        password_confirmation:sessionStorage.getItem('passwordConfirmation'),
    }
    return [org,user]
  }
    const moveNext = () => {
        setTheError(0,null)
        che = 0
        if(steps < 6) setSteps(steps+1)
        if(steps == 6) setMakePayment(1)
  }
  function goBack(){
    if(steps > 1) setSteps(steps-1)
  }
  let genOrg = sessionStorage.getItem('org') ?  sessionStorage.getItem('org') : {}
  let userOrg = sessionStorage.getItem('user') ?  sessionStorage.getItem('user') : {}
  let dummy = 0
  sessionStorage.getItem('user') ? dummy = 1 : sessionStorage.setItem('user',JSON.stringify(userOrg))
  sessionStorage.getItem('org') ? dummy = 1 : sessionStorage.setItem('org',JSON.stringify(genOrg))
  function Organization(){
    let [genOrg,setGenOrg] = useState(JSON.parse(sessionStorage.getItem('org')))
    const inputOrgData = (val,field) => {
        setGenOrg({
            ...genOrg,
            [field] : val
        })
        sessionStorage.setItem(field,val)
        sessionStorage.setItem('org',JSON.stringify(genOrg))
      }
    return (
        <>
            <div className='flex flex-col space-y-3 px-5 lg:px-20 xl:px-60'>
                <div className='flex flex-col space-y-2 text-[#0E0E0E] font-[600] text-[32px]'>Welcome, Get Started</div>
                <p className='text-[#B30000] text-[15px]'>By signing up, you agree to our terms and conditions found <a className='underline' target='_blank' href='/terms-and-conditions'>here.</a></p>
                <span className='text-[16px] text-[#4F4F4F] pb-10'>Enter the right information about your charity to onboard you.</span>
                <div className='grid grid-cols-2 text-[#505050] text-[16px] text-start gap-x-4 gap-y-5'>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label>Charity name</label>
                        <input onChange={(e) => inputOrgData(e.target.value,'name')} defaultValue={sessionStorage.getItem('name') ? sessionStorage.getItem('name') : ''}  className='border-[1px] rounded-[4px] h-[50px] px-3 outline-none' placeholder='e.g Overcomers church'/>
                    </div>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label>Charity reference number</label>
                        <input onChange={(e) => inputOrgData(e.target.value,'hmrc_reference')} defaultValue={sessionStorage.getItem('hmrc_reference') ? sessionStorage.getItem('hmrc_reference') : ''} className='border-[1px] rounded-[4px] h-[50px] px-3 outline-none' placeholder='e.g. TH576519'/>
                    </div>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label>Charity registration number</label>
                        <input onChange={(e) => inputOrgData(e.target.value,'charity_reg_no')} defaultValue={sessionStorage.getItem('charity_reg_no') ? sessionStorage.getItem('charity_reg_no') : ''} className='border-[1px] rounded-[4px] h-[50px] px-3 outline-none' placeholder='7868013'/>
                    </div>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label>Reserve unique name</label>
                        <input onChange={(e) => inputOrgData(e.target.value,'site_prefix')} defaultValue={sessionStorage.getItem('site_prefix') ? sessionStorage.getItem('site_prefix') : ''} className='border-[1px] rounded-[4px] h-[50px] px-3 outline-none' placeholder='overcomers'/>
                    </div>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label>HMRC Charities Registration Body</label>
                        <select onChange={(e) => inputOrgData(e.target.value,'hmrc_body')} defaultValue={sessionStorage.getItem('hmrc_body') ? sessionStorage.getItem('hmrc_body') : ''} className='border-[1px] rounded-[4px] h-[50px] px-3 pr-5 outline-none' style={{boxSizing:'border-box',borderRadius: '8px',paddingLeft: '16px',
                            WebkitAppearance: 'none',
                            backgroundImage: "url('https://www.svgrepo.com/show/80156/down-arrow.svg')",
                            backgroundRepeat:'no-repeat',
                            backgroundSize: '14px 14px',
                            backgroundPosition: 'calc(100% - 16px)'}}>
                            <option value='null'>Choose Registration</option>
                            <option value='CCEW'>Charity Commission for England and Wales</option>
                            <option value='CCNI'>Charity Commission for Northern Ireland</option>
                            <option value='OSCR'>Office of the Scottish Charity Regulator</option>
                        </select>
                    </div>
                </div>
                <div className='flex flex-col text-start text-[#4F4F4F] pt-3'>
                    <label className='text-[#505050]'>Are you authorised to make a gift claim ?</label>
                     <div className='flex flex-row space-x-8'>
                        <div className='flex flex-row space-x-2'>
                            <input checked = {sessionStorage.getItem('authorization') == 1 ? true : false} onChange={(e) => inputOrgData('1','authorization')} type='radio' className='accent-[#B30000]'/>
                            <span>Yes</span>
                        </div>
                        <div className='flex flex-row space-x-2'>
                            <input checked = {sessionStorage.getItem('authorization') == 0 ? true : false} onChange={(e) => inputOrgData('0','authorization')} type='radio' className='accent-[#B30000]'/>
                            <span>No</span>
                        </div>
                     </div>
                </div>
                {error.id == 1 ? <div className='text-start text-[#EB5757] text-[13px] font-[500] pt-[-10px]'>{error.err}</div> : null}
            </div>
        </>
    )
  }

  function FinancialYear(){
    let fileData = new FormData()
    const [finerror, setFinError] = useState()
    const chooseFile = () => {
        document.getElementById('logo').click()
    }
    const displaySelected = (e) => {
        let file = e.target.files[0]
        if(file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg'){
            fileData.append('image',file)
            setFinError(null)
            setProcessing(1)
            axios.post(process.env.REACT_APP_BASE+'/api/upload-file',fileData,settings)
            .then((res) => {
                if(res.data.code == 200){
                    sessionStorage.setItem('image',res.data.data.image)
                    setProcessing(0)
                    SetselectedImage(file.name)
                }else{
                    document.getElementById('logo').value=null
                    setFinError(res.data.message)
                    setProcessing(0)
                }
            }).catch((e) => {
                document.getElementById('logo').value=null
                setFinError('There was an upload error')
                setProcessing(0)
            })
        }else{
            document.getElementById('logo').value=null
            setFinError('Invalid Image supplied')
        }
    }
    const removeImage = () =>{
        document.getElementById('logo').value=null
        SetselectedImage(null)
    }
    let [genOrg,setGenOrg] = useState(JSON.parse(sessionStorage.getItem('org')))
    const calculateDate = (val) => {
        let today = new Date(val)
        let dat = today.setMonth(today.getMonth() + 11)
        let end = (new Date(dat)).toISOString().slice(0, 10)
        return end;
    }
    const [endDate, setEndDate] = useState(sessionStorage.getItem('date')? calculateDate(sessionStorage.getItem('date')) : '')
    const inputOrgData = (val) => {
        setGenOrg({
            ...genOrg,
            start_date : val
        })
        sessionStorage.setItem('date',val)
        sessionStorage.setItem('start_date',val)
        sessionStorage.setItem('org',JSON.stringify(genOrg))
        let today = new Date(val)
        let dat = today.setMonth(today.getMonth() + 12)
        dat = new Date(dat);
        dat.setDate(dat.getDate() - 1);
        let end = (new Date(dat)).toISOString().slice(0, 10)
        sessionStorage.setItem('end_date',end)
        setEndDate(end)   
        console.log(genOrg)
    }
    
 
    return (
        <>
            <div className='flex flex-col space-y-3 px-5 lg:px-20 xl:px-60'>
                <div className='flex flex-col space-y-2 text-[#0E0E0E] font-[600] text-[32px]'>Organization Details</div>
                <span className='text-[16px] text-[#4F4F4F] pb-10'>Submit your financial year period to help maximize the functions.</span>
                <div className='grid grid-cols-2 text-[#505050] text-[16px] text-start gap-x-4 gap-y-5'>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label>Start date</label>
                        <input onChange={(e) => inputOrgData(e.target.value,'start_date')} type='date' defaultValue={sessionStorage.getItem('start_date') ? sessionStorage.getItem('start_date') : ''} className='border-[1px] rounded-[4px] h-[50px] px-3 outline-none'/>
                    </div>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label>End date</label>
                        <input type='date' readOnly className='border-[1px] rounded-[4px] h-[50px] px-3 outline-none' value={endDate ? endDate : ''}/>
                    </div>
                    <div onClick={chooseFile} className='col-span-2 border-[1px] cursor-pointer rounded-[8px] border-dashed items-center py-5 flex flex-col space-y-2'>
                        <span><img className='w-[32px] h-[32px]' src={upload} /></span>
                        <span>Select your image here (5mb max.)</span>
                        <span className='text-[#828282]'>SVG PNG JPEG or JPG</span>
                        <input onChange={displaySelected} hidden id="logo" type='file'/>
                    </div>
                    {selectedImage ? 
                    <div className='col-span-2 flex flex-row justify-between border-[1px] p-2 rounded-[8px] h-[61px] items-center'>
                        <div className='flex flex-row space-x-2'>
                            <span><img src='' /></span>
                            <span>{selectedImage}</span>
                            <span>(2mb)</span>
                        </div>
                        <span onClick={removeImage} className='rounded-full cursor-pointer h-[24px] w-[24px] p-3 bg-[#FBB4B4] text-[#B30000] grid text-center items-center justify-center'><p className='mt-[-14px]'>x</p></span>
                    </div>
                     : ''}
                     {finerror ? <div className='text-start text-[#EB5757] text-[13px] font-[500] mt-[-10px]'>{finerror}</div> : null}
                    <div className='text-[#4F4F4F] col-span-2 text-center'>You can skip the image upload and do that from your profile</div>
                    
                    {error.id == 2 ? <div className='text-start text-[#EB5757] text-[13px] font-[500] pt-[-10px]'>{error.err}</div> : null}
                </div>
            </div>
        </>
    )
  }

  function Admin(){
    let [userOrg,setUserOrg] = useState(JSON.parse(sessionStorage.getItem('user')))
    let [genOrg,setGenOrg] = useState(JSON.parse(sessionStorage.getItem('org')))
    const inputOrgData = (val,field) => {
        setUserOrg({
            ...userOrg,
            [field] : val
        })
        sessionStorage.setItem('user',JSON.stringify(userOrg))
        sessionStorage.setItem(field,val)
    }
    return (
        <>
            <div className='flex flex-col space-y-3 px-5 lg:px-20 xl:px-60'>
                <div className='flex flex-col space-y-2 text-[#0E0E0E] font-[600] text-[32px]'>Admin Details</div>
                <span className='text-[16px] text-[#4F4F4F] pb-10'>Enter admin information, this information will be required in logging in the dashboard.</span>
                <div className='grid grid-cols-2 text-[#505050] text-[16px] text-start gap-x-4 gap-y-5'>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label>First name</label>
                        <input onChange={(e) => inputOrgData(e.target.value,'first_name')} defaultValue={sessionStorage.getItem('first_name') ? sessionStorage.getItem('first_name'): ''} className='border-[1px] rounded-[4px] h-[50px] px-3 outline-none' placeholder='Enter first name'/>
                    </div>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label>Last name</label>
                        <input onChange={(e) => inputOrgData(e.target.value,'last_name')} defaultValue={sessionStorage.getItem('last_name') ? sessionStorage.getItem('last_name') : ''} className='border-[1px] rounded-[4px] h-[50px] px-3 outline-none' placeholder='Enter last name'/>
                    </div>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label>Email address</label>
                        <input onChange={(e) => inputOrgData(e.target.value,'email')} defaultValue={sessionStorage.getItem('email') ? sessionStorage.getItem('email') : ''} type='email' className='border-[1px] rounded-[4px] h-[50px] px-3 outline-none' placeholder='example@gmail.com'/>
                    </div>
                    <div className='col-span-2 lg:col-span-1 flex flex-col space-y-2'>
                        <label>Phone number</label>
                        <input autoComplete="false" onChange={(e) => inputOrgData(e.target.value,'phone')} defaultValue={sessionStorage.getItem('phone') ? sessionStorage.getItem('phone') : ''} className='border-[1px] rounded-[4px] h-[50px] px-3 outline-none' placeholder='Enter phone number'/>
                    </div>
                    {error.id == 3 ? <div className='text-start text-[#EB5757] text-[13px] font-[500] pt-[-10px]'>{error.err}</div> : null}
                </div>
            </div>
        </>
    )
  }

  function Email(){
    const [act, setAct] = useState()
    let otp = null
    const changeDirection = (e,next) => {
        if(e.target.value){
            if(next != '7'){
                document.getElementById(next).focus()
                setAct(0)
            }else{
                otp = document.getElementById('1').value + document.getElementById('2').value + 
                document.getElementById('3').value + document.getElementById('4').value + 
                document.getElementById('5').value + document.getElementById('6').value
                sessionStorage.setItem('otp',otp)
            }
        }else{
            if(next != '2'){
                if(act){
                    let prevInt = parseInt(next) - 2;
                    let prev = prevInt.toString()
                    document.getElementById(prev).focus()
                    setAct(0)
                }else{
                    setAct(next)
                }
            }
        }
    }
    const [resend, setResend] = useState()
    const [sec, setSec] = useState(20)

    useEffect(() => {
        let countdown;
        if (sec > 0) {
          countdown = setInterval(() => {
            setSec((prevTimer) => prevTimer - 1);
          }, 1000);
        } else {
          setResend(1);
        }
        return () => {
          clearInterval(countdown);
        };
      }, [sec]);
      const ResendOtp = () => {
        if(sec != 0){
            return
        }
        setProcessing(1)
        //send otp
        let datas = {email:sessionStorage.getItem('email')}
        axios.post(process.env.REACT_APP_BASE+'/api/verify-email',datas,settings)
        .then((res) => {
            if(res.data.code == 200){
                setProcessing(0)
                setSec(20)
            }else{
                setTheError(4,res.data.message)
                che = 1
                setProcessing(0)
            }
        }).catch((e) => {
            setTheError(4,'There was an internal error, please try again later')
            che = 1
            setProcessing(0)
        })
      }
    return (
        <>
            <div className='flex flex-col space-y-3 px-5 lg:px-20 xl:px-60'>
                <div className='flex flex-col space-y-2 text-[#0E0E0E] font-[600] text-[32px]'>Email Verification</div>
                <span className='text-[16px] text-[#4F4F4F] pb-10'>We have emailed a six digit code to <span className='font-[500]'>{sessionStorage.getItem('email')}</span> Enter the code below to confirm your email.</span>
                <div className='flex flex-row text-[#505050] text-[16px] text-start space-x-4 justify-center'>
                    <input type='password' id="1" onKeyUp={(e) => changeDirection(e,'2')} maxLength='1' className='rounded-[5px] border-[1px] w-[40px] h-[56px] outline-none text-center text-[#121212] text-[20px] font-[600]'/>
                    <input type='password' id="2" onKeyUp={(e) => changeDirection(e,'3')} maxLength='1' className='rounded-[5px] border-[1px] w-[40px] h-[56px] outline-none text-center text-[20px] font-[600]'/>
                    <input type='password' id="3" onKeyUp={(e) => changeDirection(e,'4')} maxLength='1' className='rounded-[5px] border-[1px] w-[40px] h-[56px] outline-none text-center text-[20px] font-[600]'/>
                    <input type='password' id="4" onKeyUp={(e) => changeDirection(e,'5')} maxLength='1' className='rounded-[5px] border-[1px] w-[40px] h-[56px] outline-none text-center text-[20px] font-[600]'/>
                    <input type='password' id="5" onKeyUp={(e) => changeDirection(e,'6')} maxLength='1' className='rounded-[5px] border-[1px] w-[40px] h-[56px] outline-none text-center text-[20px] font-[600]'/>
                    <input type='password' id="6" onKeyUp={(e) => changeDirection(e,'7')} maxLength='1' className='rounded-[5px] border-[1px] w-[40px] h-[56px] outline-none text-center text-[20px] font-[600]'/>
                </div>
                {processing ? <div className='flex flex-col space-y-2 items-center justify-center pt-[-30px]'><LineWave
                                height="70"
                                width="100"
                                color="#B30000"
                                ariaLabel="line-wave"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                firstLineColor=""
                                middleLineColor=""
                                lastLineColor=""
                                /> <span className='ml-[-30px]'>Please wait</span></div> : <div className='text-center text-[14px] text-[#0E0E0E]'>Did not receive OTP? <span onClick={ResendOtp} className={` ${resend? '':'opacity-50'} text-[#B30000] cursor-pointer`}>{resend ? 'Resend now' : 'Resend in '+sec+' secs.'}</span></div>
                        }
                
                {error.id == 4 ? <div className='text-start text-[#EB5757] text-[13px] font-[500] pt-[-10px]'>{error.err}</div> : null}
            </div>
        </>
    )
  }
 
  function Password(){
    const [pass1,setPass1] = useState()
    const [pass2,setPass2] = useState()
    let [genOrg,setGenOrg] = useState(JSON.parse(sessionStorage.getItem('org')))
    const inputOrgData = (val,field) => {
        setGenOrg({
            ...genOrg,
            [field] : val
        })
        sessionStorage.setItem('org',JSON.stringify(genOrg))
        sessionStorage.setItem(field,val)
        //console.log(genOrg)
    }
    return (
        <>
            <div className='flex flex-col space-y-3 px-5 lg:px-20 xl:px-60'>
                <div className='flex flex-col space-y-2 text-[#0E0E0E] font-[600] text-[32px]'>Set Password</div>
                <span className='text-[16px] text-[#4F4F4F] pb-10'>Please set a safe and strong password for your account</span>
                <div className='grid grid-cols-2 text-[#505050] text-[16px] text-start gap-x-4 gap-y-5'>
                    <div className='col-span-2 flex flex-col space-y-2 w-[90%] sm:w-[70%] lg:w-[60%] mx-auto'>
                        <label>Password</label>
                        <div className='w-full relative'>
                            <input onChange={(e) => inputOrgData(e.target.value,'password')} minLength='8' defaultValue={sessionStorage.getItem('password') ? sessionStorage.getItem('password') : ''}  type={pass1 ? 'text' : 'password'} className='border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none' placeholder='Enter password'/>
                            <span onClick={()=>setPass1(!pass1)} className='absolute right-3 top-4 cursor-pointer '>{pass1 ? <AiOutlineEye/> : <AiOutlineEyeInvisible/>}</span>
                        </div>
                    </div>
                    <div className='col-span-2 flex flex-col space-y-2 w-[90%] sm:w-[70%] lg:w-[60%] mx-auto'>
                        <label>Confirm Password</label>
                        <div className='w-full relative'>
                            <input onChange={(e) => inputOrgData(e.target.value,'passwordConfirmation')} defaultValue={sessionStorage.getItem('passwordConfirmation') ? sessionStorage.getItem('passwordConfirmation') : ''} minLength='8' type={pass2 ? 'text' : 'password'} className={`border-[1px] ${error.id==5 ? 'border-[#EB5757]' : ''} rounded-[4px] w-full h-[50px] px-3 outline-none`} placeholder='Confirm your password'/>
                            <span onClick={()=>setPass2(!pass2)} className='absolute right-3 top-4 cursor-pointer '>{pass2 ? <AiOutlineEye/> : <AiOutlineEyeInvisible/>}</span>
                        </div>
                    </div>
                    {error.id == 5 ? <div className='col-span-2 mx-auto w-[90%] sm:w-[70%] lg:w-[60%] text-[#EB5757] text-[13px] font-[500] mt-[-15px]'>{error.err}</div> : null}
                </div>
            </div>
        </>
    )
  }

  function Pricing(){
    const createSubscription = (plan) => {
        setProcessing(1)
        let datas = {plan:plan}
        let headers = {
            "Access-Control-Allow-Origin" : "*",
            "Content-type": "Application/json",
            "Authorization": 'Bearer '+sessionStorage.getItem('access_token')
            };
        axios.post(process.env.REACT_APP_BASE+'/api/subscriptions/create',datas,{headers:headers})
        .then((res) => {
            if(res.data.code == 200){
                sessionStorage.clear()
                setSuccess(res.data.message)
                setProcessing(0)
            }else{
                setTheError(6,res.data.message)
                che = 1
                setProcessing(0)
            }
        }).catch((e) => {
            setTheError(6,'There was an internal error, please try again later')
            che = 1
            setProcessing(0)
        })
    }

    if(sessionStorage.getItem('chGamsFreeTrial') == 1) {
        sessionStorage.removeItem('chGamsFreeTrial')
        setProcessing(1) 
       createSubscription(1)
    }
    if(sessionStorage.getItem('chGamsFreeTrial') == 2) {
        setChoosePlan(1)
        sessionStorage.removeItem('chGamsFreeTrial')
    }
    if(sessionStorage.getItem('chGamsFreeTrial') == 4) {
        setChoosePlan(4)
        sessionStorage.removeItem('chGamsFreeTrial')
    }

    const subscriptionAction = (id) => {
        // setProcessing(1)
        if(id ==1){
           createSubscription(1)
        }else{
            if(id == 3 || id == 4 || id == 5) setChoosePlan(4)
            else setChoosePlan(1)
        }
    }

    
    const plans = [{id:1,type:'Free',fee:'£0.00',period:'Month',recommend:0},{id:2,type:'Silver',fee:'£161.99',period:'Year',recommend:1},{id:3,type:'Gold',fee:'£291.99',period:'Year',recommend:0}]
    return (
        <>
            <div className='flex flex-col space-y-3 px-5 lg:px-10 xl:px-20'>
                <div className='flex flex-col space-y-2 text-[#0E0E0E] font-[600] text-[32px]'>Plan & Pricing</div>
                <span className='text-[16px] text-[#4F4F4F] pb-10'>We provide flexible pricing and plan just for you.</span>
                <div className='grid grid-cols-3 text-[#505050] text-[16px] text-start gap-x-8 gap-y-10'>
                    {plans.map(({id,type,fee,period,recommend},idd) => {
                        return (
                            <div key={idd} className='relative col-span-3 lg:col-span-1'>
                                <div className={`h-[555px] border-[2px] rounded-[8px] py-5 px-4 border-[#EAEAEA] ${id == 1 ? 'freemium text-white' : id == 2 ? 'silver' : 'price text-white'} flex flex-col space-y-3`}>
                                    <span className={`text-[24px] font-[500] border-b-[1px] ${id == 2 ? 'border-black' : ''} pb-5`}>{type}</span>
                                    <span className='font-[500] text-[32px] pb-3'>{fee}/<span className='pt-5 font-[400] text-[14px]'>{period}</span></span>
                                    <button disabled={success? true : false} onClick={() => subscriptionAction(id)} className={`${id == 2 ? 'bg-[#B30000] text-white' : 'border-[1px] bg-white text-[#4F4F4F] border-none'} rounded-[8px] py-2 text-center text-[14px] font-[600]`}>Choose</button>
                                    <div className='flex flex-col pt-5 space-y-3 text-[14px]'>
                                        <div className='flex flex-row space-x-2 items-center'>
                                            <span><img src={id == 1 ? Check3 : id == 2? check : Check2}/></span>
                                            <span className=''>Members Management</span> 
                                        </div>
                                        <div className='flex flex-row space-x-2 items-center'>
                                            <span><img src={id == 1 ? Check3 : id == 2? check : Check2}/></span>
                                            <span className=''>Donations Management</span>
                                        </div>
                                        <div className='flex flex-row space-x-2 items-center'>
                                            <span><img src={id == 1 ? Check3 : id == 2? check : Check2}/></span>
                                            <span className=''>Groups Managememt</span>
                                        </div>
                                        <div className='flex flex-row space-x-2 items-center'>
                                            <span><img src={id == 1 ? Check3 : id == 2? check : Check2}/></span>
                                            <span className=''>Statistics Overview</span>
                                        </div>
                                        {id == 1 ? 
                                            <div className='flex flex-row space-x-2 items-center'>
                                                <span><img src={Check3}/></span>
                                                <span className=''>3 Months Data Retention</span>
                                            </div>
                                        :null}
                                        {id != 3 ? 
                                            <>
                                            <div className='flex flex-row space-x-2 items-center'>
                                                <span><img src={id == 1 ? Check3 : id == 2? check : Check2}/></span>
                                                <span className=''>Maximum of 3 Users</span>
                                            </div>
                                            </> : null
                                        }
                                        {recommend || id == 3 ? 
                                            <>
                                                <div className='flex flex-row space-x-2 items-center'>
                                                    <span><img src={id == 1 ? Check3 : id == 2? check : Check2}/></span>
                                                    <span className=''>HMRC Claims</span>
                                                </div>
                                                {id == 3 ?
                                                <> 
                                                <div className='flex flex-row space-x-2 items-center'>
                                                    <span><img src={id == 1 ? Check3 : id == 2? check : Check2}/></span>
                                                    <span className=''>Unlimited User Access (Including Donors)</span>
                                                </div>
                                                <div className='flex flex-row space-x-2 items-center'>
                                                    <span><img src={id == 1 ? Check3 : id == 2? check : Check2}/></span>
                                                    <span className=''>Dedicated Relationship Manager</span>
                                                </div>
                                                <div className='flex flex-row space-x-2 items-center'>
                                                    <span><img src={id == 1 ? Check3 : id == 2? check : Check2}/></span>
                                                    <span className=''>Priority Support Service</span>
                                                </div>
                                                <div className='flex flex-row space-x-2 items-center'>
                                                    <span><img src={id == 1 ? Check3 : id == 2? check : Check2}/></span>
                                                    <span className=''>Bulk Donation Upload from excel</span>
                                                </div>
                                                </> : null}
                                            </>
                                             : null
                                        }
                                    </div>
                                </div>
                            </div>
                            
                        )
                    })}
                </div>
                {error.id == 6 ? <div className='text-start text-[#EB5757] text-[13px] font-[500] pt-[-10px]'>{error.err}</div> : null}
            </div>
        </>
    )
  }
  const [makePayment, setMakePayment] = useState()
  const [success, setSuccess] = useState()

  const paymentData = (val,field) => {
    sessionStorage.setItem(field,val)
  }

  const confirmPayment = (session_id,pppp) => {
    axios.get(process.env.REACT_APP_BASE + "/api/subscriptions/confirm/"+session_id+'/'+pppp+'/new', settings())
    .then((res) =>{
      if (res.data.code === 200){
        sessionStorage.setItem('chgamsSubscription',res.data.data.plan)
        setMakePayment(0)
        setChoosePlan(0)
        sessionStorage.clear()
        setSuccess(res.data.message)
        setProcessing(0)
      }else {
        setTheError(6,res.data.message)
        che = 1
        setMakePayment(0)
        setProcessing(0)
      }
      setProcessing(0)
    })
    .catch((error) => {
      console.log(error);
      setProcessing(0)
    })
  }

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const session_id = urlParams.get('session_id');
    const check = urlParams.get('checkierr');
    if (session_id && check) {
      setProcessing(1)
      confirmPayment(session_id,check);
    } 

  }, [])

  let headers = {
    "Access-Control-Allow-Origin" : "*",
    "Content-type": "Application/json",
    "Authorization": 'Bearer '+sessionStorage.getItem('access_token')
    };  
    const [choosePlan, setChoosePlan] = useState()
  const createSubscription = (plan) => {
    setMakePayment(0)
    setProcessing(1)
    let datas = {plan:plan}
    axios.post(process.env.REACT_APP_BASE+'/api/subscriptions/create',datas,{headers:headers})
    .then((res) => {
        if(res.data.code == 200){
            if(res.data.data.url){
                window.location.href=res.data.data.url
            }else{
                setMakePayment(0)
                setChoosePlan(0)
                sessionStorage.clear()
                setSuccess(res.data.message)
                setProcessing(0)
            }
        }else{
            setTheError(6,res.data.message)
            che = 1
            setMakePayment(0)
            setProcessing(0)
        }
    }).catch((e) => {
        setTheError(6,'There was an internal error, please try again later')
        che = 1
        setProcessing(0)
    })
    }

    const ensurePlan = () => {
        if(choosePlan!= 2 && choosePlan!= 3 && choosePlan!=4 && choosePlan!=5){
            alert('Choose a plan')
            return
        }else{
            createSubscription(choosePlan)
        }
    }

  const payingUserSubscription = () => {
    setProcessing(1)
    let datas = {card_num:sessionStorage.getItem('card_num'),expiry_month:sessionStorage.getItem('expiry_month'),
    expiry_year:sessionStorage.getItem('expiry_year'),cvv:sessionStorage.getItem('cvv')
    }
    if(!datas.card_num || !datas.expiry_month || !datas.expiry_year || !datas.cvv){
        setTheError(6,'Please fill fields')
        return
    }
        axios.post(process.env.REACT_APP_BASE+'/api/cards/create',datas,{headers:headers})
        .then((res) => {
            if(res.data.code == 200){
                setMakePayment(0)
                setProcessing(0)
                setChoosePlan(1)
            }else{
                setTheError(6,res.data.message)
                che = 1
                setMakePayment(0)
                setProcessing(0)
            }
        }).catch((e) => {
            setTheError(6,'There was an internal error, please try again later')
            che = 1
            setProcessing(0)
        })
    }

    const continueToLogin = () => {
        window.location.href='/login'
    }

    const changePlanSelect = (id) => {
        setChoosePlan(id)
        //alert(choosePlan)
    }

  return (
    <>
    {makePayment ?
        <div id="defaultModal" tabIndex="-1" aria-hidden="true" className={`fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
        <div className="relative w-[95%] lg:w-[40%] mt-[25%] lg:mt-[10%] max-h-full mx-auto text-[#333]">
            <div className="relative bg-white shadow pt-3">
                <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                    <h3 className="text-[#121927] text-[20px] font-[600]">
                        Payment Method
                    </h3>
                    <button onClick={() => setMakePayment(0)} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                        <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className='flex flex-col mt-3 mb-20 px-6 mt-7 text-start space-y-6 text-[#4F4F4F] text-[16px]'>
                    <div className='flex flex-col space-y-2'>
                        <label className='text-[#828282]'>Card Holder's number</label>
                        <input onChange={(e) => paymentData(e.target.value,'card_num')} className='p-2 border-[1px] rounded-[8px] text-[#4F4F4F]' placeholder='Enter card number' />
                    </div>
                    <div className='flex flex-col space-y-2'>
                        <label className='text-[#828282]'>Card expiry month</label>
                        <input onChange={(e) => paymentData(e.target.value,'expiry_month')} className='p-2 border-[1px] rounded-[8px] text-[#4F4F4F]' placeholder='Enter expiry month' />
                    </div>
                    <div className='grid grid-cols-2 gap-x-3'>
                        <div className='col-span-1 flex flex-col space-y-2'>
                            <label className='text-[#828282]'>Expiration Year</label>
                            <input onChange={(e) => paymentData(e.target.value,'expiry_year')} className='p-2 border-[1px] rounded-[8px] text-[#4F4F4F]' placeholder='Enter expiry year'/>
                        </div>
                        <div className='col-span-1 flex flex-col space-y-2'>
                            <label className='text-[#828282]'>CVV</label>
                            <input onChange={(e) => paymentData(e.target.value,'cvv')} className='p-2 border-[1px] rounded-[8px] text-[#4F4F4F]' placeholder='Enter card cvv'/>
                        </div>
                    </div>
                </div>
                {error.id == 6 ? <div className='text-start text-[#EB5757] text-[13px] font-[500] pt-2'>{error.err}</div> : null}
                <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4">
                    <button onClick={() => setMakePayment(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] text-[#121927] w-[102px] h-[50px] rounded-[8px] bg-white">Cancel</button>
                    {processing ? <span>Please wait..</span> : <button onClick={payingUserSubscription} data-modal-hide="defaultModal" type="button" className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white`}>Continue</button>}
                </div>
            </div>
        </div>
    </div>
    :null} 

{choosePlan == 1 || choosePlan == 2 || choosePlan == 3 ? (
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className={`fixed bg-[rgba(65,_66,_88,_0.62)] rounded-[10px] top-0 bottom-0 left-0 z-[1000] w-full overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}
        >
          <div className="relative w-[95%] lg:w-[40%] mt-[25%] lg:mt-[10%] max-h-full mx-auto text-[#333]">
            <div className="relative bg-white shadow pt-3">
              <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                <p className="text-[#121927] text-[20px] font-[600]">
                  Confirm your plan
                </p>

              </div>
              <div className="p-8">
                <div className="w-full flex justify-between py-4 space-x-2">
                  <div className="col-span-2 flex flex-col space-y-2  mx-auto w-[49%]">
                    <div className="w-full">
                      <input
                        onClick={(e) => changePlanSelect(3)}
                        type="text"
                        name="plan"
                        readOnly
                        className={`border-[1px] rounded-[4px] w-full h-[50px] px-3 text-center cursor-pointer outline-none ${choosePlan == 3 ? 'border-[#B30000]' : ''}`}
                        value="Silver Plan - £13.99/Month"
                      />
                    </div>
                  </div>
                  <div className="col-span-2 flex flex-col space-y-2 mx-auto w-[49%]">
                    <div className="w-full">
                      <input
                        onClick={(e) => changePlanSelect(2)}
                        type="text"
                        name="plan"
                        readOnly
                        className={`border-[1px] rounded-[4px] w-full h-[50px] px-3 text-center cursor-pointer outline-none ${choosePlan == 2 ? 'border-[#B30000]' : ''}`}
                        value="Silver Plan - £161.99/Year"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {processing ? <div>Please wait...</div> : 
              <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] rounded-b-[10px] py-4">
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={() => setChoosePlan(0)}
                  className="border-[1px] border-[#828282] text-[#121927] w-[102px] h-[50px] rounded-[8px] bg-white"
                >
                  Cancel
                </button>
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={ensurePlan}
                  className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white`}
                >
                  Proceed
                </button>
              </div>
              }
            </div>
          </div>
        </div>
      ) : null}

{choosePlan == 4 || choosePlan == 5 ? (
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className={`fixed bg-[rgba(65,_66,_88,_0.62)] rounded-[10px] top-0 bottom-0 left-0 z-[1000] w-full overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}
        >
          <div className="relative w-[95%] lg:w-[40%] mt-[25%] lg:mt-[10%] max-h-full mx-auto text-[#333]">
            <div className="relative bg-white shadow pt-3">
              <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                <p className="text-[#121927] text-[20px] font-[600]">
                  Confirm your plan
                </p>

              </div>
              <div className="p-8">
                <div className="w-full flex justify-between py-4 space-x-2">
                  <div className="col-span-2 flex flex-col space-y-2  mx-auto w-[49%]">
                    <div className="w-full">
                      <input
                        onClick={(e) => changePlanSelect(4)}
                        type="text"
                        name="plan"
                        readOnly
                        className={`border-[1px] rounded-[4px] w-full h-[50px] px-3 text-center cursor-pointer outline-none ${choosePlan == 4 ? 'border-[#B30000]' : ''}`}
                        value="Gold Plan - £24.99/Month"
                      />
                    </div>
                  </div>
                  <div className="col-span-2 flex flex-col space-y-2 mx-auto w-[49%]">
                    <div className="w-full">
                      <input
                        onClick={(e) => changePlanSelect(5)}
                        type="text"
                        name="plan"
                        readOnly
                        className={`border-[1px] rounded-[4px] w-full h-[50px] px-3 text-center cursor-pointer outline-none ${choosePlan == 5 ? 'border-[#B30000]' : ''}`}
                        value="Gold Plan - £291.99/Year"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {processing ? <div>Please wait...</div> : 
              <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] rounded-b-[10px] py-4">
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={() => setChoosePlan(0)}
                  className="border-[1px] border-[#828282] text-[#121927] w-[102px] h-[50px] rounded-[8px] bg-white"
                >
                  Cancel
                </button>
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={ensurePlan}
                  className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white`}
                >
                  Proceed
                </button>
              </div>
              }
            </div>
          </div>
        </div>
      ) : null}

    {success ?
      <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative w-[50%] lg:w-[48%] mt-20 max-h-full mx-auto text-[#333]">
              <div className="relative bg-white shadow pt-4 rounded-[8px] px-8">
                  <div className="flex items-start justify-between px-8">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                      </h3>
                  </div>
                  <div className='flex flex-col space-y-2 mt-3 px-5'>
                    <div className='grid mb-2'><img className='mx-auto' src={successs} /></div>
                    <span className='text-[#121927] font-[600] text-[24px] text-center'>Subscription Successful</span>
                    <span className='text-[#4F4F4F] text-[16px] text-center'>{success}</span>
                  </div>
                  <div className="flex flex-row mt-5 space-x-3 px-8 pb-4 justify-center">
                      <button onClick={continueToLogin} data-modal-hide="defaultModal" type="button" className="border-[#828282] w-full h-[50px] rounded-[8px] bg-[#B30000] text-white grid text-center"><p className="my-auto">Login</p></button>
                  </div>
              </div>
          </div>
      </div>
      :null}

    <div className={`${makePayment || success ? 'bg-[#D9D9D9]' : ''}`}>
        <div className='flex flex-col'>
            <div className='py-10 px-5 lg:px-20'><img src={logo} /></div>
            <div className='grid grid-cols-6'>
                {tSteps.map((item,id) => {
                    return (
                        <span key={id} className={`col-span-1 ${item <= steps? 'border-[#B30000]' : 'border-[#D5D5D5]'} ${item < steps ? 'mr-2' : ''} border-b-[1px]`}></span>
                    )
                })}
            </div>
            <div className='my-10 lg:my-20'>
                {steps == 1 ? <Organization></Organization> : steps == 2 ? <FinancialYear></FinancialYear> : steps == 3 ? <Admin></Admin> : steps == 4 ? <Email></Email> : steps == 5 ? <Password></Password> : <Pricing></Pricing>}
            </div>
            <div className='px-5 lg:px-20 grid grid-cols-1 mb-10'>
                <span className='col-span-1 border-b-[1px]'></span>
                <div className='col-span-1 flex flex-row justify-between mt-7 items-center'>
                    <span className='text-[#E33A3A]'>Step {steps}/6</span>
                    <div className='flex flex-row space-x-5 items-center'>
                        {steps > 1 ? <>{processing || sessionStorage.getItem('last') == 1 ? '' : <span onClick={goBack} className='text-[#0E0E0E] font-[600] cursor-pointer'>Go back</span>} </> : ''}
                        {processing ? <div className='flex flex-col space-y-2 items-center justify-center'><LineWave
                                height="100"
                                width="100"
                                color="#B30000"
                                ariaLabel="line-wave"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                firstLineColor=""
                                middleLineColor=""
                                lastLineColor=""
                                /> <span className='ml-[-30px]'>Please wait</span></div> : <>{sessionStorage.getItem('last') == 1 ? '' : <button onClick={changeStep} className='bg-[#B30000] px-3 py-2 rounded-[8px] text-white w-[144px] h-[50px] font-[600]'>Continue</button>}</>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Onboarding