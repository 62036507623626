import React, { useEffect, useState } from "react";
import General from "./general";
import Roles from "./roles";
import Security from "./security";
import Billings from "./billing";
import Giving from "./giving";
import Letters from "./letters";
import axios from "axios";
import settings from "../../../components/getrequest";

function Settings() {
  let urlbase = process.env.REACT_APP_BASE;
  const pages = sessionStorage.getItem('role') !=1 ? [
    { id: 3, title: "Security" },
  ] : [
    { id: 1, title: "General" },
    { id: 2, title: "Admins" },
    { id: 3, title: "Security" },
    { id: 4, title: "Subscription" },
    // { id: 5, title: "Givings" },
    // { id: 6, title: "Letters" },
  ];
  const [aP, setAP] = useState(sessionStorage.getItem('role') != 1 ? 3 :1);

  const [periods, setPeriods] = useState([])
  const allPeriods = async () => {
    axios
      .get(urlbase + "/api/settings/periods", settings())
      .then((res) => {
        if (res.data.code === 200) {
          setPeriods(res.data.data)
          console.log(res.data.data)
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [periodToChange, setPeriodToChange] = useState()
  const [processing, setProcessing] = useState()
  const changePeriod = async () => {
    if(sessionStorage.getItem('end_year') == periodToChange.end){
      return;
    }
    setProcessing(1)
    axios
      .post(urlbase + "/api/settings/periods/set",periodToChange, settings())
      .then((res) => {
        if (res.data.code === 200) {
          sessionStorage.setItem('end_year',periodToChange.end)
          alert('Period changed successfully')
          window.location.reload()
        } else {
          console.log("Error");
          setProcessing(0)
        }
      })
      .catch((error) => {
        console.log(error);
        setProcessing(0)
      });
  };

  const setThePeriod = (e) => {
    let val = e.target.value
    var start = val.substring(0, 4);
    var end = val.substring(4);
    console.log(val)
    setPeriodToChange({...periodToChange,
      end:end,start:start
    })
  }

  useEffect(() =>{
    allPeriods()
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const session_id = urlParams.get('session_id');
    if (session_id) {
      setAP(4)
    } 

  }, [])
  return (
    <>
      <div className="flex flex-col text-start px-8 mt-5 space-y-4">
        <div className="text-[#121927] font-[600] text-[24px] flex flex-row justify-between">
          <span>Settings</span>
          {/* {sessionStorage.getItem('role') !=1 ? null :
          <div className="flex flex-row space-x-3 text-[16px] items-center">
            <select onChange={(e) => setThePeriod(e)} className="p-2 text-[#4F4F4F] border-[1px] rounded-[8px] outline-none">
            <option value='currenty' selected={false}>Current Year</option>
              {periods.map(({start,end}) => {
                return (
                  <option value={start.toString()+end.toString()} selected={end == sessionStorage.getItem('end_year') ? true : false}>{start == end ? start : start+'-'+end}</option>
                )
              })}
            </select>
            {
              processing ? <p>Processing ...</p> :
              <button onClick={changePeriod} className="bg-[#B30000] p-2 text-white rounded-[4px] h-[40px]" type="button">
              Change period
            </button>
            }
          </div>
          } */}
        </div>
        <div className="border-[1px] rounded-[8px] p-2 flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-3 text-center lg:text-start">
          {pages.map(({ id, title }) => {
            return (
              <div
                onClick={() => setAP(id)}
                key={id}
                className={`${
                  aP == id ? "bg-black text-white" : "text-[#4F4F4F]"
                } p-2 cursor-pointer rounded-[4px]`}
              >
                {title}
              </div>
            );
          })}
        </div>
        {aP == 1 ? (
          <General></General>
        ) : aP == 2 ? (
          <Roles></Roles>
        ) : aP == 3 ? (
          <Security></Security>
        ) : aP == 4 ? (
          <Billings></Billings>
        ) : aP == 5 ? (
          <Giving></Giving>
        ): (
          <Letters></Letters>
        )}
      </div>
    </>
  );
}

export default Settings;
