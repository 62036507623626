import React from 'react'
import Layout from '../../layout'

function Giving() {
  return (
    <>
        <Layout props='addgiving'></Layout>
    </>
  )
}

export default Giving