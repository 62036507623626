import React from 'react'
import Layout from '../../layout'

function TicketDetails() {
  return (
    <>
        <Layout props='ticketdetails'></Layout>
    </> 
  )
}

export default TicketDetails