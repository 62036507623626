import React from 'react'
import Layout from '../../layout'

function ExitedMemberDetails() {
  return (
    <>
        <Layout props='exitedmemberdetails'></Layout>
    </>
  )
}

export default ExitedMemberDetails