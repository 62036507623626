import React, {useState,useEffect} from 'react'
import search from "../../../images/icons/search.svg"
import { ThreeDots } from 'react-loader-spinner'
import filter from "../../../images/icons/filter.svg"
import HistoryFull from './historySingle'
import settings from '../../../components/getrequest'
import back from "../../../images/icons/back.svg"
import axios from 'axios'

function ClaimHistory() {
  const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
  let urlbase = process.env.REACT_APP_BASE;
  const [error, setError] = useState(0)
  const [processing, setProcessing] = useState(0)
    const claimsheaders = [
        { label: "id", key: "id" },
        { label: "name", key: "name" },
        { label: "date", key: "date" },
        { label: "member_id", key: "member_id" },
        { label: "amount", key: "amount" },
        { label: "type", key: "type" },
        { label: "payment", key: "payment" },
      ]
      const [claimsHistory, setclaimsHistory] = useState([])
    const[fetching, setFetching] = useState(1)
    const sliceValue = 25
    const [givingArray, setgivingArray] = useState([])
    const[givingCurrent, setgivingCurrent] = useState([])
    const[givingCurrentDefault, setgivingCurrentDefault] = useState()
    const [currentPage,setCurrentPage] = useState(0)

    const [total, setTotal] = useState(0)

    const [tableStart, setTableStart] = useState(0)
    const [tableEnd, setTableEnd] = useState(5)
    const getHistory = (id,date=null) => {
      let url = date ? '/api/claims/history/'+id+'/'+date : '/api/claims/history/'+id
        axios.get(urlbase+url,settings())
        .then((res) => {
            if(res.data.code == 200){
              setTotal(res.data.data.total)
              setclaimsHistory(res.data.data.donations)
              console.log(res.data.data.donations)
              const allData = res.data.data.donations;
              const slicedData = [];
              for (let i = 0; i < allData.length; i += sliceValue) {
                slicedData.push(allData.slice(i, i + sliceValue));
              }
              setgivingCurrent(slicedData[0].length > 0 ?slicedData[0] : [])
              setgivingArray(slicedData);
            }else{
                setError(res.data.message)
                setProcessing(0)
            }
        }).catch((e) => {
            setError('There was an internal error, please try again later')
            setProcessing(0)
        })
  

    }
    useEffect(()=>{
      getHistory(sessionStorage.getItem('historyId'))
      setFetching(0)
    },[])

    const filterByDate = (date) => {
      if(date){
        setclaimsHistory([])
        setgivingCurrent([])
        getHistory(sessionStorage.getItem('historyId'),date)
      }else{
        setclaimsHistory([])
        setgivingCurrent([])
        getHistory(sessionStorage.getItem('historyId'))
      }

    }

    const [pageCount, setPageCount] = useState(0) 
      const PreviousPage = () => {
        if(currentPage > 0){
          if(givingArray.length > 5){
            if(tableStart != 0){
              setTableStart(tableStart-1)
              setTableEnd(tableEnd-1)
              }
            }
          let thenewPage = currentPage
          let counted = pageCount
          let start = 2
          while(start > 0){
              counted -= givingArray[thenewPage].length
              thenewPage--;
              start--;
          }
          setPageCount(pageCount - givingArray[currentPage].length)
          setCurrentPage(currentPage-1)
          setgivingCurrent(givingArray[currentPage-1])
        }
      }

      const NextPage = () => {
        if(currentPage != givingArray.length-1){
          if(givingArray.length > 5){
            if(givingArray.length != tableEnd){
              setTableStart(tableStart+1)
              setTableEnd(tableEnd+1)
            }
          }
            let thenewPage = currentPage
            let counted = 0
            while(thenewPage >= 0){
                counted += thenewPage > 0 ? givingArray[thenewPage-1].length : 0 + givingArray[thenewPage].length
                thenewPage--;
            }
            setCurrentPage(currentPage+1)
            setgivingCurrent(givingArray[currentPage+1])
            setPageCount(pageCount+givingArray[currentPage+1].length)
        }
      }

      const FirstPage = () => {
        numberedPage(0)
        setTableStart(0)
        setTableEnd(5)
      }
      
      const LastPage = () => {
        numberedPage(givingArray.length-1)
        if(givingArray.length > 5){
            setTableStart(givingArray.length - 5)
            setTableEnd(givingArray.length)
        }
      }

      const numberedPage = (id) => {
        let count = 0
        let counted = 0;
      
        while(count < id){
          counted += givingArray[count].length
          count++;
        }
        setPageCount(counted+givingArray[id].length)
      
        setCurrentPage(id)
        setgivingCurrent(givingArray[id])
      }
      const [searchItem, setSearchItem] = useState("");
      //Active donation
      const [activeDonation, setActiveDonation] = useState()
      const changeActiveDon = (id) => {
        if(activeDonation == id) setActiveDonation(null)
        else setActiveDonation(id)
      }
      
      //giving types
      const givingTypes = ['Cash','Individual','Aggregated','GASDS']
      
      //filter
      const [filterr, setFilterr] = useState()
      const filterAction = () => {
        if(filterr) setFilterr(0)
        else setFilterr(1)
      }

      function Goback(){
        sessionStorage.setItem('singleHistory',1);
        sessionStorage.setItem('claimsCat',2);
        window.location.href= '/'+base+'/claims'
      }
  return (
    <>  
        <div>
        <div className='text-start mt-3'>
          <div className='grid grid-cols-8'>
            <div onClick={Goback} className='col-span-1 flex flex-row cursor-pointer space-x-2'>
              <img src={back}/>
              <span className='text-[#4F4F4F] text-[16px]'>Back</span>
            </div>
          </div>

        </div>
          <div className='col-span-1 flex lg:flex-row flex-col space-y-4 lg:space-y-0 justify-between mt-5 lg:items-center text-start'>

            <div className='flex flex-row space-x-4'>
              <div className='flex flex-col items-'>
                <span className='text-[#8A8A8A] font-[400] text-[14px]'>Total Tax Reclaimed</span>
                <span className='text-[#121927] font-[600] text-[20px] lg:text-[28px]'>£{total.toLocaleString()}</span>
              </div>
            </div>
            <div className='flex lg:flex-row flex-col space-y-4 lg:space-y-0 lg:space-x-3 lg:items-center'>
              <div className='relative'>
                <div className='absolute top-3 z-40 left-4'><img src={search} /></div>
                <input onChange={(e) => setSearchItem(e.target.value)} className='relative w-[280px] bg-[#F7F7F7] h-[40px] pl-10 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none' placeholder='Search' />
              </div>
              <div className='' onChange={(e) => filterByDate(e.target.value)}>
                <input className='border-[1px] h-[40px] w-full lg:w-[200px] rounded-[4px] outline-none border-[#D9D9D9] p-1' type='date' />
              </div>
              <div>
            </div>
            </div>
          </div>
          <div className='col-span-1 mt-5 border-[1px] rounded-[8px]'>
            <table className="table-auto overflow-x-scroll w-full block divide-y divide-gray-200 ">
              <thead className='bg-[#F7F7F7] w-full'>
                  <tr className='px-7 py-1'>
                  <th className='px-7 py-1 text-start text-[#292D32] font-[400] w-[20%]'>Donor Name</th>
                  <th className='px-7 py-1 text-start text-[#292D32] font-[400] w-[20%]'>Member ID</th>
                  <th className='px-7 py-1 text-start text-[#292D32] font-[400] w-[20%]'>Payment Method</th>
                  <th className='px-7 py-1 text-start text-[#292D32] font-[400] w-[20%]'>Amount</th>
                  <th className='px-7 py-1 text-start text-[#292D32] font-[400] w-[20%]'>Date</th>
                  </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 w-full'>
                  {givingCurrent.filter((row) =>
                  !searchItem.length || row.name
                  .toString()
                  .toLowerCase()
                  .includes(searchItem.toString().toLowerCase()) 
                  ).map(({id,name,member_id,date,amount,payment_method,payment_date},index) => {
                      return (
                          <tr key={id}>
                              <td className='px-7 py-3 text-start text-[#4F4F4F]'>{name}</td>
                              <td className='px-7 py-3 text-start text-[#4F4F4F]'>{member_id}</td>
                              <td className='px-7 py-3 text-start text-[#4F4F4F]'>{payment_method}</td>
                              <td className='px-7 py-3 text-start text-[#4F4F4F]'>£{amount.toLocaleString()}</td>
                              <td className='px-7 py-3 text-start text-[#4F4F4F]'>{payment_date}</td>
                          </tr>
                          )
                      }) 
                  }
              </tbody>
            </table>
          </div>
          <div className='col-span-1 flex flex-row justify-between items-center'>
            {searchItem? '' : <span className='text-[#333333] text-[16px]'>Showing <span>{givingCurrent.length}</span>/<span>{claimsHistory.length}</span> results</span>}
            {fetching ? 
              <div className='col-span-8 lg:col-span-6 flex flex-col justify-center items-center '>
                  <ThreeDots height="80" width="80" radius="9" color="#333333" ariaLabel="three-dots-loading"
                  wrapperStyle={{}} wrapperClassName="" visible={true}
                  />
              </div> 
              :
            <div className={`ml-auto grid grid-cols-6 gap-x-4`}>
                  <div className={`mt-5 col-span-6 flex flex-row gap-x-3 mb-5 lg:ml-auto items-center`}>
                      <div onClick={PreviousPage} className={`border-[#BCBCBC] h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`<`}</div>
                      {givingArray.length > 5 ? <span className={`cursor-pointer text-[14px] font-[500] ${currentPage > 0 ? 'text-[#B30000]' : ''}`} onClick={FirstPage} >First</span> : null }
                      {givingArray.slice(tableStart,tableEnd).map((givingArrayS,index)=> {
                          return (
                              <div key={index} onClick={()=>numberedPage(givingArray.indexOf(givingArrayS))} className={`transitiion-all h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto text-start duration-500 border-[#BCBCBC] font-[600] text-[18px] border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center ${currentPage == givingArray.indexOf(givingArrayS) ? 'text-white bg-[#B30000] border-[#B30000] hover:bg-[#B30000] hover:text-white' :'text-[#292D32] bg-white hover:text-white hover:bg-[#B30000]'} hover:text-[#1D242D] cursor-pointer`}>{givingArray.indexOf(givingArrayS)+1}</div>
                          )
                      })}
                      {givingArray.length > 5 ? <span className={`cursor-pointer text-[14px] font-[500] ${currentPage < givingArray.length-1 ? 'text-[#B30000]' : ''}`} onClick={LastPage}>Last</span> : null }
                      <div onClick={NextPage} className={`border-[#BCBCBC] border-[1px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`>`}</div>
                  </div>
              </div>
            }
          </div>
        </div>
    </>
  )
}

export default ClaimHistory