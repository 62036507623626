import { useState } from "react";
import Mark1 from "../../../images/icons/mark1.svg";
import Mark2 from "../../../images/icons/mark2.svg";
import axios from "axios";
import settings from "../../../components/getrequest";


const Supot = () => {
  let urlbase = process.env.REACT_APP_BASE;
  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    console.log(data);

  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(!data.email || !data.name || !data.message){
      alert('Please fill all fields')
    } else {
      axios
        .post(urlbase + "/api/contact", data)
        .then((res) => {
          console.log(res);
          alert(res.data.message)
          clearInputFields();
        window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    }  
  }
  const clearInputFields = () => {
  document.getElementById("email").value = "";
  document.getElementById("name").value = "";
  document.getElementById("message").value = "";

  }

  return (
    <>
      <div className="bg-gradient-to-r from-white to-[#FFECEC] h-[500px] relative">
        <div className="max-w-[1440px] w-[90%] m-auto py-10 flex flex-col items-center">
          <div className="mark2 absolute top-20 left-20 animate-bounce">
            <img src={Mark2} alt="Mark2 Image" />
          </div>
          <div className="flex flex-col space-y-10 justify-center items-center text-center pt-8">
            <p className="text-[#0E0E0E] text-[20px] md:text-[48px] font-[600] leading-[130%]">
              Is there a way we can <br />
              <span className="bg-[#B30000] text-white">help you?</span>
            </p>
            <p className="text-[#686868] text-[24px] font-[300] leading-[150%] md:w-[880px]">
              Our dedicated support team is ready to help you with any
              questions, concerns, or issues you may have.
            </p>
            <div></div>
          </div>
          <div className="mark1 absolute bottom-40 right-20 animate-bounce">
            <img src={Mark1} alt="Mark1 Image" />
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="max-w-[1440px] w-[90%] m-auto py-28">
          <div className="w-full flex justify-between pb-10">
            <div className="w-[49%]">
              <input
                type="text"
                placeholder="Enter your name"
                name="name"
                id="name"
                value={data.name}
                className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none bg-[#F8F8F9]"
                onChange={handleChange}
              />
            </div>
            <div className="w-[49%]">
              <input
                type="text"
                placeholder="Email Address"
                name="email"
                id="email"
                value={data.email}
                onChange={handleChange}
                className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none bg-[#F8F8F9]"
              />
            </div>
          </div>
          <textarea
            onChange={handleChange}
            name="message"
            id="message"
            value={data.message}
            placeholder="Type Your Comment here..."
            className="border-[1px] rounded-[4px] w-full h-[150px] px-3 py-2 outline-none bg-[#F8F8F9]"
          ></textarea>
          <div className="mt-6">
            <button className="border-[1px] bg-[#B30000] text-white px-20 h-[40px] cursor-pointer rounded-[8px] text-[16px] font-[600] leading-normal">
              Send Message
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default Supot;
