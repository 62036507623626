import React,{useState,useEffect,useRef} from 'react'
import donations from '../../images/icons/donations.svg'
import donors from '../../images/icons/donors.svg'
import members from '../../images/icons/tot_mem.svg'
import more from '../../images/icons/more.svg'
import up from '../../images/icons/up.svg'
import down from '../../images/icons/down.svg'
import { Line,Bar,Doughnut} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from 'axios'
import { LineWave } from  'react-loader-spinner'
import settings from '../../components/getrequest'
import { format } from 'date-fns';
import { Chart,Title,Tooltip,LineElement,Legend,CategoryScale,LinearScale,PointElement,Filler,ArcElement,BarElement } from 'chart.js';
import { Link } from 'react-router-dom' 
Chart.register(
  Title,BarElement,Tooltip,LineElement,Legend,CategoryScale,LinearScale,PointElement,Filler,ArcElement,ChartDataLabels
)
function Home() {
  const [dbRetentionData, setDbRetentionData] = useState()
  const [donationsStats, setDonationsStats] = useState({total:0,rate:0,suffix:'this year'})
  const [membersStats, setMembersStats] = useState({total:0,rate:0,suffix:'this year'})
  const [donorsStats, setDonorsStats] = useState({total:0,rate:0,suffix:'this year'})
  const [donationsData, setDonationsData] = useState([])
const [error, setError] = useState(0)
const [processing, setProcessing] = useState(0)
const [urlbase, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
const [chartLabels, setChartLabels] = useState([]) 
const [barLabels, setBarLabels] = useState([])
let base = process.env.REACT_APP_BASE;
    useEffect(()=>{
      setProcessing(1)
      //call donations received
      axios.get(base+'/api/givings/stats',settings())
      .then((res) => {
          if(res.data.code == 200){
            setDonationsStats(res.data.data)
          }else{
              setError(res.data.message)
              setProcessing(0)
          }
      }).catch((e) => {
          setError('There was an internal error, please try again later')
          setProcessing(0)
      })

      //call members stats
      axios.get(base+'/api/members/stats',settings())
      .then((res) => {
          if(res.data.code == 200){
            setMembersStats(res.data.data)
          }else{
              console.log(res.data.message)
          }
      }).catch((e) => {
          console.log('There was an internal error, please try again later')
          setProcessing(0)
      })

      //call donors stats
      axios.get(process.env.REACT_APP_BASE+'/api/members/donors',settings())
      .then((res) => {
          if(res.data.code == 200){
            setDonorsStats(res.data.data)
          }else{
              console.log(res.data.message)
          }
      }).catch((e) => {
          console.log('There was an internal error, please try again later')
          setProcessing(0)
      })
      //call donations chart data
      axios.get(base+'/api/givings/chart',settings())
      .then((res) => {
          if(res.data.code == 200){
            setChartLabels(res.data.data.labels)
            setData({
              labels:res.data.data.labels,
              datasets:[
                {
                  labels:[],
                  data:res.data.data.data,
                  backgroundColor:'white',
                  borderColor:'#B30000',
                  borderWidth: 1.5,
                  tension:0,
                  fill:false,
                  radius:5,
                  pointStyle:'circle',
                  pointBorderColor:'#F5C4BA',
                  pointBorderWidth:4,
                  pointBackgroundColor:'#B30000'
                },
              ],
            })
          }else{
              console.log(res.data.message)
          }
      }).catch((e) => {
          console.log('There was an internal error, please try again later')
          setProcessing(0)
      })

      //call performance chart data
      axios.get(base+'/api/givings/bar',settings())
      .then((res) => {
          if(res.data.code == 200){
            setBarLabels(res.data.data.labels)
            setPerformance({
              labels:res.data.data.labels,
              datasets:[
                {
                  barPercentage: 0.6,
                  data:res.data.data.data,
                  width:66,
                  backgroundColor:['#B30000','#D58936','#4A1942'],
                  borderColor:'#B30000',
                  borderWidth:0,
                  tension:0.4,
                  fill:true,
                },
              ],
            })
          }else{
              console.log(res.data.message)
          }
      }).catch((e) => {
          console.log('There was an internal error, please try again later')
          setProcessing(0)
      })

      //call retention doughnut data
      axios.get(base+'/api/givings/retention',settings())
      .then((res) => {
          if(res.data.code == 200){
            setDbRetentionData(res.data.data)
            setRetension({
              datasets:[
                {
                  data:res.data.data.data,
                  backgroundColor:['#E0E0E0','#D58936'],
                  borderColor:'#068374',
                  borderWidth:0,
                  tension:0.1,
                  fill:true,
                  pointStyle:false,
                  cutout:'90%',
                },
              ],
            })
            setCenterText({
              id:'centerText',
              beforeDatasetsDraw(chart,args,pluginOptions) {
                  const {ctx} = chart
                  ctx.save()
                  ctx.font = "normal 37.7px sans-serif"
                  ctx.fillStyle = '#333333'
                  ctx.textAlign = 'center'
                  ctx.BASEine = 'middle'
                  const x = chart.getDatasetMeta(0).data[0].x
                  const y = chart.getDatasetMeta(0).data[0].y
                  ctx.fillText(res.data.data.total,x,y-10)
                  ctx.font = "normal 16px sans-serif"
                  ctx.fillStyle = '#8A8A8A'
                  ctx.fillText('Total',x,y+20)
              }
            })
          }else{
              console.log(res.data.message)
          }
      }).catch((e) => {
          console.log('There was an internal error, please try again later')
          setProcessing(0)
      })

      //call donations data
      axios.get(base+'/api/givings',settings())
      .then((res) => {
          if(res.data.code == 200){
            setDonationsData(res.data.data)
            setProcessing(0)
          }else{
              console.log(res.data.message)
          }
      }).catch((e) => {
          console.log('There was an internal error, please try again later')
          setProcessing(0)
      })
    },[])

      const [data, setData] = useState()
    
      const options = {
        plugins:{
          datalabels: {
            display:false
          },
         legend: {
          display: false
         },
         chartAreaBorder: {
          borderColor: 'red',
          borderWidth: 2,
          borderDash: [5, 5],
          borderDashOffset: 2,
        }
        },
        scales:{
          x: {
            grid: {
              display: false,
              border: false,
            },
            ticks: {
            },
          },
          y:{
            suggestedMin: 0,
            border:{
              display:false,
            },
            grid: {
            },
            ticks: {
              stepSize:1,
              padding:0,
              callback: function (val, index, ticks) {
                return ('£'+val+'k'); 
              }
            },
            
          }
      }
       }
       const [performance, setPerformance] = useState()
      const options2 = {
        maintainAspectRatio: false,
        events: [],
        plugins:{
          datalabels: {
            color: 'white',
            display:false
          },
         legend: {
          display: false
         },
        },
        scales:{
            x: {
              grid: {
                display: false,
                border: false,
                
              },
              ticks: {
              },
            },
            y:{
              suggestedMin: 0,
              border:{
                display:false,
              },
              grid: {
              },
              ticks: {
                stepSize:1,
                padding:0,
                callback: function (val, index, ticks) {
                  return ('£'+val+'k'); 
                }
              },
              
            }
        }
       }
       const [centerText,setCenterText] = useState()
       const [retention, setRetension] = useState()

      const options3 = {
        events: [],
        plugins:{
        
          datalabels: {
            color: 'black',
            display: false,
            formatter: function(value, context) {
              return value+'%';
            },
          },
         legend: {
          display: false
         },
        },
       }

const [morre, setMorre] = useState()
const showMore = (e,id) => {
  e.stopPropagation();
    if(morre){
        if(morre ==id)setMorre(false)
        else setMorre(id)
    }else{
        setMorre(id)
    }
}

const filterRef = useRef(null);
const handleOutsideClick = (event) => {
  setTimeout(() => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setMorre(false);
    }
  }, 0);
};

useEffect(() => {
  document.addEventListener('click', handleOutsideClick);
  return () => {
    document.removeEventListener('click', handleOutsideClick);
  };
}, []);

function ChangeStatsPeriod(id,period){
  //setProcessing(1)
  let url1 = base+'/api/givings/stats'
  let url2 = base+'/api/members/stats'
  let url3 = base+'/api/members/donors'
  axios.get((id==1?url1:id==2?url2:url3)+'?period='+period,settings())
  .then((res) => {
      if(res.data.code == 200){
        id == 1 ? setDonationsStats(res.data.data) : id== 2 ? setMembersStats(res.data.data) : setDonorsStats(res.data.data)
        setProcessing(0)
      }else{
          setError(res.data.message)
          setProcessing(0)
      }
  }).catch((e) => {
      setError('There was an internal error, please try again later')
      setProcessing(0)
  })
  setMorre(false)
}
const [chosen1,setChosen1] = useState(1)
const [chosen2,setChosen2] = useState(1)
const [chosen3,setChosen3] = useState(1)
function ChangeChartsPeriod(id,period){
  id == 1 ? setChosen1(id) : id == 2 ? setChosen2(id) :setChosen3(id)
 // setProcessing(1)
  let url1 = base+'/api/givings/chart'
  let url2 = base+'/api/givings/bar'
  let url3 = base+'/api/givings/retention'
  axios.get((id==1?url1:id==2?url2:url3)+'?period='+period,settings())
  .then((res) => {
      if(res.data.code == 200){
        if(id == 1){    
          setChartLabels(res.data.data.labels)         
        setData({
          labels:res.data.data.labels,
          datasets:[
            {
              labels:[],
              data:res.data.data.data,
              backgroundColor:'white',
              borderColor:'#B30000',
              borderWidth: 1.5,
              tension:0,
              fill:false,
              radius:5,
              pointStyle:'circle',
              pointBorderColor:'#F5C4BA',
              pointBorderWidth:4,
              pointBackgroundColor:'#B30000'
            },
          ],
        })} else if(id== 2){   
          setBarLabels(res.data.data.labels)     
        setPerformance({
          labels:res.data.data.labels,
          datasets:[
            {
              barPercentage: 0.6,
              data:res.data.data.data,
              width:66,
              backgroundColor:['#B30000','#D58936','#4A1942'],
              borderColor:'#B30000',
              borderWidth:0,
              tension:0.4,
              fill:true,
            },
          ],
        }) 
      }else{
        setDbRetentionData(res.data.data)
        setRetension({
          datasets:[
            {
              data:res.data.data.data,
              backgroundColor:['#D58936','#E0E0E0'],
              borderColor:'#068374',
              borderWidth:0,
              tension:0.1,
              fill:true,
              pointStyle:false,
              cutout:'90%',
            },
          ],
        })
        setCenterText({
          id:'centerText',
          beforeDatasetsDraw(chart,args,pluginOptions) {
              const {ctx} = chart
              ctx.save()
              ctx.font = "normal 37.7px sans-serif"
              ctx.fillStyle = '#333333'
              ctx.textAlign = 'center'
              ctx.BASEine = 'middle'
              const x = chart.getDatasetMeta(0).data[0].x
              const y = chart.getDatasetMeta(0).data[0].y
              ctx.fillText(res.data.data.total,x,y-10)
              ctx.font = "normal 16px sans-serif"
              ctx.fillStyle = '#8A8A8A'
              ctx.fillText('Total',x,y+20)
          }
        })
      }
        setProcessing(0)
      }else{
          setError(res.data.message)
          setProcessing(0)
      }
  }).catch((e) => {
      setError('There was an internal error, please try again later')
      setProcessing(0)
  })
}

  return (
    <>{processing ? <span className='flex flex-row items-center justify-center mt-40'><LineWave height="100" width="100" color="#B30000" ariaLabel="line-wave" wrapperStyle={{marginTop:'-40px'}}
    wrapperClass="" visible={true} firstLineColor="" middleLineColor="" lastLineColor=""/> <span className='font-[500]'>Getting things set</span></span>
     :
        <div className='flex flex-col mt-5 mb-5 px-8'>
            <div className='grid grid-cols-6 gap-x-4 gap-y-4'>
                <div className='col-span-6 lg:col-span-2 grid grid-cols-1 p-3 border-[1px] rounded-[8px]'>
                    <div className='col-span-1 flex flex-row justify-between items-center'>
                        <img src={donations} />
                        <div className='relative'>
                        {/* <img onClick={(e) => showMore(e,1)} className='h-[12px] cursor-pointer' src={more} /> */}
                            { morre && morre==1 ? 
                                <span ref={filterRef} className='absolute right-5 top-0 w-[100px] text-[#8A8A8A] bg-white flex flex-col rounded-[8px] border-[1px]'>
                                    <span className='border-b-[1px] p-2 cursor-pointer' onClick={() => ChangeStatsPeriod(1,1)}>This Year</span>
                                    <span className='border-b-[1px] p-2 cursor-pointer' onClick={() => ChangeStatsPeriod(1,2)}>This Month</span>
                                    <span className='p-2 cursor-pointer' onClick={() => ChangeStatsPeriod(1,3)}>This Week</span>
                                </span>
                                : null
                            }
                        </div>
                    </div>
                    <div className='col-span-1 text-start font-[600] text-[28px] mt-8'>£{donationsStats.total.toLocaleString()}</div>
                    <div className='col-span1 flex flex-row text-start text-[#8A8A8A] justify-between items-center mt-1'>
                        <span className='text-[14px] font-[400]'>Donations Received</span>
                        <span className='flex flex-row text-[11px] items-center'>
                          <span>
                            {donationsStats.rate == 0 ? '' : donationsStats.rate > 0 ? <img src={up} ></img> : <img src={down}></img>}
                          </span> 
                          <span className={`${donationsStats.rate > 0 ? 'text-[#219653]' : 'text-[#EB5757]'}`}>
                            {donationsStats.rate > 0 ? '+'+donationsStats.rate : ''+donationsStats.rate}% 
                            {/* <span className='text-[#8A8A8A]'>{donationsStats.suffix}</span> */}
                          </span> 
                        </span>
                    </div>
                </div>
                <div className='col-span-6 lg:col-span-2 grid grid-cols-1 p-3 border-[1px] rounded-[8px]'>
                    <div className='col-span-1 flex flex-row justify-between items-center'>
                        <img src={members} />
                        <div className='relative'>
                        {/* <img onClick={(e) => showMore(e,2)} className='h-[12px] cursor-pointer' src={more} /> */}
                            { morre && morre==2 ? 
                                <span ref={filterRef} className='absolute right-5 top-0 w-[100px] text-[#8A8A8A] bg-white flex flex-col rounded-[8px] border-[1px]'>
                                    <span className='border-b-[1px] p-2 cursor-pointer' onClick={() => ChangeStatsPeriod(2,1)}>This Year</span>
                                    <span className='border-b-[1px] p-2 cursor-pointer' onClick={() => ChangeStatsPeriod(2,2)}>This Month</span>
                                    <span className='p-2 cursor-pointer' onClick={() => ChangeStatsPeriod(2,3)}>This Week</span>
                                </span>
                                : null
                            }
                        </div>
                    </div>
                    <div className='col-span-1 text-start font-[600] text-[28px] mt-8'>{membersStats.total.toLocaleString()}</div>
                    <div className='col-span1 flex flex-row text-start text-[#8A8A8A] justify-between items-center mt-1'>
                        <span className='text-[14px] font-[400]'>Total Members</span>
                        <span className='flex flex-row text-[11px] items-center'><span>{membersStats.rate == 0 ? '' : membersStats.rate > 0 ? <img src={up} ></img> : <img src={down}></img>}</span>
                         <span className={`${membersStats.rate > 0 ? 'text-[#219653]' : 'text-[#EB5757]'}`}>{membersStats.rate > 0 ? '+'+membersStats.rate : ''+membersStats.rate}% 
                        {/* <span className='text-[#8A8A8A]'>{membersStats.suffix}</span> */}
                        </span> </span>
                    </div>
                </div>
                <div className='col-span-6 lg:col-span-2 grid grid-cols-1 p-3 border-[1px] rounded-[8px]'>
                    <div className='col-span-1 flex flex-row justify-between items-center'>
                        <img src={donors} />
                        <div className='relative'>
                        {/* <img onClick={(e) => showMore(e,3)} className='h-[12px] cursor-pointer' src={more} /> */}
                            { morre && morre==3 ? 
                                <span ref={filterRef} className='absolute right-5 top-0 w-[100px] text-[#8A8A8A] bg-white flex flex-col rounded-[8px] border-[1px]'>
                                    <span className='border-b-[1px] p-2 cursor-pointer' onClick={() => ChangeStatsPeriod(3,1)}>This Year</span>
                                    <span className='border-b-[1px] p-2 cursor-pointer' onClick={() => ChangeStatsPeriod(3,2)}>This Month</span>
                                    <span className='p-2 cursor-pointer' onClick={() => ChangeStatsPeriod(3,3)}>This Week</span>
                                </span>
                                : null
                            }
                        </div>
                    </div>
                    <div className='col-span-1 text-start font-[600] text-[28px] mt-8'>{donorsStats.total.toLocaleString()}</div>
                    <div className='col-span1 flex flex-row text-start text-[#8A8A8A] justify-between items-center mt-1'>
                        <span className='text-[14px] font-[400]'>Total Donors</span>
                        <span className='flex flex-row text-[11px] items-center'><span>{donorsStats.rate == 0 ? '' : donorsStats.rate > 0 ? <img src={up} ></img> : <img src={down}></img>}</span> 
                        <span className={`${donorsStats.rate > 0 ? 'text-[#219653]' : 'text-[#EB5757]'}`}>{donorsStats.rate > 0 ? '+'+donorsStats.rate : ''+donorsStats.rate}% 
                        {/* <span className='text-[#8A8A8A]'>{donorsStats.suffix}</span> */}
                        </span> </span>
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-6 mt-5 border-[1px] rounded-[8px] p-3'>
                <div className='col-span-6 flex flex-row justify-between items-center'>
                    <span className='text-[24px] font-[500]'>Donations</span>
                    {/* <select onChange={(e) => ChangeChartsPeriod(1,e.target.value)} className='border-[1px] rounded-[8px] px-2 text-[#8A8A8A] outline-none h-[40px]'>
                        <option selected={chosen1 == 1 ? true : false} value={1}>This year</option>
                        <option selected={chosen1 == 2 ? true : false} value={2}>This month</option>
                        <option selected={chosen1 == 3 ? true : false} value={3}>This week</option>
                    </select> */}
                </div>
                <div className='col-span-6 mt-5'>
                  {data && chartLabels.length > 0 ? <Line data={data} options={options}></Line> : <div>No donations for this period</div>}
                </div>
            </div>
            <div className='mt-5 grid grid-cols-2 gap-x-4 gap-y-5'>
                <div className='col-span-2 lg:col-span-1 grid grid-cols-1 border-[1px] rounded-[8px] p-3'>
                    <div className='col-span-1 flex flex-row justify-between'>
                        <span className='text-[#121927] font-[500]'>Giving Performance</span>
                        {/* <select onChange={(e) => ChangeChartsPeriod(2,e.target.value)} className='border-[1px] rounded-[8px] px-2 text-[#8A8A8A] outline-none h-[40px]'>
                            <option selected={chosen2 == 1 ? true : false} value={1}>This year</option>
                            <option selected={chosen2 == 2 ? true : false} value={2}>This month</option>
                            <option selected={chosen2 == 3 ? true : false} value={3}>This week</option>
                        </select> */}
                    </div>
                    {performance && barLabels.length > 0 ?
                    <div className={`col-span-1 mt-3 ${performance ? 'h-[321px]' :'' } `}>
                       <Bar data={performance} options={options2}/> 
                    </div>: <div>No donations for this period</div>}
                </div>
                <div className='col-span-2 lg:col-span-1 grid grid-cols-1 border-[1px] rounded-[8px] p-3'>
                    <div className='col-span-2 lg:col-span-1 flex flex-row justify-between'>
                        <span className='text-[#121927] font-[500]'>Donations Performance</span>
                        {/* <select onChange={(e) => ChangeChartsPeriod(3,e.target.value)} className='border-[1px] rounded-[8px] px-2 text-[#8A8A8A] outline-none h-[40px]'>
                            <option selected={chosen3 == 1 ? true : false} value={1}>This year</option>
                            <option selected={chosen3 == 2 ? true : false} value={2}>This month</option>
                            <option selected={chosen3 == 3 ? true : false} value={3}>This week</option>
                        </select> */}
                    </div>
                    {retention &&  dbRetentionData.total > 0 ?
                    <>
                    <div className='col-span-1 mt-3 mx-auto h-[321px] lg:w-[60%]'>
                       <Doughnut data={retention} options={options3} plugins={[centerText]}/> 
                    </div>
                    <div className='col-span-1 mt-80 lg:mt-auto flex flex-row items-center space-x-3 text-center justify-center'>
                        <span title="Members with donations greater than 2" className='rounded-full bg-[#D58936] w-[10px] h-[10px] cursor-pointer'></span>
                        <span className='text-[#404040] text-[16px]'>Retained Donors: {`${dbRetentionData ? dbRetentionData.data[1] : 0 }%`}</span>
                        <span title="Members with donations less than or equal to 2" className='rounded-full bg-[#E0E0E0] w-[10px] h-[10px] cursor-pointer'></span>
                        <span className='text-[#404040] text-[16px]'>New Donors: {`${dbRetentionData ? dbRetentionData.data[0] : 0 }%`}</span>
                    </div></>
                    : <div>No donations for this period</div>
                    }
                </div>
            </div>

            {/* <div className='mt-5 rounded-[8px] border-[1px] grid grid-cols-1 p-3'>
                <div className='col-span-1 flex flex-row justify-between items-center'>
                    <span className='text-[#121927] font-[500] text-[20px]'>Recent Donations</span>
                   {donationsData.length > 5 ? <Link to={'/'+urlbase+'/giving'} className='cursor-pointer p-3 py-2 border-[1px] text-[16px] rounded-[8px] text-[#8A8A8A]'>See more</Link> : null}
                </div>
                {donationsData.length > 0 ?
                <div className='col-span-1 mt-5 border-[1px] rounded-[8px]'>
                    <table className="table-auto overflow-x-scroll w-full block divide-y divide-gray-200 ">
                        <thead className='bg-[#F7F7F7]'>
                            <tr className='px-6 py-1 text-[#292D32] font-[400]'>
                            <th className='px-6 py-1 text-start text-[#292D32] font-[400]'>Donor Name</th>
                            <th className='px-6 py-1 text-start text-[#292D32] font-[400]'>Date & Time</th>
                            <th className='px-6 py-1 text-start text-[#292D32] font-[400]'>Member ID</th>
                            <th className='px-6 py-1 text-start text-[#292D32] font-[400]'>Amount</th>
                            <th className='px-6 py-1 text-start text-[#292D32] font-[400]'>Giving Type</th>
                            <th className='px-6 py-1 text-start text-[#292D32] font-[400]'>Payment method</th>
                            </tr>
                        </thead>
                        <tbody className='divide-y divide-gray-200'>
                            {donationsData.slice(0,5).map(({id,name,member_id,payment_date,amount,type,payment_method},index) => {
                                return (
                                    <tr key={id}>
                                        <td className='px-6 py-3 text-start text-[#4F4F4F]'>{name}</td>
                                        <td className='px-6 py-3 text-start text-[#4F4F4F]'>{(new Date(payment_date), 'yyyy-mm-dd')}</td>
                                        <td className='px-6 py-3 text-start text-[#4F4F4F]'>{member_id}</td>
                                        <td className='px-6 py-3 text-start text-[#4F4F4F]'>£{amount.toLocaleString()}</td>
                                        <td className='px-6 py-3 text-start text-[#4F4F4F]'>{type == 1? 'Individual(Offering)' : type == 2 ? 'Individual(Tithe)' : type == 3 ? 'GASD' : 'Aggregated'}</td>
                                        <td className='px-6 py-3 text-start text-[#4F4F4F]'>{payment_method}</td>
                                    </tr>
                                    )
                                }) 
                            }
                        </tbody>
                    </table>
                </div>
                : <div>No recent donations</div>
                }
            </div> */}
            
        </div>
      }
    </>
  )
}

export default Home