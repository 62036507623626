import React, { useEffect, useState } from 'react'
import logo from '../../images/logo.svg'
import { AiOutlineEye,AiOutlineEyeInvisible } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import welcome from '../../images/welcome.svg'
import axios from 'axios'
import settings from '../../components/getrequest'
import { LineWave } from  'react-loader-spinner'

function Reset() {
    const [param, setParam] = useState()
    useEffect(() => {
        const queryString = window.location.search;
        const searchParams = new URLSearchParams(queryString);
        if(!searchParams.get('resetToken')){
            window.location.href = '/login'
        } 
        setParam(searchParams.get('resetToken'))
    }, []);
  const [password,setPassword] = useState()
  const [pass2,setPass2] = useState()
  const [error, setError] = useState(0)
  const [processing, setProcessing] = useState(0)
  const resetPassword = () => {
    if(!password || password.length < 8 ){
      setError('Please fill all fields correctly')
    }else{
      setProcessing(1)
      let datas = {password:password,password_confirmation:password,token:param}
      axios.post(process.env.REACT_APP_BASE+'/api/password/reset',datas,settings)
      .then((res) => {
          if(res.data.code == 200){
            sessionStorage.setItem('chgamsresetdone',1)
            window.location.href='/reset-done'
          }else{
              setError(res.data.message)
              setProcessing(0)
          }
      }).catch((e) => {
          setError('There was an internal error, please try again later')
          setProcessing(0)
      })
    }
  }
  
  return (
    <>
        <div className='grid grid-cols-2'>
          <div className='col-span-2 lg:col-span-1 px-5 lg:px-20 flex flex-col space-y-20 lg:space-y-40 pt-5'>
            <span><img src={logo} /></span>
            <div className='flex flex-col space-y-8 text-start'>
              <div className='flex flex-col space-y-'>
                <span className='text-[#090712] text-[32px] font-[500]'>Reset Password</span>
                <span className='text-[#828282] text-[16px] font-[400]'>We are happy to have you back. Please set your new login details.</span>
              </div>
              <div className='grid grid-cols-2 text-[#505050] text-[16px] text-start gap-x-4 gap-y-5'>
                    <div className='col-span-2 flex flex-col space-y-2 mx-auto w-full'>
                        <div className='flex flex-row justify-between'>
                          <label>New Password</label>
                        </div>
                        <div className='w-full relative'>
                            <input onChange={(e) => setPassword(e.target.value)} id="password" name="password" type={pass2 ? 'text' : 'password'} className='border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none' defaultValue={localStorage.chgamscheckbox ? localStorage.chgamspassword : ''} placeholder='Input your password'/>
                            <span onClick={()=>setPass2(!pass2)} className='absolute right-3 top-4 cursor-pointer '>{pass2 ? <AiOutlineEye/> : <AiOutlineEyeInvisible/>}</span>
                            {error ? <span className='text-[#EB5757] text-[13px] font-[500]'>{error}</span> : ''}
                        </div>
                        {processing ? <span className='flex flex-row items-center'><LineWave height="100" width="100" color="#B30000" ariaLabel="line-wave" wrapperStyle={{marginTop:'-40px'}}
                                wrapperClass="" visible={true} firstLineColor="" middleLineColor="" lastLineColor=""/> <span className='font-[500]'>Please wait</span></span>
                                : <div className='w-full pt-5'><button onClick={resetPassword} className='bg-[#B30000] text-white w-full h-[50px] rounded-[4px]'>Create Password</button></div> }
                        <div className='pt-3'>Wanna sign in ? <Link to='/login' className='text-[#B30000]'>Login</Link></div>
                    </div>
                </div>
            </div>
          </div>
          <div className='col-span-1 pl-5 hidden lg:flex lg:pl-20 bg-[#F3F5F7] flex flex-col text-start pt-20'>
            <span className='text-[40px] font-[600]'>Welcome to Chgams</span>
            <span className='text-[#4F4F4F] pr-5'>We help empowers charity organizations to efficiently register and manage all activities of their members.</span>
            <div className='ml-[-40px]'><img src={welcome} /></div>
          </div>
        </div>
    </>
  )
}

export default Reset