import axios from "axios";
import settings from "../../../components/getrequest";
import React, { useState, useEffect } from "react";


function Permissions() {
  let urlbase = process.env.REACT_APP_BASE;
  const [permissions, setPermissions] = useState([]);
  const [permissionsUser, setPermissionsUser] = useState([]);

  const fetchAllPermissions = async () => {
    axios
      .get(urlbase + "/api/settings/permissions", settings())
      .then((res) => {
        if (res.data.code === 200) {
            setPermissions(res.data.data.admin);
            setPermissionsUser(res.data.data.user);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchAllPermissions();
  }, []);

  const[isProcessing,setIsProcessing] = useState()
  const updatePermissions = async (event) => {
    const userPerm = {
      "add_member" : permissionsUser.filter(item => item.col === "add_member")[0].value ? 1 : 0,
      "update_member":permissionsUser.filter(item => item.col === "update_member")[0].value ? 1 : 0,
      "delete_member":permissionsUser.filter(item => item.col === "delete_member")[0].value ? 1 : 0,
      "add_group" : permissionsUser.filter(item => item.col === "add_group")[0].value ? 1 : 0,
      "update_group":permissionsUser.filter(item => item.col === "update_group")[0].value ? 1 : 0,
      "delete_group":permissionsUser.filter(item => item.col === "delete_group")[0].value? 1 : 0,
      "add_giving" : permissionsUser.filter(item => item.col === "add_giving")[0].value? 1 : 0,
      "update_giving":permissionsUser.filter(item => item.col === "update_giving")[0].value? 1 : 0,
      "delete_giving":permissionsUser.filter(item => item.col === "delete_giving")[0].value ? 1 : 0,
    }
    const adminPerm = {
      "add_member" : permissions.filter(item => item.col === "add_member")[0].value ? 1 : 0,
      "update_member":permissions.filter(item => item.col === "update_member")[0].value ? 1 : 0,
      "delete_member":permissions.filter(item => item.col === "delete_member")[0].value ? 1 : 0,
      "add_group" : permissions.filter(item => item.col === "add_group")[0].value ? 1 : 0,
      "update_group":permissions.filter(item => item.col === "update_group")[0].value ? 1 : 0,
      "delete_group":permissions.filter(item => item.col === "delete_group")[0].value? 1 : 0,
      "add_giving" : permissions.filter(item => item.col === "add_giving")[0].value? 1 : 0,
      "update_giving":permissions.filter(item => item.col === "update_giving")[0].value? 1 : 0,
      "delete_giving":permissions.filter(item => item.col === "delete_giving")[0].value ? 1 : 0,
    }
    let data  = {users:userPerm,admins:adminPerm};
    setIsProcessing(1)
    event.preventDefault();
      axios
        .post(urlbase + "/api/settings/permissions",data, settings())
        .then((res) => {
          if (res.data.code === 200) {
            alert('Permissions updated successfully')
            window.location.reload();
        } else {
          alert(res.data.message)
          console.log("Error");
        }
        setIsProcessing(0)
        })
        .catch((error) => {
          alert('There was an error, please try later')
          console.log(error)
          setIsProcessing(0)
        });
  };


  const changeState = (index) => {
    const updatedPermissions = [...permissions];
    updatedPermissions[index].value = !updatedPermissions[index].value;
    setPermissions(updatedPermissions);
  }

  const changeStateUser = (index) => {
    const updatedUserPermissions = [...permissionsUser];
    updatedUserPermissions[index].value = !updatedUserPermissions[index].value;
    setPermissionsUser(updatedUserPermissions);
  }

  return (
    <div className='flex flex-col space-y-3'>
      <div className='flex flex-row justify-between border-b-[1px] pb-3'>
        <div className='flex flex-col space-y-2'>
          <span className='text-[#121927] font-[600] text-[20px]'>Permissions</span>
          <span>Make changes to the access levels of different admins</span>
        </div>
        <div>
          {isProcessing ? <div>Please wait...</div> : 
          <button onClick={updatePermissions} data-modal-hide="defaultModal"
                  type="button"
                  className={`border-[1px] w-[180px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white`} >
                  Update Permissions
            </button>}
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='grid grid-cols-2 border-b-[1px] pb-4 gap-y-3'>
          <span className='col-span-2'>Admin</span>
          {permissions.length > 0 ? 
          <div className='col-span-2 grid grid-cols-2 lg:col-span-1 flex flex-row justify-between'>
            {permissions.map(({col,name,value},index) => {
              return (<div key={index} className='flex flex-row items-center space-x-4 mt-2'>
                      <input type='checkbox' onChange={() => changeState(index)} checked={value == 1 ? true : false} className='accent-[#b30000]'/>
                      <span>{name}</span>
                    </div>
                  )
            })}
          </div>
          : <div>No permissions set for admin</div> }
        </div>
        <div className='grid grid-cols-2 border-b-[1px] pb-4 gap-y-3 mt-4'>
          <span className='col-span-2'>User</span>
          {permissionsUser.length > 0 ? 
          <div className='col-span-2 grid grid-cols-2 lg:col-span-1 flex flex-row justify-between'>
            {permissionsUser.map(({col,name,value},index) => {
              return (<div key={index} className='flex flex-row items-center space-x-4 mt-2'>
                      <input type='checkbox' onChange={() => changeStateUser(index)} checked={value == 1 ? true : false} className='accent-[#b30000]'/>
                      <span>{name}</span>
                    </div>
                  )
            })}
          </div>
          : <div>No permissions set for user</div> }
        </div>
      </div>
    </div>
  )
}

export default Permissions