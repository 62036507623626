import React, {useState,useEffect, useRef} from 'react'
import search from "../../../images/icons/search.svg"
import goback from "../../../images/icons/goback.svg"
import { ThreeDots } from 'react-loader-spinner'
import { Link } from 'react-router-dom'
import axios from 'axios'
import settings from '../../getrequest'
import { AiOutlineEye } from 'react-icons/ai'
import { LineWave } from  'react-loader-spinner' 

function Members() {
  let urlbase = process.env.REACT_APP_BASE;
  const [add, setAdd] = useState()
  const [membersAdded, setMembersAdded] = useState()
  const [error, setError] = useState(0)
  const [processing, setProcessing] = useState(0)
  const [membersData, setmembersData] = useState([])
  const[fetching, setFetching] = useState(1) 
  const sliceValue = 10
  const [MembersArray, setMembersArray] = useState([])
  const[MembersCurrent, setMembersCurrent] = useState([])
  const[MembersCurrentDefault, setMembersCurrentDefault] = useState()
  const [currentPage,setCurrentPage] = useState(0)

  const [tableStart, setTableStart] = useState(0)
  const [tableEnd, setTableEnd] = useState(5) 
  const getTickets = async (url) => {
    setFetching(1)
    axios.get(urlbase+url, settings())
      .then((res) => {
        if (res.data.code === 200) {
          if(res.data.data.length > 0) {
            setmembersData(res.data.data)
            const allData = res.data.data;
            const slicedData = [];
            for (let i = 0; i < allData.length; i += sliceValue) {
              slicedData.push(allData.slice(i, i + sliceValue));
            }
            setMembersCurrent(slicedData[0])
            setPageCount(slicedData[0].length)
            setMembersCurrentDefault(slicedData[0])
            setMembersArray(slicedData);
          }else{
            setmembersData([])
            setMembersCurrent([])
            setMembersCurrentDefault([])
            setMembersArray([]);
          }

        } else {
          setError(res.data.message);
          setProcessing(0);
        }
        setFetching(0)
      })
      .catch((e) => {
        setError('There was an internal error, please try again later');
        setProcessing(0);
        setFetching(0)
      });
  }

  useEffect(() => {
    getTickets('/api/tickets') 
  },[])

  //Applying filter
  let [dateF, setDateF] = useState(null)
  useEffect(() => {
    applyFilters();
  }, [dateF]);

  const dateFilter = (value) => {
      if(value) setDateF(value)
      else setDateF(null)
  };
    const applyFilters = () => {
       if (dateF) {
        getTickets('/api/tickets?date='+dateF)
      } else{
        getTickets('/api/tickets') 
      }
    };


const [pageCount, setPageCount] = useState(0) 
let [pageNumbers, setPageNumbers] = useState(0) 

const PreviousPage = () => {
  if(currentPage > 0){
    if(MembersArray.length > 5){
      if(tableStart != 0){
        setTableStart(tableStart-1)
        setTableEnd(tableEnd-1)
      }
    }
    let thenewPage = currentPage
    let counted = pageCount
    let start = 2
    while(start > 0){
        counted -= MembersArray[thenewPage].length
        thenewPage--;
        start--;
    }
    setPageNumbers(counted)
    setPageCount(pageCount - MembersArray[currentPage].length)
    setCurrentPage(currentPage-1)
    setMembersCurrent(MembersArray[currentPage-1])
  }
}

const NextPage = () => {
    if(currentPage != MembersArray.length-1){
      if(MembersArray.length > 5){
        if(MembersArray.length != tableEnd){
          setTableStart(tableStart+1)
          setTableEnd(tableEnd+1)
        }
      }
      let thenewPage = currentPage
      let counted = 0
      while(thenewPage >= 0){
          counted += thenewPage > 0 ? MembersArray[thenewPage-1].length : 0 + MembersArray[thenewPage].length
          thenewPage--;
      }
      setPageNumbers(counted)
      setCurrentPage(currentPage+1)
      setMembersCurrent(MembersArray[currentPage+1])
      setPageCount(pageCount+MembersArray[currentPage+1].length)
  }
}

const numberedPage = (id) => {
  let count = 0
  let counted = 0;
  while(count < id){
    counted += MembersArray[count].length
    count++;
  }
  setPageNumbers(counted)
  setPageCount(counted+MembersArray[id].length)

  setCurrentPage(id)
  setMembersCurrent(MembersArray[id])
}

const FirstPage = () => {
  numberedPage(0)
  setTableStart(0)
  setTableEnd(5)
}

const LastPage = () => {
  numberedPage(MembersArray.length-1)
  if(MembersArray.length > 5){
      setTableStart(MembersArray.length - 5)
      setTableEnd(MembersArray.length)
  }
}

const [searchItem, setSearchItem] = useState("");

//filter
const [filterr, setFilterr] = useState()
const filterAction = (e) => {
  e.stopPropagation();
  setFilterr(!filterr)
}

function getFirstLetter(inputString) {
  if (typeof inputString === 'string' && inputString.length > 0) {
      return inputString[0].toUpperCase(); 
  } else {
      return ''; 
  }
}

function limitWords(inputString) {
  return inputString.length > 10 ? inputString.slice(0, 10) + '...' : inputString;
}
const [memberData, setMemberData] = useState({country:'United Kingdom'})

function moveToDetails(id){
  sessionStorage.setItem('ticket_id',id);
  window.location.href = 'ticket/details'
} 

const AddMemberData = (value,field) => {
  setMemberData({...memberData, [field]:value})
}
const [addingProcessing,setAddingProcessing] = useState()

const PerformAdd = (e) => {
  e.preventDefault()
  setAddingProcessing(1)
  axios.post(urlbase+'/api/tickets/create',memberData,settings())
  .then((res) => {
    if(res.data.code == 200){
      setAdd(0)
      alert('Your ticket has been created, look out for a response soon.')
      window.location.reload()
    }else{
      alert(res.data.message);
    }
    setAddingProcessing(0)
  }).catch(() => {
    //setAddingError('There was an error, please try again')
    alert('There was an error, please try again');
    setAddingProcessing(0)
  })
}

  return (
    <>
      {add ?
      <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative w-[90%] lg:w-[40%] mt-[10%] lg:mt-[1%] max-h-full mx-auto text-[#333]">
              <form onSubmit={PerformAdd} className="relative bg-white shadow pt-3 rounded-[8px]">
                  <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                          Create new Ticket
                      </h3>
                      <button onClick={(e) => setAdd(0)} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div className='grid grid-cols-6 px-8 mt-5 gap-x-4 gap-y-5'>
                    <div className="col-span-6 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Title <span className='text-[#B30000]'>*</span></div>
                        <input onChange={(e) => AddMemberData(e.target.value,'title')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' placeholder='Input Title'/>
                    </div>
                    <div className="col-span-6 flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Issue Type <span className='text-[#B30000]'>*</span></div>
                        <select onChange={(e) => AddMemberData(e.target.value,'category')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] w-[100%] h-[50px]'>
                          <option value='0'>Select Issue category</option>
                          <option value='Members'>Members</option>
                          <option value='Giving'>Giving</option>
                          <option value='Claims'>Claims</option>
                          <option value='Groups'>Groups</option>
                          <option value='Groups'>Settings</option>
                          <option value='Others'>Others</option>
                        </select>
                    </div>
                    <div className="col-span-6 flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Priority <span className='text-[#B30000]'>*</span></div>
                        <select onChange={(e) => AddMemberData(e.target.value,'priority')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] w-[100%] h-[50px]'>
                          <option value='0'>Select Issue priority</option>
                          <option value='High'>High</option>
                          <option value='Medium'>Medium</option>
                          <option value='Low'>Low</option>
                        </select>
                    </div>
                    <div className="col-span-6 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Input Description <span className='text-[#B30000]'>*</span></div>
                        <textarea onChange={(e) => AddMemberData(e.target.value,'description')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[100px]' placeholder='Input description'/>
                    </div>
                  </div>

                  <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4 rounded-b-[8px]">
                      <button onClick={() => setAdd(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] w-[102px] h-[50px] rounded-[8px]">Cancel</button>
                      {addingProcessing ? <LineWave height="100" width="100" color="#B30000" ariaLabel="line-wave" wrapperStyle={{marginTop:'-40px'}}
                        wrapperClass="" visible={true} firstLineColor="" middleLineColor="" lastLineColor=""/>
                        : <button data-modal-hide="defaultModal" type="submit" className="border-[1px] w-[178px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white">Create ticket</button>}
                  </div>
              </form>
          </div>
      </div>
      :null}

      <div className={`grid grid-cols-1 mt-10 mb-5 px-8 ${add || membersAdded ? 'opacity-25' : ''}`}>
         <>
         <div className='col-span-1 flex flex-col lg:flex-row justify-between space-y-3 lg:space-y-0 lg:items-center m-3'>
              <span className='text-[#121927] font-[500] text-[20px] flex flex-row lg:space-x-2'>
                <span>All Tickets</span>
              </span>
              <div className='flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:space-x-2'>
                <div className='relative '>
                  <input onChange={(e) => dateFilter(e.target.value)} className='bg-white h-[40px] px-4 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none' type='date' />
                </div>
                {sessionStorage.getItem('role') != 1 && sessionStorage.getItem('role') != 2 ? null :
                <div onClick={() => setAdd(1)} className='border-[1px] bg-[#B30000] px-2 h-[40px] cursor-pointer rounded-[4px] flex flex-row p-2 items-center space-x-3'>
                  <img src='' />
                  <p className='text-white text-[15px] font-[600]'>Create Ticket</p>
                </div>}
              </div>
            </div>
         <div className='col-span-1 mt-5 rounded-[8px] border-[1px] grid grid-cols-1 bg-white h-[auto] pb-10'>
            
            {!fetching ? 
            <div className='col-span-1 grid grid-cols-1'>
            {membersData.length > 0 ?
                <div className='col-span-1 mt-5 grid grid-cols-1 space-y-10 overflow-x-auto'>
                  <table className="table-flex">
                      <thead className='bg-[#F9FAFB]'>
                      <tr className='text-[#667085] font-[400] text-[12px]'>
                        <th className='text-start px-5 py-3'>Title</th>
                        <th className='text-start px-5'>Ticket ID</th>
                        <th className='text-start px-5'>Priority</th>
                        <th className='text-start px-5'>Status</th>
                        <th className='text-start px-5'>Date</th>
                        <th className='text-start px-5'> </th>
                      </tr>
                    </thead>
                      <tbody className='text-[#667085] font-[300]'>
                    { MembersCurrent.filter((row) =>
                      !searchItem.length || (row.org?row.org:'')
                      .toString()
                      .toLowerCase()
                      .includes((searchItem ? searchItem : '').toString().toLowerCase()) 
                      ).map(({id,title,user,ticket_id,priority,status,created_at},index) => {
                          return (
                            <tr className='border-b-[.5px] border-t-[.5px]'> 
                              <td className='px-5 py-3 flex flex-row space-x-3 items-center'>
                                <span className='bg-[#F9FAFB] text-[#475467] rounded-full p-2 w-[40px] h-[40px] text-[16px] text-center'>{getFirstLetter('Prissolutions')}</span>
                                <span className='flex flex-col items-center'>
                                  <span>{limitWords(title)}</span>
                                </span>
                              </td>
                              <td className='px-5'>{ticket_id}</td>
                              <td className='px-5'>{priority}</td>
                              <td className='px-5'><span className={`font-[400] rounded-[16px] ${status == 'Resolved' ? 'bg-[#ECFDF3] text-[#027A48]' : 'bg-[#F2F4F7] text-[#344054]'} p-2`}>{status}</span></td>
                              <td className='px-5'>{created_at}</td>
                              <td className='px-5 cursor-pointer' title='View More' onClick={ () => moveToDetails(id)}><AiOutlineEye/></td>
                            </tr>
                          )
                          }) 
                      } 
                      </tbody>
                    </table>
                </div>
                : <div>No tickets fetched</div>
                }
            </div>
            : <div className='col-span-8 lg:col-span-6 flex flex-col justify-center items-center '>
            <ThreeDots height="80" width="80" radius="9" color="#333333" ariaLabel="three-dots-loading"
            wrapperStyle={{}} wrapperClassName="" visible={true}
            />
            </div>}

          </div>
        <div className='col-span-1 flex flex-row justify-between items-center'>
          {searchItem? '' : <span className='text-[#333333] text-[16px] text-start'>Showing <span>{pageCount}</span>/<span>{membersData.length}</span> results</span>}
          <div className={`ml-auto grid grid-cols-6 gap-x-4`}>
                <div className={`mt-5 col-span-6 flex flex-row gap-x-3 mb-5 lg:ml-auto items-center`}>
                    <div onClick={PreviousPage} className={`border-[#BCBCBC] h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`<`}</div>
                    {MembersArray.length > 5 ? <span className={`cursor-pointer text-[14px] font-[500] ${currentPage > 0 ? 'text-[#B30000]' : ''}`} onClick={FirstPage} >First</span> : null }
                    {MembersArray.slice(tableStart,tableEnd).map((MembersArrayS,index)=> {
                        return (
                            <div key={index} onClick={()=>numberedPage(MembersArray.indexOf(MembersArrayS))} className={`transitiion-all h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto text-start duration-500 border-[#BCBCBC] font-[600] text-[18px] border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center ${currentPage == MembersArray.indexOf(MembersArrayS) ? 'text-white bg-[#B30000] border-[#B30000] hover:bg-[#B30000] hover:text-white' :'text-[#292D32] bg-white hover:text-white hover:bg-[#B30000]'} hover:text-[#1D242D] cursor-pointer`}>{MembersArray.indexOf(MembersArrayS)+1}</div>
                        )
                    })}
                    {MembersArray.length > 5 ? <span className={`cursor-pointer text-[14px] font-[500] ${currentPage < MembersArray.length-1 ? 'text-[#B30000]' : ''}`} onClick={LastPage}>Last</span> : null }
                    <div onClick={NextPage} className={`border-[#BCBCBC] border-[1px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`>`}</div>
                </div>
            </div>
        </div>
          </> 
      </div>

    </>
  )
}

export default Members