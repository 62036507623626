import React from 'react'
import Layout from '../../layout'

function GroupDetails() {
  return (
    <>
        <Layout props='groupdetails'></Layout>
    </>
  )
}

export default GroupDetails