import React, { useState, useEffect } from "react";
import axios from "axios";
import settings from "../../../components/getrequest";

function Security() {
  let urlbase = process.env.REACT_APP_BASE;
  const [data, setData] = useState({});

   const handleChange = (event) => {
     setData({ ...data, [event.target.name]: event.target.value });
   };
   const [isProcessing, setIsProcessing] = useState();
   const updatePassword = () => {
    if(data.password !== data.password_confirmation){
      alert('Passwords do not match')
      return
    }
    setIsProcessing(1)
    axios
      .post(urlbase + "/api/settings/password",data, settings())
      .then((res) => {
        if (res.data.code === 200) {
          alert(res.data.message)
          window.location.reload();
      } else {
        alert(res.data.message)
        console.log("Error");
      }
      setIsProcessing(0)
      })
      .catch((error) => {
        alert('There was an error, please try later')
        console.log(error)
        setIsProcessing(0)
      });
   }

  return (
    <>
      <div className="border-b-[1px] pb-7 text-[#4F4F4F] md:flex md:flex-row md:justify-between md:items-center">
        <div className="flex flex-col space-y-2">
          <p className="text-[20px] font-[600] text-[#121927]">Password</p>
          <p className="text-[#4F4F4F] font-[400] text-[16px]">
            Enter your current password to change your password.
          </p>
        </div>
        <div>
          {isProcessing ? <div>Please wait...</div>:
          <button onClick={updatePassword} className="border-[1px] bg-[#B30000] text-white px-2 h-[40px] cursor-pointer rounded-[4px] flex flex-row p-2 items-center space-x-3">
            Update Password
          </button>}
        </div>
      </div>
      <div>
        <div className="col-span-2 flex flex-col space-y-2 mx-auto w-full pb-4">
          <label className="text-[#828282] text-[16px] leading-normal font-[400]">
            Current password
          </label>
          <div className="w-full relative">
            <input
              onChange={handleChange}
              id="currentPassword"
              name="current_password"
              type="password"
              className="border-[1px] text-[#4F4F4F] text-[16px] leading-normal font-[400] rounded-[4px] w-full h-[50px] px-3 outline-none"
              placeholder="Enter Current password"
            />
          </div>
        </div>
        <div className="col-span-2 flex flex-col space-y-2 mx-auto w-full pb-4">
          <label className="text-[#828282] text-[16px] leading-normal font-[400]">
            New password{" "}
          </label>
          <div className="w-full relative">
            <input
              onChange={handleChange}
              id="new"
              name="password"
              type="password"
              className="border-[1px] text-[#4F4F4F] text-[16px] leading-normal font-[400] rounded-[4px] w-full h-[50px] px-3 outline-none"
              placeholder="Enter New password"
            />
            <span className="text-[#AEAEAE] text-[14px] leading-normal font-[400]">
              Your new password must be more than 8 characters.
            </span>
          </div>
        </div>
        <div className="col-span-2 flex flex-col space-y-2 mx-auto w-full">
          <label className="text-[#828282] text-[16px] leading-normal font-[400]">
            Confirm new password
          </label>
          <div className="w-full relative">
            <input
              onChange={handleChange}
              id="confirm"
              name="password_confirmation"
              type="password"
              className="border-[1px] text-[#4F4F4F] text-[16px] leading-normal font-[400]  rounded-[4px] w-full h-[50px] px-3 outline-none"
              placeholder="Confirm new password"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Security 