import React from 'react'
import Layout from '../../layout'

function Tickets() {
  return (
    <>
        <Layout props='tickets'></Layout>
    </>
  )
}

export default Tickets