import React from 'react'
import Layout from '../../layout'

function Settings() {
  return (
    <>
        <Layout props='settings'>
          
        </Layout>
    </>
  )
}

export default Settings