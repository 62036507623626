import React, {useState,useEffect} from 'react'
import back from "../../../images/icons/back.svg"
import { Link } from 'react-router-dom'
import search from "../../../images/icons/search.svg"
import { Line} from 'react-chartjs-2';
import success from "../../../images/membersuccess.svg"
import axios from 'axios'
import { LineWave } from  'react-loader-spinner'
import settings from '../../../components/getrequest'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { format } from 'date-fns';
import { Chart,Title,Tooltip,LineElement,Legend,CategoryScale,LinearScale,PointElement,Filler,ArcElement } from 'chart.js';
Chart.register(
  Title,Tooltip,LineElement,Legend,CategoryScale,LinearScale,PointElement,Filler,ArcElement,ChartDataLabels
)

function MembersDetails() {
  const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
  const [declare, setDeclare] = useState()
  const changeDeclaration = (e) => {
    AddMemberData(e.target.checked,'gift_aid_declaration')
    setDeclare(e.target.checked)
  }
  const [memberInfo, setMemberInfo] = useState({})
  const [familiesData, setFamiliesData] = useState([])
  let urlbase = process.env.REACT_APP_BASE;
  const [processing, setProcessing] = useState(0)
  const [groups, setGroups] = useState([])
  const [error, setError] = useState(0)
  useEffect(() => {
    setProcessing(1)
    //get groups
    axios.get(urlbase+'/api/groups',settings())
    .then((res) => {
        if(res.data.code == 200){
          setGroups(res.data.data)
        }else{
            setError(res.data.message)
            setProcessing(0)
        }
    }).catch((e) => {
        setError('There was an internal error, please try again later')
        setProcessing(0)
    })
    //get member details

    axios.get(urlbase+'/api/members/member?id='+sessionStorage.getItem('member_id'),settings())
    .then((res) => {
        if(res.data.code == 200){
          setMemberInfo(res.data.data.member)
          setFamiliesData(res.data.data.families)
          setDeclare(res.data.data.member.gift_aid_declaration ==1 ? true : false)
        }else{
            console.log(res.data.message)
        }
        setProcessing(0)
    }).catch((e) => {
        console.log('There was an internal error, please try again later')
        setProcessing(0)
    })

  },[])

  function DonationTable() {
    
    const [donationsData, setDonationsData] = useState([])
    function getMembers(url){
      //setProcessing(1)
      //get member details
      axios.get(urlbase+url,settings())
      .then((res) => {
          if(res.data.code == 200){
            setDonationsData(res.data.data.donations)
          }else{
              console.log(res.data.message)
          }
          setProcessing(0)
      }).catch((e) => {
          console.log('There was an internal error, please try again later')
          setProcessing(0)
      })
    }
    useEffect(() => {
      getMembers('/api/members/member?id='+sessionStorage.getItem('member_id'))
    },[])

    //giving types
    const givingTypes = ['Cash','Individual','Aggregated','GASDS']

    //search
    const [searchItem, setSearchItem] = useState("");

    //filter
    const [filterr, setFilterr] = useState()
    const filterAction = () => {
      if(filterr) setFilterr(0)
      else setFilterr(1)
    }

    let [dateF, setDateF] = useState(null)
    useEffect(() => {
      applyFilters();
    }, [dateF]);

    const dateFilter = (value) => {
        if(value) setDateF(value)
        else setDateF(null)
    };

    const applyFilters = () => {
      if (dateF) {
        getMembers('/api/members/member?id='+sessionStorage.getItem('member_id')+'&date='+dateF) 
      } else {
        getMembers('/api/members/member?id='+sessionStorage.getItem('member_id'))
      }
    };

    return (
      <>
        <div className='grid grid-cols-1'>
          <div className='col-span-1 flex lg:flex-row flex-col space-y-4 lg:space-y-0 justify-between mt-8'>
            <div className='relative'>
              <div className='absolute top-3 z-50 left-4'><img src={search} /></div>
              <input onChange={(e) => setSearchItem(e.target.value)} className='relative w-[321px] bg-[#F7F7F7] h-[40px] pl-10 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none' placeholder='Search' />
            </div>
            <div className='flex flex-row space-x-4 items-center'>
              <div className="flex flex-row space-x-3 text-[16px] items-center">
                <select onChange={(e) => setThePeriod(e)} className="p-2 text-[#4F4F4F] border-[1px] rounded-[8px] outline-none">
                <option value='currenty' selected={false}>Current Year</option>
                  {periods.map(({start,end},index) => {
                    return (
                      <option key={index} value={start.toString()+end.toString()} selected={end == sessionStorage.getItem('end_year') ? true : false}>{start == end ? start : start+'-'+end}</option>
                    )
                  })}
                </select>
                {
                  processing ? <p>Processing ...</p> :
                  <button onClick={changePeriod} className="bg-[#B30000] p-2 text-white rounded-[4px] h-[40px]" type="button">
                  Change period
                </button>
                }
              </div>
              {/* <div className=''>
                <input onChange={(e) => dateFilter(e.target.value)} className='relative border-[1px] h-[40px] rounded-[4px] outline-none border-[#D9D9D9] p-1' type='date' />
              </div> */}
            </div>
          </div>
          <div className='col-span-1 mt-5 border-[1px] rounded-[8px]'>
            <table className="table-auto overflow-x-scroll w-full block divide-y divide-gray-200 ">
              <thead className='bg-[#F7F7F7]'>
              <tr className='py-1 text-[#292D32] font-[400] bg-[#F7F7F7]'>
                  <th className='px-3 py-1 text-start text-[#292D32] font-[400] w-[15%]'>S/N</th>
                  <th className='px-3 py-1 text-start text-[#292D32] font-[400] w-[20%]'>Date & Time</th>
                  <th className='px-3 py-1 text-start text-[#292D32] font-[400] w-[20%]'>Amount(£)</th>
                  <th className='px-3 py-1 text-start text-[#292D32] font-[400] w-[30%]'>Giving Type</th>
                  <th className='px-3 py-1 text-start text-[#292D32] font-[400] w-[30%]'>Payment method</th>
                </tr>
              </thead>
              {donationsData.length > 0 ? 
              <tbody className='divide-y divide-gray-200'>
                {donationsData.filter((row) =>
                !searchItem.length || row.name
                .toString()
                .toLowerCase()
                .includes(searchItem.toString().toLowerCase()) 
                ).map(({id,others,payment_date,amount,type,payment_method},index) => {
                    return (
                        <tr key={id}>
                            <td className='px-3 py-1 text-start'>{index+1}</td>
                            <td className='px-3 py-1 text-start text-[#4F4F4F]'>{payment_date}</td>
                            <td className='px-3 py-1 text-start text-[#4F4F4F]'>{amount.toLocaleString()}</td>
                            <td className='px-3 py-1 text-start text-[#4F4F4F]'>{type == 1 ? 'Individual(Offering)' : type == 2 ? 'Individual(Tithe)': (others ? others : 'Others')}</td>
                            <td className='px-3 py-1 text-start text-[#4F4F4F]'>{payment_method}</td>
                        </tr>
                        )
                    }) 
                }
            </tbody> : <span>No donations retrieved</span>}
          </table>
          </div>
        </div>
      </>
    )
  }

  function DonationChart(){
    const [data, setData] = useState()
    const [chartData, setChartData] = useState([])
    useEffect(() => {
      //setProcessing(1)
      //get member details
      axios.get(urlbase+'/api/members/trend?id='+sessionStorage.getItem('member_id'),settings())
      .then((res) => {
          if(res.data.code == 200){
            setChartData(res.data.data.data)
            setData({
              labels:res.data.data.labels,
              datasets:[
                {
                  labels:[],
                  data:res.data.data.data,
                  backgroundColor:'white',
                  borderColor:'#B30000',
                  borderWidth: 1.5,
                  tension:0,
                  fill:false,
                  radius:5,
                  pointStyle:'circle',
                  pointBorderColor:'#F5C4BA',
                  pointBorderWidth:4,
                  pointBackgroundColor:'#B30000'
                },
              ],
            })
          }else{
              console.log(res.data.message)
          }
          setProcessing(0)
      }).catch((e) => {
          console.log('There was an internal error, please try again later')
          setProcessing(0)
      })
    },[])
    const [chosen, setChosen] = useState(1)
    function ChangeChartsPeriod(period){
     setChosen(period)
      axios.get(urlbase+'/api/members/trend?id='+sessionStorage.getItem('member_id')+'&period='+period,settings())
      .then((res) => { 
        if(res.data.code == 200){
          setChartData(res.data.data.data)
          setData({
            labels:res.data.data.labels,
            datasets:[
              {
                labels:[],
                data:res.data.data.data,
                backgroundColor:'white',
                borderColor:'#B30000',
                borderWidth: 1.5,
                tension:0,
                fill:false,
                radius:5,
                pointStyle:'circle',
                pointBorderColor:'#F5C4BA',
                pointBorderWidth:4,
                pointBackgroundColor:'#B30000'
              },
            ],
          })
        }else{
          alert(res.data.message)
          setProcessing(0)
        }
      })
    }
  
    const options = {
      plugins:{
        datalabels: {
          display:false
        },
       legend: {
        display: false
       },
       chartAreaBorder: {
        borderColor: 'red',
        borderWidth: 2,
        borderDash: [5, 5],
        borderDashOffset: 2,
      }
      },
      scales:{
        x: {
          grid: {
            display: false,
            border: false,
          },
          ticks: {
          },
        },
        y:{
          suggestedMin: 0,
          border:{
            display:false,
          },
          grid: {
          },
          ticks: {
            stepSize:1,
            padding:0,
            callback: function (val, index, ticks) {
              return ('£'+val+'k'); 
            }
          },
          
        }
    }
     }
    return (
      <>
        <div className='grid grid-cols-6 border-[1px] rounded-[8px] p-3'>
            <div className='col-span-6 flex flex-row justify-between items-center'>
                <span className='text-[24px] font-[500]'>Donation</span>
                <select onChange={(e) => ChangeChartsPeriod(e.target.value)} className='border-[1px] rounded-[8px] p-2 text-[#8A8A8A] outline-none'>
                    <option selected={chosen == 1 ? true : false} value={1}>This year</option>
                    <option selected={chosen == 2 ? true : false}  value={2}>This month</option>
                    <option selected={chosen == 3 ? true : false}  value={3}>This week</option>
                </select>
            </div>
            <div className='col-span-6 mt-5'>
              {data && chartData.length > 0 ? <Line data={data} options={options}></Line> :
                <div>No donations for this period</div>
              }
            </div>
        </div>
      </>
    )
  }

  const [page, setPage] = useState(1)
  const [addRelationship, setAddRelationship] = useState()
  const [familyAdded, setFamilyAdded] = useState({})
  const [addfamily, setAddFamily] = useState()
  const [successs, setSuccesss] = useState()

  const AddAction = (e,field) => {
    setFamilyAdded({...familyAdded,
      [field] : e.target.value
    })
  }
  const AddMemberData = (value,field) => {
    //console.log(field,value)
    setMemberData({...memberData, [field]:value})
  }
  const moveNext = (e) => {
    e.preventDefault()
    if(!memberData.relationship || memberData.relationship == 'choose'){
      alert('Choose your relation to continue')
      return
    }
    if(!memberData.member_id){
      alert('Choose a member please')
      return
    }
    memberData.owner_id = sessionStorage.getItem('member_id')
    // setAddRelationship(0)
    // setAddFamily(1)
    processAdd(e)
  }

  const processAdd = (e) => {
    e.preventDefault()
    setAddRelationship(0)
    setAddFamily(0)
    PerformUpdate(e,1)
  }
  const [memberData, setMemberData] = useState({id:sessionStorage.getItem('member_id'),country:'United Kingdom'})
  const [addingProcessing,setAddingProcessing] = useState()
  const [addingError,setAddingError] = useState()

  function isValidPostcode(postcode) {
    const postcodeRegex = /^[A-Z][A-Z\d]{1,3}\s\d[A-Z]{2}$/
    return postcodeRegex.test(postcode);
  }

  const PerformUpdate = (e,type=null) => {
    if(memberData.group_id == 'choose'){
      memberData.group_id= null
    }
    if(type == 1){
      if(memberData.post_code){
        if(!isValidPostcode(memberData.post_code)){
          alert('Invalid post code')
          return
        }
      }
    }
    memberData.gift_aid_declaration =  memberData.gift_aid_declaration == true ? 1 : 0
    e.preventDefault();
    // console.log(memberData);
    // return
    setAddingProcessing(1)
    let theurl = type == 1 ? '/api/members/add/family' : '/api/members/update'
    axios.post(urlbase+theurl,memberData,settings())
    .then((res) => {
      if(res.data.code == 200){
       // setAdd(0)
        //setMembersAdded(1)
        if(type == 1){
          // setSuccesss(1)
          alert('New family member added successfully')
        }else{
          alert('Member details updated successfully')
        }
        window.location.reload();
      }else{
        alert(res.data.message);
      }
      setAddingProcessing(0)
    }).catch(() => {
      //setAddingError('There was an error, please try again')
      alert('There was an error, please try again');
      setAddingProcessing(0)
    })
  }
  const today = new Date().toISOString().split('T')[0];
  const viewDetails = (id) => {
    sessionStorage.setItem('member_id',id)
    window.location.href='/'+base+"/member-details"
  }


  //add participant 
const [members, setMembers] = useState([])

useEffect(() => { 
  axios.get(urlbase+'/api/members/others/'+sessionStorage.getItem('member_id'),settings())
  .then((res) => {
    if(res.data.code == 200){
      setMembers(res.data.data)
    }else{
        setError(res.data.message)
        setProcessing(0)
    }
  }).catch((e) => {
      setError('There was an internal error, please try again later')
      setProcessing(0)
  })
},[])

const [addParticipant2,setAddParticipant2] = useState()
const searchMember = () => {

  setAddParticipant2(1)
}

//SELECTION
const [selection, setSelection] = useState([])
const [selectionIds, setSelectionIds] = useState([])
const AddSelection = (e) => {
  const isFound = members.some(element => {
    return element.member_id === e.target.value;
  });
  if(isFound){
    const found = selection.some(obj => {
      return obj.member_id === e.target.value;
    });
    if(found){
    }else{
      const NewFind = members.find(obj => {
        return obj.member_id === e.target.value;
      });
      setSelection([{id:NewFind.id,member_id:NewFind.member_id,name:NewFind.name}])
      setMemberData({...memberData,member_id : NewFind.member_id})
    }
  }
  //document.getElementById('search').value=''
} 

const AddSelection2 = (e) => {
  const isFound = members.some(element => {
    return element.member_id === e.target.value;
  });
  if(isFound){
    const found = members.find(obj => {
      return obj.member_id === e.target.value;
    });
    setSelection([...selection,{id:found.id,member_id:found.member_id,name:found.name}])
    setSelectionIds([...selectionIds,found.member_id])
  }
 // alert(document.getElementById('search').value)
  document.getElementById('search').value=''
} 

//PERIODS

const [periods, setPeriods] = useState([])
const allPeriods = async () => {
  axios
    .get(urlbase + "/api/settings/periods", settings())
    .then((res) => {
      if (res.data.code === 200) {
        setPeriods(res.data.data)
      } else {
        console.log("Error");
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

useEffect(() => {
  allPeriods();
},[])

const [periodToChange, setPeriodToChange] = useState({})
const changePeriod = async () => {
  if(!periodToChange.end){
    alert('Select a new period please')
    return
  }
  if(sessionStorage.getItem('end_year') == periodToChange.end){
    return;
  }
  setProcessing(1)
  axios
    .post(urlbase + "/api/settings/periods/view",periodToChange, settings())
    .then((res) => {
      if (res.data.code === 200) {
        sessionStorage.setItem('end_year',periodToChange.end)
        alert('Period to view changed successfully')
        window.location.reload()
      } else {
        console.log("Error");
        setProcessing(0)
      }
    })
    .catch((error) => {
      console.log(error);
      setProcessing(0)
    });
};

const setThePeriod = (e) => {
  let val = e.target.value
  var start = val.substring(0, 4);
  var end = val.substring(4);
  setPeriodToChange({...periodToChange,
    end:end,start:start
  })
}
  return (
    <>
    {successs ?
      <div id="defaultModal" tabindex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative w-[50%] lg:w-[48%] mt-20 max-h-full mx-auto text-[#333]">
              <div className="relative bg-white shadow pt-4 rounded-[8px] px-8 text-center">
                  <div className="flex items-start justify-between px-8">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                      </h3>
                      {/* <button onClick={() => setGivingAdded(0)} type="button" className="text-gray-300 font-[200] bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                          <span className="sr-only">Close modal</span>
                      </button> */}
                  </div>
                  <div className='flex flex-col space-y-2 mt-3 px-5'>
                    <div className='grid mb-2'><img className='mx-auto w-[30%]' src={success} /></div>
                    <span className='text-[#121927] font-[600] text-[24px]'>New family member has been successfully added</span>
                    {/* <span className='text-[#4F4F4F] text-[16px]'>All members' donation can be found on the members page. check to see the specifics of each member's contribution.</span> */}
                  </div>
                  <div className="flex flex-row mt-5 space-x-3 px-8 pb-4 justify-center">
                      <Link to="/" data-modal-hide="defaultModal" type="button" className="border-[1px] w-full h-[50px] bg-white rounded-[8px] border-[1px] text-[#121927] grid my-auto"><p className="my-auto">Go to Dashboard</p></Link>
                      <Link to={'/'+base+"/members"} data-modal-hide="defaultModal" type="button" className="border-[#828282] w-full h-[50px] rounded-[8px] bg-[#B30000] text-white grid"><p className="my-auto">View Member Details</p></Link>
                  </div>
              </div>
          </div>
      </div>
      :null}

      {addRelationship ?
      <div id="defaultModal" tabindex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative w-[90%] lg:w-[30%] mt-[10%] max-h-full mx-auto text-[#333]">
              <form onSubmit={moveNext} className="relative bg-white shadow pt-3 rounded-[8px]">
                  <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                          Add family member
                      </h3>
                      <button onClick={() => setAddRelationship(0)} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div className='grid grid-cols-6 px-8 mt-5 gap-x-4 gap-y-5 mb-20'>
                    <div className="col-span-6 flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Relationship</div>
                        <select onChange={(e) => AddMemberData(e.target.value,'relationship')}  required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] w-[100%] h-[50px]'>
                          <option value="choose">Select relationship</option>
                          <option value='Husband'>Husband</option>
                          <option value='Wife'>Wife</option>
                          <option value='Daughter'>Daughter</option>
                          <option value='Son'>Son</option>
                          <option value='Sister'>Sister</option>
                          <option value='Brother'>Brother</option>
                        </select>
                    </div>
                    <div className='col-span-6 flex flex-col text-start space-y-2'>
                    <div className='text-[#505050]'>Search Member to add</div>
                    <div className='relative'>
                      <div className='absolute top-5 z-50 left-4'><img src={search} /></div>
                      <input list="members" id="search" onChange={(e) => AddSelection(e)} className='relative w-full bg-[#F7F7F7] h-[50px] pl-10 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none' placeholder='Search' />
                      {selection ? 
                      <div className='flex-col space-y-2 text-start mt-4'>
                          {selection.map(({id,member_id,name},ind) => {
                            return (
                              <div key={id} className={`flex flex-col pb-3 ${ind+1 != selection.length ? 'border-b-[1px]' : ''}`}>
                                <span className='text-[#333333] font-[400] text-[16px]'>{name}</span>
                                <span className='text-[#333333] font-[400] text-[12px]'>{member_id}</span>
                              </div>
                            )
                          })}
                      </div> :null }
                      <datalist onSelect={(e) => AddSelection2(e)} id="members">
                        {members.map(({member_id,name},idd) => {
                          return (
                            <option key={idd} value={member_id}>{name}</option>
                          )
                        })}
                      </datalist> 
                    </div>
                  </div>
                  </div>
                  {!addingProcessing ?
                  <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4 rounded-b-[8px] items-center">
                      <button onClick={(e) => setAddRelationship(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] w-[102px] h-[50px] rounded-[8px]">Cancel</button>
                      <button onClick={(e) => moveNext(e)} data-modal-hide="defaultModal" className="border-[1px] w-[119px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white">Continue</button>
                  </div> :
                  <p className='text-end p-8'>Please wait ...</p>
                  }
              </form>
          </div>
      </div>
      :null}


    {addfamily ?
      <div id="defaultModal" tabindex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative w-[90%] lg:w-[60%] mt-[25%] lg:mt-[1%] max-h-full mx-auto text-[#333]">
              <form onSubmit={processAdd} className="relative bg-white shadow pt-3 rounded-[8px]">
                  <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                          Input Details
                      </h3>
                      <button onClick={() => setAddFamily(0)} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div className='mt-7 text-start px-8 text-[#292D32] font-[500] text-[18px]'>Personal Information</div>
                  <div className='grid grid-cols-6 px-8 mt-5 gap-x-4 gap-y-5'>
                    <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>First Name</div>
                        <input onChange={(e) => AddMemberData(e.target.value,'first_name')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' placeholder='Enter first name'/>
                    </div>
                    <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Last Name</div>
                        <input onChange={(e) => AddMemberData(e.target.value,'last_name')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' placeholder='Enter last name'/>
                    </div>
                    <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Phone Number</div>
                        <input onChange={(e) => AddMemberData(e.target.value,'phone')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' placeholder='Enter phone number'/>
                    </div>
                    <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Email</div>
                        <input type='email' onChange={(e) => AddMemberData(e.target.value,'email')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' placeholder='Enter email addresss'/>
                    </div>
                    <div className="col-span-6 lg:col-span-2 flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Gender</div>
                        <select onChange={(e) => AddMemberData(e.target.value,'gender')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] w-[100%] h-[50px]'>
                          <option value="choose">Select gender</option>
                          <option value={1}>Male</option>
                          <option value={2}>Female</option>
                        </select>
                    </div>
                    <div className="col-span-6 lg:col-span-2 flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Date of Birth</div>
                        <input max={today} onChange={(e) => AddMemberData(e.target.value,'birthday')} type='date' className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]'/>
                    </div>
                    <div className="col-span-6 lg:col-span-2 flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Group</div>
                        <select onChange={(e) => AddMemberData(e.target.value,'group_ids')} className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] w-[100%] h-[50px]'>
                        <option value="choose">Choose group</option>
                        {groups.map(({id,name}) => {
                            return (
                              <option key={id} value={id}>{name}</option>
                            )
                          })}
                        </select>
                    </div>
                    <div className="col-span-6 lg:col-span-2 flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Wedding date</div>
                        <input max={today} onChange={(e) => AddMemberData(e.target.value,'wedding_date')} type='date' className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]'/>
                    </div>
                    <div className="col-span-6 lg:col-span-2 flex flex-col text-start space-y-2">
                      <label className='text-[#505050]'>Gift aid declaration</label>
                      <div className='flex flex-row items-center space-x-2'>
                        <input onChange={(e) => AddMemberData(e.target.value,'gift_aid_declaration')} type='checkbox' className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px] accent-[#B30000]'/>
                        <div className='text-[#828282]'>Declare gift aid</div>
                      </div>
                    </div>
                  </div>

                  <div className='mt-7 text-start px-8 text-[#292D32] font-[500] text-[18px]'>Address</div>
                  <div className='grid grid-cols-6 px-8 mt-5 gap-x-3 gap-y-3'>
                    <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Country</div>
                        <input onChange={(e) => AddMemberData(e.target.value,'country')} readOnly defaultValue='United Kingdom' className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' placeholder='Input country'/>
                    </div>
                    <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>City</div>
                        <input onChange={(e) => AddMemberData(e.target.value,'city')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' placeholder='Input city'/>
                    </div>
                    <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Post code</div>
                        <input onChange={(e) => AddMemberData(e.target.value,'post_code')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' placeholder='Enter post code'/>
                    </div>
                    <div className="col-span-6 lg:col-span-6 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Street Address</div>
                        <textarea onChange={(e) => AddMemberData(e.target.value,'address')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[76px]'></textarea>
                    </div>
                  </div>
                  <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4 rounded-b-[8px]">
                      <button onClick={() => setAddFamily(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] w-[102px] h-[50px] rounded-[8px]">Cancel</button>
                      <button data-modal-hide="defaultModal" type="submit" className="border-[1px] w-[178px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white">Add new member</button>
                  </div>
              </form>
          </div>
      </div>
      :null}
      {processing? <div className='justify-center items-center'>
        <LineWave height="100" width="100" color="#B30000" ariaLabel="line-wave" wrapperStyle={{marginTop:'-40px'}}
                      wrapperClass="" visible={true} firstLineColor="" middleLineColor="" lastLineColor=""/></div> : 
       <div className={`grid grid-cols-1 mt-5 text-start ${addRelationship || addfamily || successs ? 'opacity-25' : ''}`}>
        <div className='col-span-1 grid grid-cols-1 px-8'>
        {sessionStorage.getItem('role') == 4 ? null :
          <Link to={'/'+base+'/exited-members'} className='col-span-1 flex flex-row space-x-3'>
              <img src={back}/>
              <span className='text-[#4F4F4F] text-[16px]'>Back</span>
          </Link>}
          <div className='text-[#121927] font-[600] text-[24px] mt-5 space-y-3 lg:space-y-auto flex flex-col lg:flex-row justify-between lg:items-center'>
            <span>Member ID - <span className='underline cursor-pointer' title='This is your member Id'>{memberInfo.member_id}</span></span>
          </div>
        </div>
        <div className='col-span-1 grid grid-cols-6 gap-x-3 px-8 gap-y-5 mb-10'>
          <form className="col-span-6" onSubmit={PerformUpdate}>
            <div className='mt-7 text-start text-[#292D32] font-[500] text-[18px]'>Personal Information</div>
            <div className='grid grid-cols-6 mt-5 gap-x-4 gap-y-5'>
              <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                  <div className='text-[#505050]'>First Name</div>
                  <input onChange={(e) => AddMemberData(e.target.value,'first_name')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' defaultValue={memberInfo.first_name} placeholder='Enter first name'/>
              </div>
              <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                  <div className='text-[#505050]'>Last Name</div>
                  <input onChange={(e) => AddMemberData(e.target.value,'last_name')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' defaultValue={memberInfo.last_name} placeholder='Enter last name'/>
              </div>
              <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                  <div className='text-[#505050]'>Phone Number</div>
                  <input onChange={(e) => AddMemberData(e.target.value,'phone')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' defaultValue={memberInfo.phone} placeholder='Enter phone number'/>
              </div>
              <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                  <div className='text-[#505050]'>Email</div>
                  <input onChange={(e) => AddMemberData(e.target.value,'email')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' defaultValue={memberInfo.email} placeholder='Enter email addresss'/>
              </div>
              <div className="col-span-6 lg:col-span-2 flex flex-col text-start space-y-2">
                  <div className='text-[#505050]'>Gender</div>
                  <select onChange={(e) => AddMemberData(e.target.value,'gender')}  required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] w-[100%] h-[50px]'>
                    <option value={1} selected={memberInfo.gender==1?true:false}>Male</option>
                    <option value={2} selected={memberInfo.gender==2?true:false}>Female</option>
                  </select>
              </div>
              <div className="col-span-6 lg:col-span-2 flex flex-col text-start space-y-2">
                  <div className='text-[#505050]'>Date of Birth</div>
                  <input max={today} onChange={(e) => AddMemberData(e.target.value,'birthday')} type='date' className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' defaultValue={memberInfo.birthday ? format(new Date(memberInfo.birthday),'yyyy-MM-dd') : ''}/>
              </div>
              <div className="col-span-6 lg:col-span-2 flex flex-col text-start space-y-2">
                  <div className='text-[#505050]'>Wedding date</div>
                  <input max={today} onChange={(e) => AddMemberData(e.target.value,'wedding_date')} type='date' className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' defaultValue={memberInfo.wedding_date ? format(new Date(memberInfo.wedding_date),'yyyy-MM-dd') : ''}/>
              </div>
              <div className="col-span-6 lg:col-span-2 flex flex-col text-start space-y-2">
                <label className='text-[#505050]'>Gift aid declaration</label>
                <div className='flex flex-row items-center space-x-2'>
                  <input onChange={(e) => changeDeclaration(e,'gift_aid_declaration')} checked={declare} type='checkbox' className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px] accent-[#B30000]'/>
                  {/* <div className='text-[#828282]'>Declare gift aid Declare gift aid</div> */}
                  <div className='text-[#828282]'>Declare gift aid {memberInfo.declare_date ? <span>{'('+format(new Date(memberInfo.declare_date),'yyyy-MM-dd')+')'}</span> : null}</div>
                </div>
              </div>
            </div> 

            <div className='mt-7 text-start text-[#292D32] font-[500] text-[18px]'>Address</div>
            <div className='grid grid-cols-6 mt-5 gap-x-3 gap-y-3'>
              <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                  <div className='text-[#505050]'>Country</div>
                  <input onChange={(e) => AddMemberData(e.target.value,'country')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' defaultValue={memberInfo.country} placeholder='Input country'/>
              </div>
              <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                  <div className='text-[#505050]'>City</div>
                  <input onChange={(e) => AddMemberData(e.target.value,'city')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' defaultValue={memberInfo.city} placeholder='Input city'/>
              </div>
              <div className="col-span-6 lg:col-span-2 grid flex flex-col text-start space-y-2">
                  <div className='text-[#505050]'>Post code</div>
                  <input onChange={(e) => AddMemberData(e.target.value,'post_code')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[50px]' defaultValue={memberInfo.post_code} placeholder='Enter post code'/>
              </div>
              <div className="col-span-6 lg:col-span-6 grid flex flex-col text-start space-y-2">
                  <div className='text-[#505050]'>Street Address</div>
                  <textarea onChange={(e) => AddMemberData(e.target.value,'address')} required className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[76px]' defaultValue={memberInfo.address}></textarea>
              </div>
            </div> 

            {familiesData.length > 0 ? <>
          <div className='mt-10'><hr></hr></div>
          <div className='mt-7 text-start text-[#292D32] font-[500] text-[18px]'>Family Members</div>
          <div className='col-span-1 mt-5 border-[1px] rounded-[8px]'>
            <table className="table-auto overflow-x-scroll w-full block divide-y divide-gray-200 ">
              <thead className='bg-[#F7F7F7]'>
              <tr className='py-1 text-[#292D32] font-[400] bg-[#F7F7F7]'>
                  <th className='px-12 py-3 text-start text-[#292D32] font-[400] '>S/N</th>
                  <th className='px-12 py-1 text-start text-[#292D32] font-[400]'>First Name</th>
                  <th className='px-12 py-1 text-start text-[#292D32] font-[400] '>Last Name</th>
                  <th className='px-12 py-1 text-start text-[#292D32] font-[400] '>Relationship</th>
                  <th className='px-12 py-1 text-start text-[#292D32] font-[400] w-full'>Gender</th>
                  <th className='px-12 py-1 text-start text-[#292D32] font-[400] w-full'>Action</th>
                </tr>
              </thead>
              {familiesData.length > 0 ? 
              <tbody className='divide-y divide-gray-200'>
                {familiesData.map(({id,first_name,last_name,relationship,gender,member_id},index) => {
                    return (
                        <tr key={id}>
                            <td className='px-12 py-3 text-star'>{index+1}</td>
                            <td className='px-12 py-3 text-start text-[#4F4F4F]'>{first_name}</td>
                            <td className='px-12 py-3 text-start text-[#4F4F4F]'>{last_name}</td>
                            <td className='px-12 py-3 text-start text-[#4F4F4F]'>{relationship}</td>
                            <td className='px-12 py-3 text-start text-[#4F4F4F]'>{gender == 1 ? 'Male' : 'Female'}</td>
                            <td className='px-12 py-3 text-start text-[#4F4F4F] cursor-pointer text-[#b30000]' onClick={(e) => viewDetails(member_id)}>View</td>
                        </tr>
                        )
                    }) 
                }
            </tbody> : <span>No family members</span>}
          </table>
          </div> </>: null }
            
            <div className='mt-10'><hr></hr></div>
            <div className='mt-7 text-start text-[#292D32] font-[500] text-[18px]'>Donation Performance</div>
            <div className='grid grid-cols-1 mt-5'>
              <div className='col-span-1 border-b-[1px] flex flex-row space-x-2'>
                <span onClick={() => setPage(1)} className={`px-2 pb-2 cursor-pointer ${page == 1 ? 'text-[#B30000] border-b-[1px] border-[#B30000]' : 'text-[#828282]'}`}>Donation History</span>
                <span onClick={() => setPage(2)} className={`px-2 pb-2 cursor-pointer ${page == 2 ? 'text-[#B30000] border-b-[1px] border-[#B30000]' : 'text-[#828282]'}`}>Chart</span>
              </div>
              <div className='col-span-1 mt-5'>
                {page == 1 ? <DonationTable></DonationTable> : <DonationChart></DonationChart>}
              </div>
            </div>
        </form>
        </div>
       </div>
      }
    </>
  )
}

export default MembersDetails